import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators/finalize';

import { ExportService } from '../../core/services/export.service';
import { NotificationService } from 'if-angular-core';
import { StateService } from '../../core/services/state.service';
import { UtilityService } from '../../core/services/utility.service';
import { IDocument } from '../models/document.interface';
import { UserHeader } from '../models/user-header.model';
import { DocumentState } from './models/document-state.model';

@Component({
    selector: 'dol-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css', '../datatable-bar/datatable-bar.css']
})
export class DocumentsComponent implements OnInit {
    @Input() documentCreateFunction: (uploads: Array<File>) => Observable<Array<IDocument>>;
    @Input() documentDownloadFunction: (documentId: number) => Observable<any>;
    @Input() documentDeleteFunction: (documentId: number) => Observable<void>;
    @Input() noDocumentsMessage: string;
    @Input() disable: boolean;
    @Input() disableDelete: boolean;
    @Input() disableDownload: boolean;
    @Input() disableUpload: boolean;

    @Input()
    set documents(documents: Array<IDocument>) {
        this.transformDocuments(documents);
    }

    documentStates: Array<DocumentState>;
    documentToDelete: DocumentState;
    uploads: Array<File>;
    loading: boolean;
    showDeleteDocumentConfirmation: boolean;
    errorMessage: string;

    constructor(
        private stateService: StateService,
        private notificationService: NotificationService,
        private exportService: ExportService,
        private utilityService: UtilityService) { }

    ngOnInit() {
        this.transformDocuments(this.documents);
    }

    transformDocuments(documents: Array<IDocument>) {
        if (documents && documents.length > 0) {
            const unorderedDocuments = documents.map(document => new DocumentState({ document }));
            const orderedDocuments = this.utilityService.orderBy(unorderedDocuments, false, 'document.createDate', 'document.id');
            this.documentStates = orderedDocuments;
        }
    }

    handleFileInput(fileInputElement: HTMLInputElement) {
        this.uploads = Array.from(fileInputElement.files);

        if (this.uploads.length > 0) {
            this.loading = true;
            this.errorMessage = null;

            this.documentCreateFunction(this.uploads)
                .pipe(
                    finalize(() => {
                        this.uploads = null;
                        fileInputElement.value = null;
                        this.loading = false;
                    })
                )
                .subscribe(documents => {
                    const newDocuments = documents
                        .map(this.addCurrentUserHeaderToDocument.bind(this))
                        .map(document => new DocumentState({ document }));
                    this.documentStates = this.documentStates ? newDocuments.concat(this.documentStates) : newDocuments;

                    this.notificationService.push({ severity: 'success', summary: 'Success', detail: 'Documents uploaded successfully.' });
                }, error => {
                    this.errorNotification(error,
                        'An error occurred',
                        'You do not have permissions to upload documents.',
                        'An error occurred uploading your documents.');
                });
        }
    }

    downloadDocument(document: DocumentState) {
        if (document && !document.pendingAction) {
            this.loading = true;
            document.pendingDownload = true;

            this.documentDownloadFunction(document.document.id)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                        document.pendingDownload = false;
                    })
                )

                .subscribe(data => {
                    this.exportService.download(data, document.document.name);
                }, error => {
                    if (error.status === 400 && !(error.error instanceof Error)) {
                        this.notificationService.push(
                            {
                                severity: 'error',
                                summary: 'An error occurred',
                                detail: 'An error occurred retrieving ' + document.document.name
                            });
                    } else {
                        this.errorNotification(error,
                            'An error occurred',
                            'You do not have permissions to download ' + document.document.name,
                            'An error occurred retrieving ' + document.document.name);
                    }
                });
        }
    }

    confirmDocumentDelete(document: DocumentState) {
        this.showDeleteDocumentConfirmation = true;
        this.documentToDelete = document;
    }

    cancelDocumentDelete() {
        this.showDeleteDocumentConfirmation = false;
        this.documentToDelete = null;
    }

    deleteDocument(document: DocumentState) {
        if (document) {
            this.loading = true;
            this.showDeleteDocumentConfirmation = false;
            this.documentToDelete = null;
            document.pendingDelete = true;
            this.errorMessage = null;

            this.documentDeleteFunction(document.document.id)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                        document.pendingDelete = false;
                    })
                )
                .subscribe(() => {
                    const deletedIndex = this.documentStates.indexOf(document);
                    this.documentStates.splice(deletedIndex, 1);
                    this.notificationService.push(
                        {
                            severity: 'success',
                            summary: 'File Deleted',
                            detail: document.document.name + ' was successfully deleted.'
                        });
                }, error => {
                    this.errorNotification(error,
                        'An error occurred',
                        'You do not have permissions to delete ' + document.document.name,
                        document.document.name + ' could not be deleted.');
                });
        }
    }

    private errorNotification(error: any, summary: string, permissionMessage: string, genericErrorMessage: string) {
        console.log('error', error);
        if (error.status === 400 && !(error.error instanceof Error)) {
            const errors = error.error;
            this.errorMessage = errors.Message;
        } else if (error.status === 400) {
            this.notificationService.push({ severity: 'error', summary: summary, detail: permissionMessage });
        } else {
            this.notificationService.push({ severity: 'error', summary: summary, detail: genericErrorMessage });
        }
    }

    private addCurrentUserHeaderToDocument(document: IDocument): IDocument {
        const user = this.stateService.currentUser;
        const creator = new UserHeader({
            UserId: user.userID,
            FirstName: user.firstName,
            LastName: user.lastName,
            Email: user.userName
        });
        document.createdBy = creator;

        return document;
    }
}
