<dol-message-center>
</dol-message-center>
<div class="stage container"> <!--TODO:MFA-->
<div class="column-wrapper" [class.one-column-layout]="!loginImage || !loginImage.uri">
    <div class="login-form-wrapper">
        <form class="login-form raised">
            <h1>Login</h1>

            <b2c-content-host [b2cFrameSource]="b2cFrameSource" [b2cFrameHeight]="b2cFrameHeight"></b2c-content-host>

            <div class="login-form-links">
                <div class="password-reset">
                    <a routerLink="/reset">Forgot Your Password?</a>
                </div>
                <div>
                    <span>If you are a new user, please <a routerLink="dealer-register">click here</a> to
                        register.</span>
                </div>
                <div>
                    <span><a href="https://www.raboag.com/security-privacy/how-we-protect-you-148" target="_blank">Click
                            here</a> for customer security information.</span>
                </div>
                <div>
                    <p>Only authorized use of this site is permitted. Unauthorized access may result in legal or disciplinary action.</p>
                    <p>This site uses cookies to provide for technical functionality as well as to make our website more enjoyable
                        and relevant to you. By continuing your visit on the website, you consent to the use of these cookies.</p>
                </div>
            </div>
        </form>
        <div class="login-form-message raised">
            <h4>Online Credit Application</h4>
            <div class="login-form-message-link-wrapper">
                <a href="https://grower.raboag.com/" target="_blank">grower.raboag.com</a>
            </div>
            <div class="login-form-message-link-slogan">
                <span>Quick</span>
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>Convenient</span>
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>Faster Turnaround</span>
            </div>
            <dol-email-grower></dol-email-grower>
        </div>
    </div>
    <div *ngIf="loginImage && loginImage.uri" class="login-image-wrapper raised">
        <a *ngIf="loginImage.redirectTo" [href]="loginImage.redirectTo" target="_blank">
            <img [src]="loginImage.uri" alt="Login banner image">
        </a>
        <img *ngIf="!loginImage.redirectTo" [src]="loginImage.uri" alt="Login banner image" class="login-image">
    </div>
</div>

</div>