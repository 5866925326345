export const PROGRAM_DELETE = 'Program:Delete';
export const PROGRAM_VIEW = 'Program:View';
export const PROGRAM_EDIT = 'Program:Edit';
export const PROGRAM_APPROVE = 'Program:Approve';
export const ASSIGNED_DEALER_GET_ANY = 'AssignedDealer:Get';
export const ASSIGNED_DEALER_EDIT = 'AssignedDealer:Update';
export const DEALER_VIEW = 'Dealer:View';
export const DEALER_EDIT = 'Dealer:Edit';
export const DEALER_DOCUMENT_DELETE = 'Dealer:Document:Delete';
export const COMMITMENT_MASTER_APPROVE = 'Commitment:Master:Approve';
export const COMMITMENT_MASTER_VIEW = 'Commitment:Master:View';
export const COMMITMENT_MASTER_STAGE = 'Commitment:Master:Stage';
export const CANCEL_PENDING_MASTER_COMMITMENT = 'Commitment:Master:Delete';
export const USER_SAVE = 'User:Save';
export const USER_VIEW = 'User:View';
export const DEALER_LOCATION_PERMISSION_VIEW = 'DealerLocationPermission:Get';
export const DEALER_LOCATION_PERMISSION_EDIT = 'DealerLocationPermission:Update';
export const DEALER_SETTING_VIEW = 'Dealer:Setting:Get';
export const DEALER_SETTING_EDIT = 'Dealer:Setting:Update';
export const DEALER_TAGS_VIEW = 'Dealer:Tags:Get';
export const DEALER_TAGS_EDIT = 'Dealer:Tags:Update';
export const DEALER_STATUS_EDIT = 'Dealer:Status:Update';
export const DEALER_UPDATE_TRANSACTION_LIMIT = 'Dealer:TransactionLimit:Update';
export const TRANSACTION_LIMIT_VOUCHER_APPROVE = 'TransactionLimit:Voucher:Approve';
export const BRIDGE_TRANSACTION_UPDATE = 'BridgeTransaction:Update';
export const CONTACT_SCREEN = 'Contact:Screen';
