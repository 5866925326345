<div class="autocomplete-wrapper raised-card">
    <mat-form-field>
        <input matInput [matAutocomplete]="auto" [formControl]="programSearch" placeholder="Search">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let programHeader of filteredProgramHeaders | async" [value]="programHeader">
            {{programHeader.name}}</mat-option>
    </mat-autocomplete>
    <button mat-raised-button type="button" class="btn-success" (click)="selectProgramHeader(programSearch.value)"
        [disabled]="disabled">Add</button>
    <div *ngIf="loading" class="loading-wrapper">
        <dol-loading-feedback [inline]="true">
        </dol-loading-feedback>
    </div>
</div>
<ul>
    <li *ngFor="let programHeader of selectedProgramHeaders; let i = index" class="raised-card">
        {{programHeader.name}}
        <button *ngIf="!disabled" type="button" mat-button class="btn-default"
            (click)="deselectProgramHeader(i)">Remove</button>
    </li>
</ul>