import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../environments/environment';
import { GetMyProgramsQueryParameter } from '../../shared/models/get-my-programs-query-parameter.model';
import { Program } from '../../shared/models/program.model';
import { ProgramHeader } from '../../shared/models/program-header.model';
import { ProgramResult } from '../../shared/models/program-result.model';
import { ProgramQueryParameter } from '../../shared/models/program-query-parameter.model';
import { AuthenticatedHttp } from './authentication/authenticated-http';
import { IProgramService } from './program.service.interface';
import { ProgramDocumentHeader } from '../../shared/models/program-document-header.model';

@Injectable({
    providedIn: 'root'
})
export class ProgramService implements IProgramService {
    currentProgram: Program;
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: AuthenticatedHttp) { }

    getMyPrograms(query: GetMyProgramsQueryParameter): Observable<ProgramResult[] | ProgramHeader[]> {
        const url = `${CONFIG.apiBaseUrl}program/my`;

        return this.http.post(url, query)
            .pipe(
                map((res) => {
                    return Array.from(res, data => query.returnProgramResult ? new ProgramResult(data) : new ProgramHeader(data));
                })
            );
    }

    getPrograms(): Observable<Program[]> {
        const url = `${CONFIG.apiBaseUrl}program`;

        return this.http.get<Program[]>(url)
            .pipe(
                map((res) => {
                    return Array.from(res, data => new Program(data));
                })
            );
    }

    getProgram(id: number): Observable<Program> {
        const url = `${CONFIG.apiBaseUrl}program/${id}`;

        return this.http.get<Program>(url)
            .pipe(
                map((res) => new Program(res))
            );
    }

    deleteProgram(id: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}program/${id}`;

        return this.http.delete(url, { responseType: 'text' });
    }

    findPrograms(query: ProgramQueryParameter): Observable<Program[]> {
        const url = `${CONFIG.apiBaseUrl}program/find`;

        return this.http.post<Program[]>(url, query, { headers: this.headers })
            .pipe(
                map((response) => Array.from(response, data => new Program(data)))
            );
    }

    saveProgram(program: Program): Observable<Program> {
        const url = `${CONFIG.apiBaseUrl}program/submit`;

        return this.http.post(url, program, { headers: this.headers })
            .pipe(
                map((response) => new Program(response))
            );
    }

    setCurrentProgram(program: Program) {
        this.currentProgram = program;
    }

    getCurrentProgram() {
        return this.currentProgram;
    }

    approveProgram(program: Program): Observable<Program> {
        const url = `${CONFIG.apiBaseUrl}program/approve`;

        return this.http.post<Program>(url, program, { headers: this.headers })
            .pipe(
                map((response) => new Program(response))
            );
    }

    getPublicPrograms(): Observable<Array<ProgramHeader>> {
        const url = `${CONFIG.apiBaseUrl}program/public`;

        return this.http.get<Array<ProgramHeader>>(url)
            .pipe(
                map(rawPrograms => rawPrograms.map(rawProgram => new ProgramHeader(rawProgram)))
            );
    }

    downloadMyDocument(programId: number, documentId: number): Observable<Blob> {
        const url = `${CONFIG.apiBaseUrl}program/my/${programId}/documents/${documentId}`;

        return this.http.get<Blob>(url, { responseType: 'blob' });
    }

    downloadDocument(programId: number, documentId: number): Observable<Blob> {
        const url = `${CONFIG.apiBaseUrl}program/${programId}/documents/${documentId}`;

        return this.http.get<Blob>(url, { responseType: 'blob' });
    }

    getDocumentServiceUploadUrl(programId: number) {
        return `${CONFIG.apiBaseUrl}program/${programId}/documents`;
    }

    upLoadDocument(url: string, formData: FormData): Observable<any> {

        return this.http.post(url, formData, { responseType: 'json' })
            .pipe(
                map(items =>
                    items.map(item => new ProgramDocumentHeader(item))
                ));
    }
}
