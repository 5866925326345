export class TransactionLimit {
    transactionLimitId: number;
    amount: number;
    durationLength: number;
    durationType: string;

    constructor(appData?: any) {
        if (appData) {
            this.transactionLimitId = appData.TransactionLimitId;
            this.amount = appData.Amount;
            this.durationLength = appData.DurationLength;
            this.durationType = appData.DurationType;
        }
    }
}
