import { AssignedDealerPermission } from './assigned-dealer-permission.model';

export class AssignedDealers {
    dealers: Array<AssignedDealerPermission> = [];
    userId: string;

    constructor(appData?: any) {
        if (appData) {
            this.dealers = appData.Dealers.map(cd => new AssignedDealerPermission(cd));
            this.userId = appData.UserId;
        }
    }
}
