export class CustomReport {
    name: string;
    columns: Array<string> = [];

    constructor(appData?: any) {
        if (appData) {
            this.name = appData.Name;
            this.columns = appData.Columns;
        }
    }
}
