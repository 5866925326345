import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../environments/environment';
import { CommitmentHeader } from '../../shared/models/commitment-header.model';
import { CustomCustomerColumn } from '../../shared/models/custom-customer-column.model';
import { CustomCustomerValue } from '../../shared/models/custom-customer-value.model';
import { MasterCommitmentResult } from '../../shared/models/master-commitment-result.model';
import { PendingMasterCommitment } from '../../shared/models/pending-master-commitment.model';
import { UpdateCustomCustomerNumbersBindingModel } from '../../shared/models/update-custom-customer-numbers-binding.model';
import { UpdateCustomValuesBindingModel } from '../../shared/models/update-custom-values-binding.model';
import { AuthenticatedHttp } from './authentication/authenticated-http';

@Injectable({
    providedIn: 'root'
})
export class CommitmentService {
    constructor(private http: AuthenticatedHttp) { }

    submitMasterCommitmentForApproval(commitment: PendingMasterCommitment): Observable<number> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/master/submitforapproval`;

        return this.http.post<number>(url, commitment);
    }

    approvePendingMasterCommitment(pendingCommitmentId: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/master/approve/${pendingCommitmentId}`;

        return this.http.post(url, null, { responseType: 'text' });
    }

    cancelPendingMasterCommitment(pendingCommitmentId: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/master/${pendingCommitmentId}`;

        return this.http.delete(url, { responseType: 'text' });
    }

    getCommitmentsForSponsor(sponsorId: number): Observable<CommitmentHeader[]> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/master/sponsor/${sponsorId}`;

        return this.http.post<CommitmentHeader[]>(url)
            .pipe(
                map(res => res.map(data => new CommitmentHeader(data)))
            );
    }

    getMasterCommitment(dealerId: number): Observable<MasterCommitmentResult> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/master/dealer/${dealerId}`;

        return this.http.get(url)
            .pipe(
                map(res => new MasterCommitmentResult(res))
            );
    }

    updateCustomCustomerNumbers(customCustomerNumbers: UpdateCustomCustomerNumbersBindingModel): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/customcustomernumbers`;

        return this.http.post(url, customCustomerNumbers, { responseType: 'text' });
    }

    getCustomCustomerNumbers(commitmentNumber: string): Observable<string[]> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/customcustomernumbers/${commitmentNumber}`;

        return this.http.get<string[]>(url);
    }

    createCustomCustomerColumn(customCustomerColumn: CustomCustomerColumn): Observable<CustomCustomerColumn> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/customcustomercolumn`;

        return this.http.post<CustomCustomerColumn>(url, customCustomerColumn)
            .pipe(
                map(data => new CustomCustomerColumn(data))
            );
    }

    updateCustomCustomerColumn(customCustomerColumn: CustomCustomerColumn): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/customcustomercolumn/${customCustomerColumn.customCustomerColumnId}`;

        return this.http.post(url, customCustomerColumn, { responseType: 'text' });
    }

    deleteCustomCustomerColumn(customCustomerColumnId: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/customcustomercolumn/${customCustomerColumnId}`;

        return this.http.delete(url, { responseType: 'text' });
    }

    updateCustomCustomerValues(subcommitmentId: number, model: UpdateCustomValuesBindingModel): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}lending/commitment/${subcommitmentId}/customcustomervalues`;

        return this.http.post(url, model, { responseType: 'text' });
    }
}
