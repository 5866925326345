<div class="message-container raised-card container hide-on-print" *ngIf="showMessages()">
    <p-accordion>
        <p-accordionTab header="Message Center" [selected]="true">
            <div class="message-header" *ngFor="let group of messageGroups">
                <h5>{{group.header}}</h5>
                <ul>
                    <li class="message-content" *ngFor="let message of group.messages">
                        <div class="message-text" [innerHtml]="message | safeHtml"></div>
                    </li>
                </ul>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>