import { Address } from './address.model';

export class DealerQueryParameter {
    returnFullObject: boolean;
    includeRegistrationStatus: boolean;
    dealerNumber: string;
    name: string;
    location: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressState: string;
    addressZipCode: string;
    taxIdNumber: string;
    ancestorIds: Array<number>;
    isActive: boolean;
}
