import { DealerHeader } from './dealer-header.model';

export class DealerSettings {
    dealerId: number;
    childrenDealerIds: string[];

    usesRaboApplications: boolean;
    sendGrowerTransactionEmails: boolean;

    constructor(appData?: any) {
        if (appData) {
            this.dealerId = appData.DealerId;
            this.childrenDealerIds = appData.ChildrenDealerIds ? appData.ChildrenDealerIds : null;

            this.usesRaboApplications = appData.UsesRaboApplications;
            this.sendGrowerTransactionEmails = appData.SendGrowerTransactionEmails;
        }
    }
}
