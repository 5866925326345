import { NgModule, ErrorHandler } from '@angular/core';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';

import { CustomErrorHandler } from '../../app/core/error-handling/custom-error.handling';
import { LoggingService } from '../../app/core/error-handling/services/logging.service';
import { ServiceUnavailableInterceptor } from '../../app/core/services/service-unavailable.interceptor';

import { AccountService } from './services/account.service'; // TODO:MFA
import { AssignedDealersService } from '../../app/core/services/assigned-dealers.service';
import { AuthGuard } from '../../app/core/services/authentication/auth-guard.service';
import { AuthService } from './services/authentication/auth.service'; //TODO:MFA
import { AuthenticatedHttp } from '../../app/core/services/authentication/authenticated-http';
import { DealerPermissionGuard } from '../../app/core/services/authorization/dealer-permission-guard.service';
import { PermissionService } from '../../app/core/services/authorization/permission.service';
import { PermissionGuard } from '../../app/core/services/authorization/permission-guard.service';
import { CommitmentService } from '../../app/core/services/commitment.service';
import { DealerService } from '../../app/core/services/dealer.service';
import { ExportService } from '../../app/core/services/export.service';
import { IdleService } from '../../app/core/services/idle/idle.service';
import { MessageCenterService } from '../../app/core/services/message-center/message-center.service';
import { PreferenceService } from '../../app/core/services/preference.service';
import { ProgramService } from '../../app/core/services/program.service';
import { ReCaptchaService } from '../../app/core/services/recaptcha.service';
import { StateService } from '../../app/core/services/state.service';
import { StorageService } from '../../app/core/services/storage.service';
import { UtilityService } from '../../app/core/services/utility.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        [
            {
                provide: ErrorHandler,
                useClass: CustomErrorHandler
            }
        ],
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServiceUnavailableInterceptor,
            multi: true
        },
        AccountService,
        AssignedDealersService,
        AuthGuard,
        AuthService,
        AuthenticatedHttp,
        CommitmentService,
        DealerPermissionGuard,
        DealerService,
        ExportService,
        IdleService,
        LoggingService,
        MessageCenterService,
        PermissionGuard,
        PermissionService,
        PreferenceService,
        ProgramService,
        ReCaptchaService,
        StateService,
        StorageService,
        UtilityService
    ]
})
export class CoreModule { }
