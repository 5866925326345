import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'flattenObject' })
export class FlattenObjectPipe implements PipeTransform {
    transform(obj: any, delimiter: string = ', ') {
        const values = [];
        for (const key of Object.keys(obj)) {
            values.push(obj[key]);
        }

        return values.join(delimiter);
    }
}
