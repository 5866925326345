import * as moment from 'moment';

import { CommitmentHeader } from './commitment-header.model';
import { CreditServiceFee } from './credit-service-fee.model';
import { DealerHeader } from './dealer-header.model';
import { GrowerDiscount } from './grower-discount.model';
import { ParticipatingDealer } from './participating-dealer.model';
import { ParticipatingTag } from './participating-tag.model';
import { PaymentTerms } from './payment-terms.model';
import { PostMaturityRateStep } from './post-maturity-rate-step.model';
import { ProgramDocumentHeader } from './program-document-header.model';
import { ProgramHeader } from './program-header.model';
import { ProgramIncentive } from './program-incentive.model';
import { UserHeader } from './user-header.model';
import { ProgramProductUnitOfMeasures } from './program-product-unit-of-measure.model';
import { ProgramSponsorReference } from './program-sponsor-reference.model';

export class Program extends ProgramHeader {
    allowedProducts: Array<string>;
    vendorProducts: Array<string>;
    creditServiceFee: CreditServiceFee;
    documents: Array<ProgramDocumentHeader>;
    endDate: moment.Moment;
    maturityDate: moment.Moment;
    participatingDealers: Array<ParticipatingDealer>;
    participatingTags: Array<ParticipatingTag>;
    standardIncentive: ProgramIncentive;
    performanceIncentive: ProgramIncentive;
    sponsoringDealer: DealerHeader;
    masterCommitment: CommitmentHeader;
    startDate: moment.Moment;
    paymentTerms: string;
    isActive: boolean;
    draftProgram: Program;
    lastEditedUser: UserHeader;
    lastApprovedUser: UserHeader;
    creditLimit: number;
    irbdBillingFrequency: string;
    initialIrbdBillingDate: moment.Moment;
    irbdAdvancePercent: number;
    advanceRecipient: DealerHeader;
    advanceRecipientDealerSelectionType: string;
    useCommitmentValues: boolean;
    isPublic: boolean;
    growerDiscount: GrowerDiscount;
    postMaturityRateStep: PostMaturityRateStep;
    isProductQuantityRequired: boolean;
    isProductUnitOfMeasureRequired: boolean;
    programProductUnitOfMeasures: Array<ProgramProductUnitOfMeasures>;
    vendorProductPercent: number;
    isVendorProductPercentEnabled: boolean;
    isBridgeEnabled: boolean;
    bridgeStartDate: moment.Moment;
    bridgeEndDate: moment.Moment;
    bridgeExpirationDate: moment.Moment;
    parentProgram: Program;
    childPrograms: Array<Program>;
    originalProgramId: number;
    programSponsorReference: ProgramSponsorReference;

    constructor(appData?: any) {
        super(appData);

        if (appData) {
            this.allowedProducts = appData.AllowedProducts ? appData.AllowedProducts : null;
            this.vendorProducts = appData.VendorProducts ? appData.VendorProducts : null;
            this.creditServiceFee = new CreditServiceFee(appData.CreditServiceFee);
            this.documents = appData.Documents ? appData.Documents.map((data) => new ProgramDocumentHeader(data)) : [];
            this.endDate = appData.EndDate ? moment(appData.EndDate) : null;
            this.maturityDate = appData.MaturityDate ? moment(appData.MaturityDate) : null;

            this.participatingDealers = appData.ParticipatingDealers
                ? appData.ParticipatingDealers.map((data) => new ParticipatingDealer(data))
                : null;
            this.participatingTags = appData.ParticipatingTags ? appData.ParticipatingTags.map((data) => new ParticipatingTag(data)) : null;
            this.standardIncentive = appData.StandardIncentive
                ? new ProgramIncentive(appData.StandardIncentive)
                : null;
            this.performanceIncentive = appData.PerformanceIncentive
                ? new ProgramIncentive(appData.PerformanceIncentive)
                : null;

            this.sponsoringDealer = appData.SponsoringDealer ? new DealerHeader(appData.SponsoringDealer) : null;
            this.masterCommitment = appData.MasterCommitment ? new CommitmentHeader(appData.MasterCommitment) : null;
            this.startDate = appData.StartDate ? moment(appData.StartDate) : null;
            this.paymentTerms = appData.PaymentTerms ? appData.PaymentTerms : PaymentTerms.getDefaultTerm().getValue();
            this.isActive = appData.IsActive;
            this.draftProgram = appData.DraftProgram ? new Program(appData.DraftProgram) : null;
            this.lastEditedUser = appData.LastEditedUser ? new UserHeader(appData.LastEditedUser) : null;
            this.lastApprovedUser = appData.LastApprovedUser ? new UserHeader(appData.LastApprovedUser) : null;
            this.creditLimit = appData.CreditLimit ? appData.CreditLimit : null;
            this.irbdBillingFrequency = appData.IrbdBillingFrequency;
            this.initialIrbdBillingDate = appData.InitialIrbdBillingDate ? moment(appData.InitialIrbdBillingDate) : null;
            this.irbdAdvancePercent = appData.IrbdAdvancePercent;
            this.advanceRecipient = appData.AdvanceRecipient ? new DealerHeader(appData.AdvanceRecipient) : null;
            this.advanceRecipientDealerSelectionType = appData.AdvanceRecipientDealerSelectionType;
            this.useCommitmentValues = appData.UseCommitmentValues;
            this.isPublic = appData.IsPublic;
            this.growerDiscount = appData.GrowerDiscount ? new GrowerDiscount(appData.GrowerDiscount) : null;
            this.postMaturityRateStep = appData.PostMaturityRateStep ? new PostMaturityRateStep(appData.PostMaturityRateStep) : null;
            this.isProductQuantityRequired = appData.IsProductQuantityRequired;
            this.isProductUnitOfMeasureRequired = appData.IsProductUnitOfMeasureRequired;
            this.programProductUnitOfMeasures = appData.ProgramProductUnitOfMeasures
                ? appData.ProgramProductUnitOfMeasures.map((data) => new ProgramProductUnitOfMeasures(data))
                : null;
            this.isVendorProductPercentEnabled = appData.IsVendorProductPercentEnabled;
            this.vendorProductPercent = appData.VendorProductPercent;

            this.isBridgeEnabled = appData.IsBridgeEnabled;
            this.bridgeStartDate = appData.BridgeStartDate ? moment(appData.BridgeStartDate) : null;
            this.bridgeEndDate = appData.BridgeEndDate ? moment(appData.BridgeEndDate) : null;
            this.bridgeExpirationDate = appData.BridgeExpirationDate ? moment(appData.BridgeExpirationDate) : null;
            this.parentProgram = new Program(appData.ParentProgram);
            this.childPrograms = appData.ChildPrograms ? appData.ChildPrograms.map((data) => new Program(data)) : null;
            this.originalProgramId = appData.OriginalProgramId;
            this.programSponsorReference = appData.ProgramSponsorReference ? new ProgramSponsorReference(appData.ProgramSponsorReference) : null;
        }
    }

    checkOverrideExtension(): moment.Moment {
        if (this.participatingDealers && this.participatingDealers.length > 0 && this.endDate) {
            let mostRecent = this.endDate;
            for (const participatingDealer of this.participatingDealers) {
                if (participatingDealer.endDate > mostRecent) {
                    mostRecent = participatingDealer.endDate;
                }
            }
            return (mostRecent !== this.endDate) ? mostRecent : null;
        }
    }

    getDealerSpecificStartDate(dealerId: number): moment.Moment {
        if (this.participatingDealers && this.participatingDealers.length > 0 && this.startDate) {
            const filteredParticipatingDealers = this.participatingDealers.filter(x => x.dealer.rowId === dealerId);
            for (const participatingDealer of filteredParticipatingDealers) {
                if (participatingDealer.startDate && participatingDealer.startDate != null) {
                    return participatingDealer.startDate;
                }
            }
        }

        return this.startDate;
    }

    getDealerSpecificEndDate(dealerId: number): moment.Moment {
        if (this.participatingDealers && this.participatingDealers.length > 0 && this.endDate) {
            const filteredParticipatingDealers = this.participatingDealers.filter(x => x.dealer.rowId === dealerId);
            for (const participatingDealer of filteredParticipatingDealers) {
                if (participatingDealer.endDate && participatingDealer.endDate != null) {
                    return participatingDealer.endDate;
                }
            }
        }

        return this.endDate;
    }

    getIntroductoryGrowerRate(): string {
        if (!this.standardIncentive) {
            return null;
        }

        return this.standardIncentive.getIntroductoryGrowerRate();
    }

    getPerformanceIntroductoryGrowerRate(): string {
        if (!this.performanceIncentive) {
            return null;
        }

        return this.performanceIncentive.getIntroductoryGrowerRate();
    }
}
