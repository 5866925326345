import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AccountService } from '../../core/services/account.service';
import { validatePassword } from '../../shared/validators/validate-password.validator';
import { passwordMatcher } from '../../shared/validators/password-matcher.validator';
import { ExpiredPasswordChange } from '../../my-profile/models/expired-password-change.model';

@Component({
    selector: 'dol-password-expired',
    templateUrl: 'password-expired.component.html',
    styleUrls: ['./login-form.component.css']
})
export class PasswordExpiredComponent {
    @Input() email: string;
    @Input() oldPassword: string;
    @Output() passwordUpdatedNotification: EventEmitter<string> = new EventEmitter<string>();
    confirmNewPassword: string;
    serverError: string;
    password: FormControl = new FormControl('', Validators.compose([Validators.required, validatePassword]));
    confirmPassword: FormControl = new FormControl('', Validators.required);
    passwordGroup: FormGroup;
    form: FormGroup;
    submitted: boolean;
    loading: boolean;

    constructor(private router: Router, private authService: AccountService) {
        this.passwordGroup = new FormGroup({
            'newPassword': this.password,
            'confirmPassword': this.confirmPassword
        }, passwordMatcher);
        this.form = new FormGroup({
            passwords: this.passwordGroup
        });
        this.form.addControl('passwordGroup', this.passwordGroup);
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }
        return message;
    }

    submit() {
        this.submitted = true;
        this.serverError = '';
        if (this.form.valid) {
            this.loading = true;
            const request = new ExpiredPasswordChange();
            request.email = this.email;
            request.oldPassword = this.oldPassword;
            request.newPassword = this.password.value;
            this.authService.changeExpiredPassword(request)
                .pipe(
                    finalize(() => { this.loading = false; })
                )
                .subscribe(() => {
                    this.passwordUpdatedNotification.emit(this.password.value);
                }, (rejected) => {
                    this.serverError = JSON.parse(rejected.error).Message;
                });
        }
    }
}
