import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[dolAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
    constructor(public renderer: Renderer2, public el: ElementRef) { }

    ngOnInit() {
        this.el.nativeElement.focus();
    }
}
