<div [formGroup]="transactionLimitFormGroup" class="transaction-limit-fields">
    <dol-numeric-input class="full-width" formControlName="amount" [commas]="true" placeholder="Amount" prefix="$">
    </dol-numeric-input>
    <div *ngIf="transactionLimitFormGroup.get('amount').hasError('min')" class="error ng-invalid">Amount must be a
        positive number</div>
    <div class="transaction-limit-duration">
        <dol-numeric-input class="full-width" formControlName="durationLength" placeholder="Number">
        </dol-numeric-input>
        <mat-form-field>
            <mat-select formControlName="durationType" placeholder="Duration">
                <mat-option *ngFor="let durationType of durationTypes" [value]="durationType.value">
                    {{durationType.readableValue}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="transactionLimitFormGroup.get('durationLength').hasError('min')" class="error ng-invalid">Duration
        number must be positive</div>
    <div *ngIf="transactionLimitFormGroup.hasError('missingFields')" class="error ng-invalid">All fields are
        required
    </div>
</div>