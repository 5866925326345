import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { DurationType } from '../models/duration-type.model';
import { DURATION_TYPES } from '../models/duration-types';
import { TransactionLimit } from '../models/transaction-limit.model';
import { ifOneRequireAll } from '../validators/if-one-require-all.validator';

@Component({
    selector: 'dol-transaction-limit',
    templateUrl: './transaction-limit.component.html',
    styleUrls: ['./transaction-limit.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TransactionLimitComponent }
    ]
})
export class TransactionLimitComponent implements ControlValueAccessor, OnInit, OnDestroy {
    _onChange: (value: any) => void;
    durationTypes: Array<DurationType> = DURATION_TYPES;
    subscription: Subscription;
    transactionLimitFormGroup: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.transactionLimitFormGroup = this.fb.group({
            transactionLimitId: null,
            amount: [null, Validators.min(1)],
            durationLength: [null, Validators.min(1)],
            durationType: null
        }, { validator: ifOneRequireAll(['amount', 'durationLength', 'durationType']) });

        this.subscription = this.transactionLimitFormGroup.valueChanges.subscribe(changes => {
            const value = this.transactionLimitFormGroup.valid ? changes : null;
            this._onChange(value);
        });
    }

    writeValue(transactionLimit: TransactionLimit) {
        if (transactionLimit) {
            this.transactionLimitFormGroup.patchValue(transactionLimit, { emitEvent: false });
        } else if (this._onChange) {
            this.transactionLimitFormGroup.reset({
                transactionLimitId: null,
                amount: null,
                durationLength: null,
                durationType: null
            }, { emitEvent: false });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    registerOnTouched() { }
}
