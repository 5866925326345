import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CONFIG } from '../environments/environment';
import { AuthGuard } from './core/services/authentication/auth-guard.service';
import { PermissionGuard } from './core/services/authorization/permission-guard.service';
import { PROGRAM_VIEW } from './shared/models/permissions';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dealers',
        loadChildren: () => import('./dealers/dealers.module').then(m => m.DealersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'documents',
        loadChildren: () => import('./dealer-documents/dealer-documents.module').then(m => m.DealerDocumentsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-profile',
        loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-programs',
        loadChildren: () => import('./my-programs/my-programs.module').then(m => m.MyProgramsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'programs',
        loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            permission: PROGRAM_VIEW,
            redirectTo: CONFIG.redirectToPath
        }
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'renewals',
        loadChildren: () => import('./renewals/renewals.module').then(m => m.RenewalsModule),
        canActivate: [AuthGuard]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
