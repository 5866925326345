import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DealerRegisterComponent } from '../../app/login/dealer-register/dealer-register.component';
import { EulaFormComponent } from './eula-form/eula-form.component'; // TODO:MFA
import { LoginComponent } from '../../app/login/login.component';
import { LoginFormComponent } from './login-form/login-form.component'; // TODO:MFA
import { B2CLoginComponent } from './login-form/b2c-login.component'; // TODO:MFA

import { HasLoginPropertyGuard } from '../core/services/guards/has-login-property-guard'; // TODO:MFA
import { LoggedInGuard } from '../../app/login/services/logged-in-guard.service';
import { VERSIONCONFIG } from '../version-config';
import { SiteComponent } from '../site.component';

const routes: Routes = [
    {
        path: VERSIONCONFIG.path,
        children: [
            // the following components have header/footer displayed
            {
                path: '',
                component: SiteComponent,
                children: [
                    {
                        path: 'home',
                        component: LoginFormComponent,
                        canActivate: [LoggedInGuard],
                        children: [
                            {
                                path: 'dealer-register',
                                redirectTo: `/${VERSIONCONFIG.redirectPrefix}login/dealer-register`
                            }
                        ]
                    },
                    {
                        path: 'login',
                        component: LoginComponent,
                        canActivate: [LoggedInGuard],
                        children: [
                            {
                                path: '',
                                component: LoginFormComponent
                            },
                            {
                                path: 'dealer-register',
                                component: DealerRegisterComponent
                            },
                            {
                                path: 'eula',
                                component: EulaFormComponent,
                                canActivate: [HasLoginPropertyGuard]
                            }
                        ]
                    },
                ],
            },
            // the following components are displayed in B2C content frames without header/footer
            {
                path: 'login-b2c',
                component: B2CLoginComponent,
                canActivate: [LoggedInGuard]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [HasLoginPropertyGuard, LoggedInGuard]
})
export class LoginRoutingModule { }
