import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { StateService } from '../../../../app/core/services/state.service';
import { LoginService } from '../../../../app/login/services/login.service';
import { AuthService } from '../authentication/auth.service';

@Injectable({
    providedIn: 'root'
})
export class HasLoginPropertyGuard implements CanActivate {
    constructor(private stateService: StateService,
        private authService: AuthService,
        private loginService: LoginService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.stateService.currentUser;
        const isAuthenticated = this.authService.loggedIn(true);

        if (!isAuthenticated) {
            this.router.navigate(['/login']);
        } else if (user.acceptedEula) {
            this.authService.redirectPostLogin();
        }

        this.loginService.user = user;
        return isAuthenticated;
    }
}
