import { IndexType } from './index-type.model';

export class InterestRate {
    type: string;
    rate: number;

    constructor(appData?: any) {
        if (appData) {
            this.rate = appData.Rate;
            this.type = appData.Type;
        }
    }

    public toString = (): string => {
        const type = IndexType.getType(this.type);
        const displayValue = type ? type.getDisplayValue() : this.type;
        return displayValue + this.rateToString();
    }

    public rateToString = (): string => {
        return (this.rate < 0 ? ' - ' : ' + ') + Math.abs(this.rate) + '%';
    }
}
