<if-notification></if-notification>
<div class="header-wrapper">
    <div *ngIf="environment !== 'prod'" class="dev-banner">{{environment ? environment : 'unknown'}}</div>
    <dol-header-bar></dol-header-bar>
</div>
<router-outlet></router-outlet>
<dol-footer-bar></dol-footer-bar>
<dol-modal-dialog [show]="showWarning" (close)="showWarning = false">
    <div class="modal-body">
        <h1>Are you still there?</h1>
        <p>For your security, we will log you out in...</p>
        <div class="modal-countdown">{{countdown}}</div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" class="btn btn-brand" (click)="okWarning()">Stay logged in</button>
        <button mat-button type="button" class="btn btn-default" (click)="logout()">Logout</button>
    </div>
</dol-modal-dialog>
<dol-modal-dialog [show]="showLogout" (close)="showLogout = false">
    <div class="modal-body">
        <h1>You were logged out.</h1>
        <p>We logged you out for your security.</p>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" class="btn btn-brand" (click)="showLogout = false">Ok</button>
    </div>
</dol-modal-dialog>
<dol-modal-dialog [show]="showBowserAlert" (close)="showBowserAlert = false">
    <div class="modal-body">
        <h1>Unsupported browser</h1>
        <p>This website is designed to work best when using an up to date internet browser. Edge 88.0, Chrome 87.0,
            FireFox 80.0 or later versions ensure that all features and functionality are available.</p>
        <p> Please update to the latest version to optimize your experience.</p>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" class="btn btn-brand" (click)="showBowserAlert = false">Ok</button>
    </div>
</dol-modal-dialog>