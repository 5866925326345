import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG } from '../../../../environments/environment';
import { PendingDealerBindingModel } from '../models/pending-dealer-binding.model';

@Injectable({
    providedIn: 'root'
})
export class DealerRegisterService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: HttpClient) { }

    register(pendingDealer: PendingDealerBindingModel): Observable<number> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/RegisterPendingDealer';
        return this.http.post<number>(apiUrl, pendingDealer, { headers: this.headers });
    }

    checkAvailability(email: string): Observable<string> {
        const apiUrl = CONFIG.apiBaseUrl + 'Dealer/CheckAvailability';
        return this.http.post<string>(apiUrl, { Email: email }, { headers: this.headers });
    }
}
