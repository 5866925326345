import { Address } from './address.model';

export class FullAddress {
    addressInfo: Address = new Address();
    addressDescription = '';

    constructor(appData?: any) {
        if (appData) {
            this.addressInfo = new Address();
            this.addressInfo.line1 = appData.AddressInfo.Line1;
            this.addressInfo.line2 = appData.AddressInfo.Line2;
            this.addressInfo.city = appData.AddressInfo.City;
            this.addressInfo.state = appData.AddressInfo.State;
            this.addressInfo.zipcode = appData.AddressInfo.ZipCode;
            this.addressDescription = appData.AddressDescription;
        }
    }
}
