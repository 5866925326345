import * as moment from 'moment';

export class PendingMasterCommitment {
    pendingMasterCommitmentId: number;
    initialUserId: string;
    dealerId: number;
    amount: number;
    maturityDate: moment.Moment;

    constructor(appData?: any) {
        if (appData) {
            this.pendingMasterCommitmentId = appData.PendingMasterCommitmentId;
            this.initialUserId = appData.InitialUserId;
            this.dealerId = appData.DealerId;
            this.amount = appData.Amount;
            this.maturityDate = appData.MaturityDate ? moment(appData.MaturityDate) : null;
        }
    }
}
