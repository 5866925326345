import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { buildPhoneMask } from '../../shared/masks/masks';
import { validateEmail } from '../../shared/validators/email.validator';
import { PendingDealerBindingModel } from './models/pending-dealer-binding.model';
import { finalize } from 'rxjs/operators/finalize';

import { AuthService } from '../../core/services/authentication/auth.service';
import { NotificationService } from 'if-angular-core';
import { UtilityService } from '../../core/services/utility.service';
import { DealerRegisterService } from './services/dealer-register.service';

@Component({
    selector: 'dol-dealer-register',
    templateUrl: './dealer-register.component.html',
    styleUrls: ['./dealer-register.component.css']
})
export class DealerRegisterComponent implements OnInit {
    cachedEmail: string;
    emailIsAvailable: boolean;
    registerForm: FormGroup;
    submitted: boolean;
    submittedRegister: boolean;
    reviewMode: boolean;
    recaptchaToken: string;
    submitDisabled: boolean;
    newDealerAddress: boolean;
    errorMessage: string;
    phoneMask = buildPhoneMask();

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private dealerRegisterService: DealerRegisterService,
        private notificationService: NotificationService,
        private util: UtilityService, private router: Router) { }

    ngOnInit() {
        this.registerForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, validateEmail])],
            legalName: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            address: [null, Validators.required],
            phone: ['', Validators.required],
            fax: '',
            remitAddress: [null, Validators.required],
            participatingPrograms: null
        });

        this.submitted = false;
        this.submittedRegister = false;
        this.reviewMode = false;
        this.newDealerAddress = true;
    }


    copy(source: AbstractControl, destination: AbstractControl) {
        destination.patchValue(source.value);
    }

    submitForReview() {
        this.submitted = true;

        if (this.registerForm.valid) {
            const submittedEmail = this.registerForm.get('email').value;


            if (submittedEmail === this.cachedEmail) {
                this.switchMode('review');
            } else {
                this.cachedEmail = submittedEmail;
                this.switchMode('review');

            }
        } else {
            this.util.scrollTo('form .ng-invalid');
        }
    }

    switchMode(mode: string) {
        if (mode === 'review') {
            this.reviewMode = true;
            this.registerForm.disable();
        } else if (mode === 'edit') {
            this.submitted = false;
            this.reviewMode = false;
            this.registerForm.enable();
        }
        window.scrollTo(0, 0);
    }

    register() {
        this.submittedRegister = true;
        if (this.recaptchaToken) {
            this.submitDisabled = true;
            const pendingDealer: PendingDealerBindingModel = new PendingDealerBindingModel(this.registerForm.getRawValue());
            pendingDealer.recaptchaToken = this.recaptchaToken;

            this.dealerRegisterService.register(pendingDealer)
                .pipe(
                    finalize(() => { this.submitDisabled = false; })
                )
                .subscribe(() => {
                    this.notificationService.push(
                        {
                            severity: 'success',
                            summary: 'Registration complete',
                            detail: 'Registration is complete. Your request has been sent and will be reviewed.'
                        });
                    this.router.navigate(['/']);
                }, (err: HttpErrorResponse) => {
                    console.log(err);
                    this.notificationService.push(
                        {
                            severity: 'error',
                            summary: 'An error occurred',
                            detail: 'An error occurred while registering'
                        });
                });
        }
    }

    recaptchaSolved(token: string) {
        this.recaptchaToken = token;
    }
}
