import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthenticatedHttp } from '../../core/services/authentication/authenticated-http';
import { CONFIG } from '../../../environments/environment';
import { Observable } from 'rxjs';

export class ProgressStatus {
    PercentComplete: number;
    StatusDescription: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProgressService {
    constructor(private http: AuthenticatedHttp) { }

    getProgress<T>(
        keyFor: string,
        onProgress: (progress: ProgressStatus) => void,
        onComplete: (result: T) => void,
        onError: (message: HttpErrorResponse) => void,
        intervalInSeconds: number = 3) {
        const self = this;
        const interval = intervalInSeconds * 1000;
        const fn = function (key) {
            self.getOneTimeProgress(keyFor)
                .subscribe(res => {
                    if (res.PercentComplete < 100) {
                        onProgress(res);
                        setTimeout(() => fn(keyFor), interval);
                    } else {
                        onProgress(res);
                        setTimeout(() => { onComplete(res.Result); }, 500);
                    }
                }, error => onError(error)
                );
        };
        fn(keyFor);
    }

    private getOneTimeProgress(key: string): Observable<any> {
        const url = `${CONFIG.apiBaseUrl}progress/${key}`;

        return this.http.get(url, null, false);
    }
}
