import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { B2cContentHostComponent } from './b2c-content-host/b2c-content-host.component';

import { SharedModule as SharedModuleV1} from '../../app/shared/shared.module';

const modules = [
    CommonModule,
    SharedModuleV1
];

const components = [
    B2cContentHostComponent
];

const directives = [
];

const pipes = [
];

@NgModule({
    imports: [modules],
    declarations: [components, directives, pipes],
    exports: [components, directives, pipes], //TODO:MFA
    // providers: [ProgressService] //TODO:MFA
})
export class SharedModule { }
