import { Component, OnInit } from '@angular/core';

import { CONFIG } from '../../../environments/environment';
import { AuthenticatedHttp } from '../../core/services/authentication/authenticated-http';
import { MessageCenterService } from '../../core/services/message-center/message-center.service';
import { MessageGroup } from '../models/message-group.model';

@Component({
    selector: 'dol-message-center',
    templateUrl: 'message-center.component.html',
    styleUrls: ['./message-center.component.css']
})
export class MessageCenterComponent implements OnInit {
    messageGroups: MessageGroup[];

    constructor(private messageService: MessageCenterService) { }

    ngOnInit() {
        this.getMessages();
    }

    getMessages() {
        this.messageService.getMessages()
            .subscribe((res) => {
                if (res) {
                    const grouped = this.groupBy(res, 'messageHeader');
                    const headers = Object.keys(grouped);
                    this.messageGroups = headers.map(header => new MessageGroup(
                        {
                            header: header,
                            messages: grouped[header].map(msg => msg.text)
                        }));
                } else {
                    this.messageGroups = null;
                }
            });
    }

    showMessages(): boolean {
        return this.messageGroups && this.messageGroups.length > 0;
    }

    groupBy(arr: any[], key: string) {
        return arr.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }
}
