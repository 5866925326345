import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SecurityImageBasePath, SecurityImages } from '../../data/security-images';
import { SecurityImage } from '../../models/security-image.model';
import { isNumeric } from '../../utilities';

@Component({
    selector: 'dol-security-image-picker',
    templateUrl: './security-image-picker.component.html',
    styleUrls: ['./security-image-picker.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SecurityImagePickerComponent }
    ]
})
export class SecurityImagePickerComponent implements ControlValueAccessor {
    onChange: (value: any) => void;
    selectedIndex: number;
    securityImageUris: Array<string> = SecurityImages.map(securityImage => SecurityImageBasePath + securityImage.uri);

    writeValue(id: number) {
        if (isNumeric(id)) {
            this.selectedIndex = this.findIndexById(id);
        }
    }

    handleSelection(index: number) {
        const id = (index !== null) ? SecurityImages[index].id : null;
        this.onChange(id);
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) { }

    private findIndexById(id: number): number {
        for (let index = 0; index < SecurityImages.length; index++) {
            if (SecurityImages[index].id === id) {
                return index;
            }
        }

        return null;
    }
}
