import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import * as Bowser from 'bowser';

import { CONFIG } from '../environments/environment';
import { AuthService } from './core/services/authentication/auth.service';
import { IdleService } from './core/services/idle/idle.service';
import { NotificationService } from 'if-angular-core';

@Component({
  selector: 'dol-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  environment: string;
  showWarning: boolean;
  showLogout: boolean;
  countdown: number;
  showBowserAlert: boolean;

  constructor(
    private authService: AuthService,
    private idleService: IdleService,
    private notificationService: NotificationService,
    private router: Router) {
    this.environment = CONFIG.env;
  }

ngOnInit() {
    this.browserCheck();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && this.authService.loggedIn()) {
        this.authService.refreshToken()
          .subscribe(() => {

          }, (err: HttpErrorResponse | Error) => {
            const reason = (err instanceof HttpErrorResponse) ? err.error : err;
            console.log('Could not refresh token during AppComponent start:', reason);
          });
      }
    });

    this.idleService.event$.subscribe((event) => {
      if (event.type === 'warning') {
        this.showWarning = true;
      } else if (event.type === 'tick') {
        this.countdown = event.content;
      } else if (event.type === 'logout') {
        this.showLogout = true;
        this.logout();
      }
    });
  }

  okWarning() {
    this.showWarning = false;
    this.authService.refreshToken()
      .subscribe(() => {

      }, (err: HttpErrorResponse | Error) => {
        const reason = (err instanceof HttpErrorResponse) ? err.error : err;
        console.log('Could not refresh token:', reason);
        this.notificationService.push(
          {
            severity: 'error',
            summary: 'An error occurred',
            detail: 'An error occurred while refreshing the token.'
          });
      });
  }

  logout(): void {
    this.showWarning = false;
    this.authService.logout();
    this.router.navigate(['']);
  }

  browserCheck() {
    const browser = Bowser.getParser(window.navigator.userAgent);

    this.showBowserAlert = !browser.satisfies({
      chrome: '>=87',
      firefox: '>=80',
      edge: '>=88'
    });
  }
}
