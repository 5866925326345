export class ProgramSponsorReference {
    sponsorReferenceLabel: string;
    programId: number;
    isEnabled: boolean;

    constructor(appData?: any) {
        if (appData) {
            this.sponsorReferenceLabel = appData.SponsorReferenceLabel ? appData.SponsorReferenceLabel : null;
            this.programId = appData.ProgramId ? appData.ProgramId : null;
            this.isEnabled = appData.IsEnabled ? appData.IsEnabled : null;
        }
    }
}
