import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dol-password-confirmation',
    templateUrl: './password-confirmation.component.html',
    styleUrls: [ './password-confirmation.component.css' ]
})
export class PasswordConfirmationComponent implements OnInit {
    constructor() { }

    ngOnInit() {
        if (window.top.location != window.location) {
            window.top.location = window.location;
        }
    }
}
