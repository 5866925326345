import { Component } from '@angular/core';

@Component({
    selector: 'dol-update-password-confirmation',
    template: `
        <div class="stage container">
            <div class="confirmation-status group">
                <div class="confirmation-status-icon">
                    <i class="fa fa-check-square"></i>
                </div>
                <div class="confirmation-status-message">
                    <h1>Password Reset</h1>
                    <p>We reset your password successfully.</p>
                    <button mat-raised-button type="button" class="btn btn-default btn-confirm" routerLink="">Home</button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./update-password-confirmation.component.css']
})
export class UpdatePasswordConfirmationComponent { }
