import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChallengeFormComponent } from './challenge-form/challenge-form.component';
import { DealerRegisterComponent } from './dealer-register/dealer-register.component';
import { EulaFormComponent } from './eula-form/eula-form.component';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { UpdateChallengeFormComponent } from './update-challenge-form/update-challenge-form.component';

import { HasLoginPropertyGuard } from './services/has-login-property-guard.service';
import { LoggedInGuard } from './services/logged-in-guard.service';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoggedInGuard],
        children: [
            {
                path: '',
                component: LoginFormComponent
            },
            {
                path: 'challenge',
                component: ChallengeFormComponent,
                canActivate: [HasLoginPropertyGuard],
                data: {
                    requiredProperties: ['credentials', 'securityChallenge']
                }
            },
            {
                path: 'dealer-register',
                component: DealerRegisterComponent
            },
            {
                path: 'eula',
                component: EulaFormComponent,
                canActivate: [HasLoginPropertyGuard],
                data: {
                    requiredProperties: ['credentials', 'securityChallenge', 'user']
                }
            },
            {
                path: 'update-challenge',
                component: UpdateChallengeFormComponent,
                canActivate: [HasLoginPropertyGuard],
                data: {
                    requiredProperties: ['credentials', 'securityChallenge']
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [HasLoginPropertyGuard, LoggedInGuard]
})
export class LoginRoutingModule { }
