import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { CONFIG } from '../../../environments/environment';
import { ColumnDefinition } from '../../shared/models/column-definition.model';
import { CustomReport } from '../../shared/models/custom-report.model';
import { DefaultColumns } from '../../shared/models/default-columns.model';
import { AuthenticatedHttp } from './authentication/authenticated-http';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root'
})
export class PreferenceService {
    defaultColumns: DefaultColumns;
    displayedColumns: Array<string>;
    columnDefinitions: Array<ColumnDefinition>;
    numberOfCustomCustomerValues: number;
    reportName: string;
    reportType: string;

    constructor(private http: AuthenticatedHttp, private stateService: StateService) { }

    getColumns(reportType: string): Array<string> {
        const currentUser = this.stateService.currentUser;
        const match = (currentUser && currentUser.preferences)
            ? currentUser.preferences.customReports.find(customReport => customReport.name === reportType)
            : null;
        return (match && match.columns && match.columns.length > 0) ? match.columns : null;
    }

    updateCustomReport(report: CustomReport): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}users/preferences/table/${report.name}`;
        return this.http.post(url, report, { responseType: 'text' });
    }

    updateCurrentUserCustomReport(updatedReport: CustomReport): void {
        const currentUser = this.stateService.currentUser;
        const currentCustomReport = currentUser.preferences.customReports.find(report => report.name === updatedReport.name);

        if (currentCustomReport) {
            currentCustomReport.columns = updatedReport.columns;
        } else {
            currentUser.preferences.customReports.push(updatedReport);
        }

        this.stateService.saveUser(currentUser);
    }
}
