import * as moment from 'moment';
import { FormArray } from '@angular/forms';

export function isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
}

export function getCurrentCropYear(): number {
    const today = moment();
    return today.get('year') + (today.get('month') + 1 <= 8 ? 0 : 1);
}

export function checkIfUnchanged(prev, curr) {
    let queryUnchanged;

    for (const [key, value] of Object.entries(curr)) {
        queryUnchanged = prev[key] === value;
        if (!queryUnchanged) { break; }
    }

    return queryUnchanged;
}

export function runScript(location: string, async: boolean = false) {
    const script = document.createElement('script');
    script.src = location;
    if (async) {
        script.setAttribute('async', '');
    }
    document.body.appendChild(script);
}

export function range(minimum: number, maximum: number): Array<number> {
    return Array
        .apply(null, Array(maximum - minimum + 1))
        .map((_, i) => maximum - i);
}

export function sortFormArray(formArray: FormArray) {
    formArray.controls.sort(
        (c1, c2) => {
            const v1 = c1.value.toLowerCase();
            const v2 = c2.value.toLowerCase();
            if (v1 > v2) {
                return 1;
            } else if (v1 < v2) {
                return -1;
            } else {
                return 0;
            }
        });
}
