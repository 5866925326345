import * as moment from 'moment';

import { Address } from '../../shared/models/address.model';
import { DealerHeader } from '../../shared/models/dealer-header.model';
import { ParticipatingTag } from '../../shared/models/participating-tag.model';
import { Program } from '../../shared/models/program.model';
import { TransactionLimit } from '../../shared/models/transaction-limit.model';
import { Comment } from './comment.model';
import { DealerBase } from './dealer-base.model';
import { DealerDocument } from './dealer-document.model';

export class Dealer extends DealerBase {
    useAutomatedClearingHouse: boolean;
    advanceRecipient: DealerHeader;
    agreementTemplate: string;
    approved: boolean;
    attentionToName: string;
    childDealers: Array<DealerHeader>;
    dateOfOrganization: moment.Moment;
    effectiveDate: moment.Moment;
    email: string;
    entityType: string;
    evergreen: boolean;
    expirationDate: moment.Moment;
    inactive: boolean;
    dealerRegistrationInProgress: boolean;
    legalName: string;
    parentDealer: DealerHeader;
    phoneNumbers: Array<string>;
    physicalAddress: Array<Address>;
    setupDate: moment.Moment;
    participatingTags: Array<ParticipatingTag>;
    taxIdNumber: string;
    vendorDecision: boolean;
    programs: Array<Program>;
    comments: Array<Comment>;
    documents: Array<DealerDocument>;
    transactionLimit: TransactionLimit;
    screeningResult: string;
    lastScreeningDate: moment.Moment;

    constructor(appData?: any) {
        super(appData);

        if (appData) {
            this.useAutomatedClearingHouse = appData.UseAutomatedClearingHouse;
            this.advanceRecipient = appData.AdvanceRecipient ? new DealerHeader(appData.AdvanceRecipient) : null;
            this.agreementTemplate = appData.AgreementTemplate;
            this.approved = appData.Approved;
            this.attentionToName = appData.AttentionToName;
            this.childDealers = appData.ChildDealers.map(cd => new DealerHeader(cd));
            this.dateOfOrganization = appData.DateOfOrganization ? moment(appData.DateOfOrganization) : null;
            this.effectiveDate = appData.EffectiveDate ? moment(appData.EffectiveDate) : null;
            this.email = appData.Email;
            this.entityType = appData.EntityType;
            this.evergreen = appData.Evergreen;
            this.expirationDate = appData.ExpirationDate ? moment(appData.ExpirationDate) : null;
            this.inactive = appData.Inactive;
            this.dealerRegistrationInProgress = appData.DealerRegistrationInProgress;
            this.legalName = appData.LegalName;
            this.parentDealer = appData.ParentDealer == null ? null : new DealerHeader(appData.ParentDealer);
            this.phoneNumbers = appData.PhoneNumbers;
            this.physicalAddress = appData.PhysicalAddress.map(address => new Address(address));
            this.setupDate = appData.SetupDate ? moment(appData.SetupDate) : null;
            this.participatingTags = appData.ParticipatingTags ? appData.ParticipatingTags.map(pt => new ParticipatingTag(pt)) : [];
            this.taxIdNumber = appData.TaxIdNumber;
            this.vendorDecision = appData.VendorDecision;
            this.comments = appData.Comments ? appData.Comments.map(data => new Comment(data)) : [];
            this.documents = appData.Documents ? appData.Documents.map(data => new DealerDocument(data)) : [];
            this.transactionLimit = appData.TransactionLimit ? new TransactionLimit(appData.TransactionLimit) : null;
            this.screeningResult = appData.ScreeningResult;
            this.lastScreeningDate = appData.LastScreeningDate ? moment(appData.LastScreeningDate) : null;
        }
    }
}
