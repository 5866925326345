import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { CONFIG } from '../../../environments/environment';
import { Credentials } from '../../login/models/credentials.model';
import { SecurityChallenge } from '../../login/models/security-challenge.model';
import { SecurityChallengeUpdates } from '../../login/models/security-challenge-updates.model';
import { EmailChange } from '../../my-profile/models/email-change.model';
import { PasswordChange } from '../../my-profile/models/password-change.model';
import { ExpiredPasswordChange } from '../../my-profile/models/expired-password-change.model';
import { SecurityImageChange } from '../../my-profile/models/security-image-change.model';
import { UserInfoChange } from '../../my-profile/models/user-info-change.model';
import { SecurityQuestion } from '../../shared/models/security-question.model';
import { User } from '../../shared/models/user.model';
import { AuthService } from './authentication/auth.service';
import { AuthenticatedHttp } from './authentication/authenticated-http';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private authService: AuthService, private http: AuthenticatedHttp) { }

    getSecurityQuestion(userDetails: any): Observable<string> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/GetSecurityQuestion';

        return this.http.post(apiUrl, userDetails, { headers: this.headers, observe: 'response' })
            .pipe(
                map(res => res.body)
            );
    }

    getDealerSecurityChallenge(credentials: Credentials): Observable<SecurityChallenge> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/GetDealerSecurityChallenge';

        return this.http.post<any>(apiUrl, credentials)
            .pipe(
                map((res) => new SecurityChallenge(res))
            );
    }


    updateDealerSecurityChallenge(securityChallengeUpdates: SecurityChallengeUpdates): Observable<User> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateDealerSecurityChallenge';

        return this.http.post<User>(apiUrl, securityChallengeUpdates)
            .pipe(
                map((res) => {
                    const user = res ? new User(res) : null;

                    if (user) {
                        this.authService.saveUser(user);
                    }

                    return user;
                })
            );
    }

    addSecurityQuestions(userDetails: any, securityQuestions: Array<SecurityQuestion>): Observable<User> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/AddSecurityQuestions';
        const payload = {
            email: userDetails.email,
            password: userDetails.password,
            securityQuestions
        };

        return this.http.post<User>(apiUrl, payload)
            .pipe(
                map(res => new User(res))
            );
    }

    updateSecurityQuestions(requestDetails: any): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateSecurityQuestions';

        return this.http.post(apiUrl, requestDetails, { responseType: 'text' });
    }

    updateUserSettings(settings: { trusteerOptOut: string }): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateUserSettings';

        return this.http.post(apiUrl, settings, { responseType: 'text' });
    }

    updateEula(acceptedEula: boolean): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateEula';

        return this.http.post(apiUrl, { accepted: acceptedEula }, { responseType: 'text' }, acceptedEula);
    }

    changePassword(passwordChange: PasswordChange): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/ChangePassword';

        return this.http.post(apiUrl, passwordChange, { responseType: 'text' });
    }

    changeExpiredPassword(passwordChange: ExpiredPasswordChange): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/ChangeExpiredPassword';

        return this.http.post(apiUrl, passwordChange, { responseType: 'text' });
    }

    updateUser(userInfoChange: UserInfoChange): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateUser';

        return this.http.post(apiUrl, userInfoChange, { responseType: 'text' });
    }

    updateUserEmail(emailChange: EmailChange): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateUserEmail';

        return this.http.post(apiUrl, emailChange, { responseType: 'text' });
    }

    updateSecurityImageId(securityImageChange: SecurityImageChange): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/UpdateSecurityImageId';
        return this.http.post(apiUrl, securityImageChange, { responseType: 'text' });
    }

    getSecurityImageId(): Observable<number> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/GetSecurityImageId';
        return this.http.post(apiUrl);
    }
}
