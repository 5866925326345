import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    scrollTo(selector: string): void {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView();
            window.scrollBy(0, -120);
        }
    }

    isNullOrEmpty(obj): boolean {
        if (obj == null) {
            return true;
        }

        for (const x in obj) {
            if (obj.hasOwnProperty(x)) {
                return false;
            }
        }
        return true;
    }

    getVendorReferenceIdFormatForReports(vendorReferenceId: string): string {
        return vendorReferenceId ? '*' + vendorReferenceId.replace(',', ';') : '';
    }

    orderBy(array: Array<any>, sortOrderAscending: boolean, orderByValue?: string, thenByValue?: string): Array<any> {
        const copy = array ? array.slice() : [];
        if (copy) {
            const sortOrderValue = sortOrderAscending ? 1 : -1;
            const useSelf = !orderByValue;
            const orderByValueArray = (orderByValue) ? orderByValue.split('.') : null;
            const thenByValueArray = (thenByValue) ? thenByValue.split('.') : null;

            copy.sort((element1: any, element2: any) => {
                const value1 = this.getOrderByElement(element1, orderByValueArray, thenByValueArray, useSelf);
                const value2 = this.getOrderByElement(element2, orderByValueArray, thenByValueArray, useSelf);

                if (value1 < value2) {
                    return -1 * sortOrderValue;
                } else if (value1 > value2) {
                    return 1 * sortOrderValue;
                } else {
                    return 0;
                }
            });
        }

        return copy;
    }

    getProperty(object: any, properties: Array<string>): any {
        let obj = object;
        if (obj === undefined || obj === null) {
            return undefined;
        }

        for (let i = 0; i < properties.length; i++) {
            obj = obj[properties[i]];

            if (obj === undefined || obj === null) {
                return undefined;
            }
        }

        return obj;
    }

    private getOrderByElement(element: any, orderByValueArray: Array<string>, thenByValueArray: Array<string>, useSelf: boolean): string {
        if (useSelf) {
            return element ? element.toString().toLowerCase() : '';
        }

        let elementOrderBy = this.getProperty(element, orderByValueArray);
        let elementThenOrderBy = (thenByValueArray) ? this.getProperty(element, thenByValueArray) : '';

        elementOrderBy = elementOrderBy ? elementOrderBy.toString().toLowerCase().trim() : '';
        elementThenOrderBy = elementThenOrderBy ? elementThenOrderBy.toString().toLowerCase().trim() : '';

        return elementOrderBy + elementThenOrderBy;
    }
}
