import { FormGroup } from '@angular/forms';

import { hasValue } from './has-value.validator';

export function ifOneRequireAll(controlNames: Array<string>): (formGroup: FormGroup) => { [key: string]: any } {
    return (formGroup: FormGroup): { [key: string]: any } => {
        const allFields = controlNames
            .map(property => {
                const formControl = formGroup.get(property);
                return formControl
                    ? { property, formControl }
                    : null;
            })
            .filter(formControl => formControl);
        const populatedFields = allFields.filter(field => hasValue(field.formControl.value));
        const atLeastOneFieldIsPopulated = populatedFields.length > 0;
        const allFieldsArePopulated = populatedFields.length === allFields.length;
        const isValid = (!atLeastOneFieldIsPopulated || (atLeastOneFieldIsPopulated && allFieldsArePopulated));
        const missingFields = allFields.filter(field => populatedFields.indexOf(field) === -1).map(field => field.property);

        return (isValid) ? null : { missingFields };
    };
}
