import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CONFIG } from '../../../../environments/environment';
import { IdleEvent } from './idle-event.model';
import { Interval } from './interval.model';
import { Timer } from './timer.model';

@Injectable({
    providedIn: 'root'
})
export class IdleService {
    safeToNavigate: boolean;
    // Observable any source
    private eventSource = new Subject<any>();
    // Observable any stream
    event$ = this.eventSource.asObservable();

    private timer: Timer = new Timer();
    private interval: Interval = new Interval();

    start(secondsRemaining: number) {
        this.safeToNavigate = false;
        this.stop();

        this.timer = new Timer();
        this.interval = new Interval();

        this.timer.setOnComplete(() => {
            this.eventSource.next(new IdleEvent('warning'));
            this.interval.countdown(CONFIG.idle.countdown);
        });

        this.interval.setOnTick((timeRemaining) => {
            this.eventSource.next(new IdleEvent('tick', timeRemaining));
        });

        this.interval.setOnComplete(() => {
            this.safeToNavigate = true;
            this.eventSource.next(new IdleEvent('logout'));
        });

        this.timer.start(secondsRemaining - CONFIG.idle.countdown);
    }

    stop() {
        this.interval.stop();
        this.timer.stop();
    }
}
