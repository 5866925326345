import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dol-radio-complete',
    templateUrl: './radio-complete.component.html',
    styleUrls: ['./radio-complete.component.css'],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RadioCompleteComponent }]
})
export class RadioCompleteComponent implements ControlValueAccessor {
    @Input() options: string[];
    _onChange: (value: any) => void;
    value: string;

    updateValue(newValue) {
        if (newValue !== this.value) {
            this.value = newValue;
            if (this._onChange) {
                this._onChange(this.value);
            }
        }
    }

    shouldCheck(type: string, option?: string): boolean {
        if (type === 'radio') {
            return this.value && this.value.toLowerCase() === option.toLowerCase();
        }
    }

    writeValue(value: any) {
        this.updateValue(value);
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }
}
