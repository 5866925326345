export class ProgramDocumentHeader {
    programDocumentId: number;
    name: string;
    isExternal: boolean;

    constructor(appData?: any) {
        if (appData) {
            this.programDocumentId = appData.ProgramDocumentId;
            this.name = appData.Name;
            this.isExternal = appData.IsExternal;
        }
    }
}
