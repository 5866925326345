<div class="header-group">
    <h1>
        <i *ngIf="nav" class="fa fa-arrow-left" (click)="goBack()" title="Go back"></i>
        <ng-content></ng-content>
    </h1>
    <div *ngIf="loading" class="loading-dots">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>