import { Component, Input } from '@angular/core';

@Component({
  selector: 'dol-datatable-current-page-bar',
  templateUrl: './datatable-current-page-bar.component.html',
  styleUrls: ['./datatable-current-page-bar.component.css']
})
export class DatatableCurrentPageBarComponent {
  @Input() totalRecords: number;
  @Input() pageSize: number;
  @Input() skipAmount: number;
  @Input() displayToPrintOnly = true;

  getCurrentPage() {
    if (this.pageSize === 0) {
      return 1;
    }

    return Math.ceil(this.skipAmount / this.pageSize) + 1;
  }

  getTotalPageNumbers() {
    if (this.pageSize === 0) {
      return 1;
    }

    const totalPageNumber = Math.ceil(this.totalRecords / this.pageSize);
    if (totalPageNumber === null || totalPageNumber === NaN) {
      return 1;
    }

    return totalPageNumber;
  }
}
