import { Component } from '@angular/core';

@Component({
    selector: 'dol-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styles: [`
        .displayBox {
            background: #f3f3f3;
            margin-top: 1.5rem;
            border-radius: var(--borderRadius);
            padding: 1.5rem;
            overflow: auto;
            height: 30rem;
        }
    `]
})
export class TermsAndConditionsComponent { }
