import { Component, ViewEncapsulation } from '@angular/core';
import { CONFIG } from '../environments/environment';

// This component provides the header, footer, and other static elements to the webpage.  
// B2C content frames do not require this component.
@Component({
  selector: 'dol-base',
  templateUrl: './site.component.html',
  styleUrls: ['../app/app.component.css', './site.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteComponent {
  environment: string;

  constructor() {
    this.environment = CONFIG.env;
  }
}
