import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { DragDropModule } from 'primeng/dragdrop';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';

import { DatatableCurrentPageBarComponent } from './datatable-current-page-bar/datatable-current-page-bar.component';
import { DealerSelectComponent } from './dealer-select/dealer-select.component';
import { DocumentsComponent } from './documents/documents.component';
import { EmailGrowerComponent } from './email-grower/email-grower.component';
import { FilterComponent } from './filter/filter.component';
import { AddressInputComponent } from './form/address-input/address-input.component';
import { DecimalInputComponent } from './form/decimal-input/decimal-input.component';
import { ImagePickerComponent } from './form/image-picker/image-picker.component';
import { NumericInputComponent } from './form/numeric-input/numeric-input.component';
import { RadioCompleteComponent } from './form/radio-complete/radio-complete.component';
import { ReCaptchaComponent } from './form/recaptcha/recaptcha.component';
import { SecurityImagePickerComponent } from './form/security-image-picker/security-image-picker.component';
import { StateInputComponent } from './form/state-input/state-input.component';
import { TermsAndConditionsComponent } from './form/terms-and-conditions/terms-and-conditions.component';
import { YearInputComponent } from './form/year-input/year-input.component';
import { LoadingFeedbackComponent } from './loading-feedback/loading-feedback.component';
import { MessageCenterComponent } from './message-center/message-center.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SecurityImagePreviewComponent } from './security-image-preview/security-image-preview.component';
import { TransactionLimitComponent } from './transaction-limit/transaction-limit.component';
import { WelcomeBarComponent } from './welcome-bar/welcome-bar.component';

import { AutoFocusDirective } from './auto-focus.directive';

import { FlattenObjectPipe } from './pipes/flatten-object.pipe';
import { FormatAccountingPipe } from './pipes/format-accounting.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TargetPipe } from './pipes/target.pipe';
import { TextTransformPipe } from './pipes/text-transform.pipe';

import { ProgressService } from './services/progress.service';

const modules = [
    CommonModule,
    AccordionModule,
    TableModule,
    DragDropModule,
    FileUploadModule,
    FormsModule,
    InputMaskModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TextMaskModule,
    TooltipModule
];

const components = [
    AddressInputComponent,
    DatatableCurrentPageBarComponent,
    DealerSelectComponent,
    DecimalInputComponent,
    DocumentsComponent,
    EmailGrowerComponent,
    FilterComponent,
    ImagePickerComponent,
    LoadingFeedbackComponent,
    MessageCenterComponent,
    ModalDialogComponent,
    NumericInputComponent,
    RadioCompleteComponent,
    ReCaptchaComponent,
    SectionHeaderComponent,
    SecurityImagePickerComponent,
    SecurityImagePreviewComponent,
    StateInputComponent,
    TermsAndConditionsComponent,
    TransactionLimitComponent,
    WelcomeBarComponent,
    YearInputComponent
];

const directives = [
    AutoFocusDirective
];

const pipes = [
    FlattenObjectPipe,
    FormatAccountingPipe,
    PhoneNumberPipe,
    SafeHtmlPipe,
    TargetPipe,
    TextTransformPipe
];

@NgModule({
    imports: [modules],
    declarations: [components, directives, pipes],
    exports: [modules, components, directives, pipes],
    providers: [ProgressService]
})
export class SharedModule { }
