import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
    constructor(private permissionService: PermissionService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const allowed = this.permissionService.hasPermission(route.data['permission']);

        if (!allowed && route.data['redirectTo']) {
            this.router.navigate([route.data['redirectTo']]);
        }

        return allowed;
    }
}
