import { Pipe, PipeTransform } from '@angular/core';

import { CREDIT_TYPES } from '../credit-types/credit-types';

@Pipe({ name: 'formatAccounting' })
export class FormatAccountingPipe implements PipeTransform {
    CREDIT_TYPES: string[] = CREDIT_TYPES;

    transform(amount: string, type?: string) {
        if (amount) {
            if (amount[0] === '-') {
                return `(${amount.substr(1)})`;
            } else if (CREDIT_TYPES.indexOf(type) !== -1) {
                return `(${amount})`;
            } else {
                return amount;
            }
        } else {
            return amount;
        }
    }
}
