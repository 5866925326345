import { SecurityImage } from '../models/security-image.model';

export const SecurityImageBasePath = '/assets/images/';
export const SecurityImages: Array<SecurityImage> = [
    {
        id: 1,
        uri: 'image-1.jpg'
    },
    {
        id: 2,
        uri: 'image-2.jpg'
    },
    {
        id: 3,
        uri: 'image-3.jpg'
    },
    {
        id: 4,
        uri: 'image-4.jpg'
    },
    {
        id: 5,
        uri: 'image-5.jpg'
    },
    {
        id: 6,
        uri: 'image-6.jpg'
    },
    {
        id: 7,
        uri: 'image-7.jpg'
    },
    {
        id: 8,
        uri: 'image-8.jpg'
    },
    {
        id: 9,
        uri: 'image-9.jpg'
    },
    {
        id: 10,
        uri: 'image-10.jpg'
    },
    {
        id: 11,
        uri: 'image-11.jpg'
    },
    {
        id: 12,
        uri: 'image-12.jpg'
    },
    {
        id: 13,
        uri: 'image-13.jpg'
    },
    {
        id: 14,
        uri: 'image-14.jpg'
    },
    {
        id: 15,
        uri: 'image-15.jpg'
    },
    {
        id: 16,
        uri: 'image-16.jpg'
    },
    {
        id: 17,
        uri: 'image-17.jpg'
    },
    {
        id: 18,
        uri: 'image-18.jpg'
    },
    {
        id: 19,
        uri: 'image-19.jpg'
    },
    {
        id: 20,
        uri: 'image-20.jpg'
    }
];
