<div class="stage container">
    <form class="form-wrapper raised-card" [formGroup]="form" (ngSubmit)="submit(form)">
        <h2>Reset Password</h2>
        <p *ngIf="invalidFormMessage" class="error">{{invalidFormMessage}}</p>
        <div formGroupName="passwords" class="form-section">
            <div>
                <mat-form-field>
                    <input matInput type="password" placeholder="New Password" formControlName="newPassword" autofocus
                        autocomplete="off" [readonly]="isInvalidUserOrTokenInfo">
                </mat-form-field>
            </div>
            <ul *ngIf="submitted && form.get('passwords').get('newPassword').errors" class="error">
                <li *ngIf="form.get('passwords').get('newPassword').hasError('required')">A new password is required.
                </li>
                <li *ngIf="form.get('passwords').get('newPassword').hasError('passwordValidation')">
                    {{passwordValidationMessage(form.get('passwords').get('newPassword').errors['passwordValidation'])}}
                </li>
            </ul>
            <div>
                <mat-form-field>
                    <input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword"
                        autocomplete="off" [readonly]="isInvalidUserOrTokenInfo">
                </mat-form-field>
            </div>
            <ul *ngIf="submitted && form.get('passwords').get('confirmPassword').errors" class="error">
                <li *ngIf="form.get('passwords').get('confirmPassword').hasError('required')">Please re-type your
                    password.</li>
            </ul>
            <ul *ngIf="submitted && form.get('passwords').errors" class="error">
                <li *ngIf="form.get('passwords').hasError('nomatch')">Your passwords do not match.</li>
            </ul>
        </div>
        <div *ngIf="securityQuestion" class="form-section">
            <label>Security Question</label>
            <p>{{securityQuestion}}</p>
            <mat-form-field>
                <input matInput placeholder="Answer" formControlName="answer">
            </mat-form-field>
            <ul *ngIf="submitted && form.get('answer').invalid" class="error">
                <li *ngIf="form.get('answer').hasError('required')">An answer is required.</li>
            </ul>
        </div>
        <div class="form-controls">
            <button mat-raised-button type="submit" class="btn-brand"
                [disabled]="isInvalidUserOrTokenInfo">Change</button>
        </div>
    </form>
</div>