import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { PasswordModule } from './password/password.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { FooterBarComponent } from './core/footer-bar/footer-bar.component';
import { HeaderBarComponent } from './core/header-bar/header-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IfAngularCoreModule } from 'if-angular-core';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        LoginModule,
        PasswordModule,
        SharedModule,        
        DialogModule,
        FontAwesomeModule,
        IfAngularCoreModule
    ],
    exports: [ // TODO:MFA
        FooterBarComponent,
    ],
    declarations: [
        AppComponent,
        FooterBarComponent,
        HeaderBarComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
