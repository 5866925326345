import { PendingMasterCommitment } from './pending-master-commitment.model';
import { MasterCommitment } from './master-commitment.model';

export class MasterCommitmentResult {
    pendingMasterCommitment: PendingMasterCommitment;
    masterCommitment: MasterCommitment;

    constructor(appData?: any) {
        if (appData) {
            this.pendingMasterCommitment = appData.PendingMasterCommitment
                ? new PendingMasterCommitment(appData.PendingMasterCommitment)
                : null;
            this.masterCommitment = appData.MasterCommitment ? new MasterCommitment(appData.MasterCommitment) : null;
        }
    }
}
