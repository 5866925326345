import { TransactionLimit } from './transaction-limit.model';

export class ParticipatingTag {
    label: string;
    participantType: string;
    transactionLimit: TransactionLimit;

    constructor(appData?: any) {
        if (appData) {
            this.label = appData.Label;
            this.participantType = appData.ParticipantType;
            this.transactionLimit = appData.TransactionLimit ? new TransactionLimit(appData.TransactionLimit) : null;
        }
    }
}
