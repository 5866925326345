import { Component, OnInit } from '@angular/core';

import { CONFIG } from '../../../environments/environment';

@Component({
  selector: 'dol-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  shouldHideFrame = true;
  b2cFrameSource: string = CONFIG.b2cPasswordResetFrame.src;
  b2cFrameHeight: string = CONFIG.b2cPasswordResetFrame.height;

  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}
