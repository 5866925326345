import { DurationType } from './duration-type.model';

export const DURATION_TYPES: Array<DurationType> = [{
    value: 'Day',
    readableValue: 'Day(s)'
}, {
    value: 'Week',
    readableValue: 'Week(s)'
}, {
    value: 'Month',
    readableValue: 'Month(s)'
}, {
    value: 'Year',
    readableValue: 'Year(s)'
}];
