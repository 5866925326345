import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { POSSIBLE_SECURITY_QUESTIONS, SECURITY_QUESTIONS_COUNT } from '../../shared/data/security-questions';
import { isNumeric } from '../../shared/utilities';
import { validateSecurityQuestions } from '../../shared/validators/security-question.validator';
import { SecurityChallengeUpdates } from '../models/security-challenge-updates.model';

import { AccountService } from '../../core/services/account.service';
import { AuthService } from '../../core/services/authentication/auth.service';
import { NotificationService } from 'if-angular-core';
import { StateService } from '../../core/services/state.service';
import { UtilityService } from '../../core/services/utility.service';
import { LoginService } from '../services/login.service';

@Component({
    selector: 'dol-update-challenge-form',
    templateUrl: './update-challenge-form.component.html',
    styleUrls: ['./update-challenge-form.component.css']
})
export class UpdateChallengeFormComponent implements OnInit {
    availableQuestions = POSSIBLE_SECURITY_QUESTIONS;
    loading: boolean;
    securityForm: FormGroup;
    submitted: boolean;

    constructor(
        private fb: FormBuilder,
        private accountService: AccountService,
        private notificationService: NotificationService,
        private util: UtilityService,
        private authService: AuthService,
        private stateService: StateService,
        private router: Router,
        private loginService: LoginService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        window.scrollTo(0, 0);

        const controlsConfig = {};

        if (!isNumeric(this.loginService.securityChallenge.securityImageId)) {
            controlsConfig['securityImageId'] = [null, Validators.required];
        }

        if (!this.loginService.securityChallenge.question) {
            controlsConfig['securityQuestions']
                = this.fb.array(this.initSecurityQuestions(SECURITY_QUESTIONS_COUNT), validateSecurityQuestions);
        }

        this.securityForm = this.fb.group(controlsConfig);
    }

    handleSubmit() {
        this.submitted = true;


        console.log('securityForm', this.securityForm);

        if (this.securityForm.valid) {
            const securityImageIdControl = this.securityForm.get('securityImageId');
            const securityImageId = securityImageIdControl ? securityImageIdControl.value : null;
            const securityQuestionsControl = this.securityForm.get('securityQuestions');
            const securityQuestions = securityQuestionsControl ? securityQuestionsControl.value : null;
            const securityChallengeUpdates: SecurityChallengeUpdates = new SecurityChallengeUpdates({
                email: this.loginService.credentials.email,
                password: this.loginService.credentials.password,
                securityImageId,
                securityQuestions
            });

            this.loading = true;
            this.securityForm.disable();

            this.accountService.updateDealerSecurityChallenge(securityChallengeUpdates)
                .pipe(
                    finalize(() => { this.loading = false; })
                )
                .subscribe(user => {
                    this.loginService.user = user;

                    if (!user.acceptedEula) {
                        this.router.navigate(['../eula'], { relativeTo: this.route, skipLocationChange: true });
                    } else {
                        this.loginService.getAssignedDealers();
                    }
                }, (err: HttpErrorResponse) => {
                    console.log(err);
                    this.notificationService.push(
                        {
                            severity: 'error',
                            summary: 'An error occurred',
                            detail: 'Your security challenge could not be updated.'
                        });
                    this.securityForm.enable();
                });
        } else {
            this.util.scrollTo('form .ng-invalid');
        }
    }

    private initSecurityQuestions(numberRequired: number): Array<FormGroup> {
        const securityQuestions = [];
        for (let i = 0; i < numberRequired; i++) {
            const securityGroup = this.fb.group({
                question: ['', Validators.required],
                answer: ['', Validators.required]
            });
            securityQuestions.push(securityGroup);
        }

        return securityQuestions;
    }
}
