export class CommitmentHeader {
    rowId: number;
    commitmentNumber: string;

    constructor(appData?: any) {
        if (appData) {
            this.rowId = appData.RowId;
            this.commitmentNumber = appData.CommitmentNumber;
        }
    }
}
