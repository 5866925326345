import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../environments/environment';
import { AuthenticatedHttp } from '../../core/services/authentication/authenticated-http';
import { AssignedDealers } from '../../shared/models/assigned-dealers.model';

@Injectable({
    providedIn: 'root'
})
export class AssignedDealersService {
    constructor(private http: AuthenticatedHttp) { }

    getMyAssignedDealers(): Observable<AssignedDealers> {
        const url = `${CONFIG.apiBaseUrl}dealer/assigned`;

        return this.http.get<AssignedDealers>(url)
            .pipe(
                map((res) => new AssignedDealers(res))
            );
    }

    getAssignedDealers(userId: string): Observable<AssignedDealers> {
        const url = `${CONFIG.apiBaseUrl}dealer/assigned/${userId}`;

        return this.http.get<AssignedDealers>(url)
            .pipe(
                map((res) => new AssignedDealers(res))
            );
    }
}
