import { CustomCustomerValue } from './custom-customer-value.model';
import { ICustomCustomerValueContainer } from './custom-customer-value-container.interface';

export class ColumnDefinition {
    field: string;
    header: string;
    serverSortProperty: string;
    class: string;
    sortable = false;
    format = 'default';

    constructor(props?: any) {
        if (props) {
            this.field = props.field;
            this.header = props.header;
            this.serverSortProperty = props.serverSortProperty;
            this.class = props.class;
            this.sortable = props.sortable;
            this.format = props.format;
        }
    }

    public static getColumnDefinitionsFromCustomValues(results: Array<ICustomCustomerValueContainer>): Array<ColumnDefinition> {
        const firstResult = (results && results[0]) ? results[0] : null;
        return firstResult ? this.createCustomColumnDefinitions(firstResult.customCustomerValues) : [];
    }

    public static createCustomColumnDefinitions(customCustomerValues: Array<CustomCustomerValue>): Array<ColumnDefinition> {
        if (customCustomerValues) {
            return customCustomerValues.map((cv, i) => {
                return new ColumnDefinition({
                    field: i.toString(),
                    header: cv.name,
                    format: 'customCustomerValue',
                    sortable: false
                });
            });
        } else {
            return [];
        }
    }
}
