import { AbstractControl } from '@angular/forms';

export function validateEmail(c: AbstractControl) {
    const REGEX = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/i;

    return !c.value || REGEX.test(c.value) ? null : {
        validateEmail: {
            valid: false
        }
    };
}

export function validateIsRaboEmail(originalEmail: string) {
    return function (control: AbstractControl) {
        const REGEX = /@rabo(ag|bank)\.com$/i;
        let isRaboFormat = false;

        if (originalEmail) {
            isRaboFormat = REGEX.test(originalEmail);
        }

        if (!isRaboFormat) {
            return null;
        }

        return !control.value || REGEX.test(control.value) ? null : {
            validateIsRaboEmail: {
                valid: false
            }
        };
    };
}
