import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';

import { ChallengeFormComponent } from './challenge-form/challenge-form.component';
import { DealerRegisterComponent } from './dealer-register/dealer-register.component';
import { ParticipatingProgramsComponent } from './dealer-register/participating-programs/participating-programs.component';
import { EulaFormComponent } from './eula-form/eula-form.component';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { PasswordExpiredComponent } from './login-form/password-expired.component';
import { UpdateChallengeFormComponent } from './update-challenge-form/update-challenge-form.component';

import { DealerRegisterService } from './dealer-register/services/dealer-register.service';
import { LoginService } from './services/login.service';

@NgModule({
    imports: [LoginRoutingModule, SharedModule],
    declarations: [
        ChallengeFormComponent,
        DealerRegisterComponent,
        EulaFormComponent,
        LoginComponent,
        LoginFormComponent,
        PasswordExpiredComponent,
        ParticipatingProgramsComponent,
        UpdateChallengeFormComponent
    ],
    providers: [DealerRegisterService, LoginService]
})
export class LoginModule { }
