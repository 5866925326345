import { NgModule } from "@angular/core";
import { MsalBroadcastService, MsalService, MSAL_INSTANCE } from "@azure/msal-angular";
import { Configuration, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { AuthService as AuthServiceV2 } from "../appv2/core/services/authentication/auth.service";

export function MSALInstanceFactory(): IPublicClientApplication {
    const msalConfig: Configuration = { auth: {clientId: ''}};
    return new PublicClientApplication(msalConfig);
  }
  
@NgModule({
    providers: [AuthServiceV2, 
        MsalService, 
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        MsalBroadcastService
    ]
  })
export class AppSharedModule { }
  