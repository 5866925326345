import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CONFIG } from '../../../environments/environment';
import { EmailChange } from '../../../app/my-profile/models/email-change.model';
import { PasswordChange } from '../../../app/my-profile/models/password-change.model';
import { ExpiredPasswordChange } from '../../../app/my-profile/models/expired-password-change.model';
import { UserInfoChange } from '../../../app/my-profile/models/user-info-change.model';
import { AuthenticatedHttp } from '../../../app/core/services/authentication/authenticated-http';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private resourceUrl = CONFIG.apiBaseUrl + 'Account';
    private resourceUrlV2 = CONFIG.apiBaseUrl + 'v2/' + 'Account';

    constructor(private http: AuthenticatedHttp) { }

    updateUserSettings(settings: { trusteerOptOut: string }): Observable<any> {
        const apiUrl = this.resourceUrl + '/UpdateUserSettings';

        return this.http.post(apiUrl, settings, { responseType: 'text' });
    }

    updateEula(acceptedEula: boolean): Observable<any> {
        const apiUrl = this.resourceUrl + '/UpdateEula';

        return this.http.post(apiUrl, { accepted: acceptedEula }, { responseType: 'text' }, acceptedEula);
    }

    changePassword(passwordChange: PasswordChange): Observable<any> {
        const apiUrl = this.resourceUrl + '/ChangePassword';

        return this.http.post(apiUrl, passwordChange, { responseType: 'text' });
    }

    changeExpiredPassword(passwordChange: ExpiredPasswordChange): Observable<any> {
        const apiUrl = this.resourceUrl + '/ChangeExpiredPassword';

        return this.http.post(apiUrl, passwordChange, { responseType: 'text' });
    }

    updateUser(userInfoChange: UserInfoChange): Observable<any> {
        const apiUrl = this.resourceUrlV2 + '/UpdateUser';

        return this.http.post(apiUrl, userInfoChange, { responseType: 'text' });
    }
}
