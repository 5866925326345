import { Component, AfterContentInit } from '@angular/core';

import { StateService } from '../core/services/state.service';
import { runScript } from '../shared/utilities';

@Component({
    selector: 'dol-login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements AfterContentInit {
    constructor(private stateService: StateService) { }

    ngAfterContentInit() {
        if (!this.stateService.trusteerScriptsInitialized) {
            this.stateService.trusteerScriptsInitialized = true;
            runScript('/assets/security/snip1.js');
            runScript('/assets/security/snip2.js');
        }
    }
}
