import { NgModule } from '@angular/core';

import { SharedModule } from '../../app/shared/shared.module';
import { SharedModule as SharedModuleV2} from '../shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';

import { LoginFormComponent } from './login-form/login-form.component'; // TODO:MFA
import { B2CLoginComponent } from './login-form/b2c-login.component'; // TODO:MFA
import { EulaFormComponent } from './eula-form/eula-form.component';

import { DealerRegisterService } from '../../app/login//dealer-register/services/dealer-register.service';
import { LoginService } from '../../app/login/services/login.service';

@NgModule({
    imports: [LoginRoutingModule, SharedModule, SharedModuleV2],
    declarations: [
        LoginFormComponent,
        B2CLoginComponent,
        EulaFormComponent
    ],
    providers: [DealerRegisterService, LoginService]
})
export class LoginModule { }
