<div class="displayBox">
    <p class=MsoNormal><span lang=EN-GB>USER AGREEMENT</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>Online Services Agreement</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>This Agreement (this &quot;Agreement&quot;)
            sets forth the rights and obligations of Rabo AgriFinance LLC (&quot;RAF&quot;)
            and User regarding User’s use of this website for certain Eligible Loan
            Application(s), whether located at the current </span><span lang=EN-GB>location
            of https://grower.raboag.com</span><span lang=EN-GB> or any future website
            address (the &quot;Website&quot;).  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>User and each Authorized Representative
            acknowledges that he, she or it has read this Agreement in its entirety.  User
            agrees to the terms of this Agreement when User or any Authorized
            Representative of User clicks on the &quot;AGREE&quot; tab below, and User
            reaffirms the effectiveness of this Agreement when any such Authorized
            Representative uses the Service.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>User shall be responsible for all of the
            duties and obligations of each of its Authorized Representatives under this
            Agreement and shall be liable for all damages caused by the failure of any
            Authorized Representative to meet or fulfill any such duty or obligation and
            any acts or omissions of any Authorized Representative.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>Certain capitalized terms used in this
            Agreement are defined in Section 13 of this Agreement.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>1.  Agreements Governing User’s
            Relationships with RAF.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  This Agreement.  This Agreement governs
            the terms, conditions and procedures for access to the Website and use of the
            Service.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Other Agreements.  In addition to this
            Agreement, each Eligible Loan Application and Online Financial Service may be
            subject to a separate agreement or agreements (each, an &quot;Other
            Agreement&quot;), such as a loan agreement, promissory note, security
            agreement, mortgage, deed of trust, loan participation agreement and /or  loan
            servicing agreement.  Each of User’s Eligible Loan Applications and Online
            Financial Services and disputes related to the applicable accounts and
            services, will continue to be subject to any such Other Agreement that applies
            to them.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Conflicts Between Agreements.  If this
            Agreement conflicts with an Other Agreement that is applicable to User’s
            Eligible Loan Applications or Online Financial Services, or such Other
            Agreement has terms that are not addressed in this Agreement, the Other
            Agreement will control and take precedence, unless this Agreement specifically
            states otherwise.  This Agreement will control and take precedence in resolving
            any inconsistencies between this Agreement and the terms of any Other Agreement
            purporting to address the terms, conditions and procedures for access to the
            Website and use of the Service, including RAF’s right to change, suspend or terminate
            all or part of the Service, this Agreement or access to the Website.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>2.  Use of the Service.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Access to the Website and Use of the
            Service.  Subject to the terms and conditions of this Agreement, User and each
            Authorized Representative may use the Service only to submit and access
            Eligible Loan Applications and obtain Online Financial Services.  All other use
            of the Service or the Website is prohibited.  Access to the Service, in whole
            or in part, may not occur immediately upon registration or entry into this
            Agreement and may be delayed to allow RAF to set up, process and approve User’s
            request through any Authorized Representative to access the Service.  The
            available Online Financial Services are described on the Website and may change
            from time to time, at RAF’s sole option.  With respect to Eligible Loan
            Applications, available services may include, for example, obtaining balance
            information, transaction history and other information.  In some instances,
            balances and transaction history may only reflect activity conducted through
            the close of a previous Business Day.  RAF may, from time to time, introduce
            new features to the Service or eliminate features from the Service.  When this
            happens, RAF will update the Website accordingly.  User and each Authorized
            Representative are responsible for acquiring and maintaining a computer or
            other electronic device (and any ancillary equipment) that can handle and
            access the Website and the Service, and are responsible for all costs
            associated with accessing the Service, the Website, Eligible Loan Applications
            and Online Financial Services.  User enters into this Agreement and will use
            the Service, Online Financial Services, and Eligible Loan Applications for
            business purposes only and not for personal, family or household purposes.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Authorized Representatives.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>(i)  Authorized Representatives.  User may
            designate one or more Authorized Representatives.  User agrees to cause each of
            its Authorized Representatives to comply with all requirements, duties and
            obligations set forth in this Agreement as applicable to User and/or Authorized
            Representatives.  User hereby represents, warrants and covenants that each
            Authorized Representative individually has the right to provide RAF with
            instructions, make any decision, obtain any information or make any request
            associated with any Eligible Loan Application or Online Financial Service, and
            to otherwise carry out all of the duties and obligations under this Agreement,
            on behalf of User.  RAF may rely and act on the instructions of any Authorized
            Representative without RAF having liability to User.  All actions that an
            Authorized Representative performs regarding an Eligible Loan Application or
            Online Financial Service, including those User did not want or intend, are
            actions authorized by User and will be binding upon User.  User is solely
            responsible for those actions, and RAF is not responsible for them.  User
            agrees that any act by any Authorized Representative when using the Service is
            an act of User, regardless of any claim that the Authorized Representative was
            acting outside the scope of his or her authority.  Without limiting the
            generality of the foregoing, User represents, covenants, warrants and
            acknowledges that any Authorized Representative is authorized to:  (a) enroll
            User in the Service, (b) enter into this Agreement, as amended from time to
            time, on behalf of User; (c) submit and access each Eligible Loan Application
            in any manner and for any purpose available through the Website or the Service,
            whether now available or available at some time in the future, provided that
            such manner or purpose are consistent with the terms of this Agreement; (d) use
            any Online Financial Service in any manner and for any purpose available
            through the Website or the Service, whether now available or available at some
            time in the future; and (e) carry out all of the duties and obligations of User
            and an Authorized Representative pursuant to this Agreement.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>(ii)  Termination of Authorization for
            Authorized Representatives.  Authorization of an Authorized Representative
            designated by User can be terminated only by submission to RAF by User of the
            notice form authorized by RAF for such termination.  If RAF is notified as
            provided in this section that the authorization of an Authorized Representative
            has been terminated, only an action (a) that User did not intend to occur, (b)
            that the Authorized Representative performs after the notification, and (c)
            that occurs after RAF has a reasonable opportunity to act on the notice, will
            be considered an unauthorized action.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>3.  Access to Eligible Loan Applications
            and Online Financial Services.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>User authorizes RAF to provide access to
            User’s Eligible Loan Applications and Online Financial Services through the
            Service.  Each Authorized Representative may access such Eligible Loan
            Applications and Online Financial Services through the Service to obtain
            information and perform actions on User’s behalf as authorized through the
            Service, on the Website and in this Agreement.  To access Eligible Loan
            Applications and Online Financial Services through the Service, each of User’s
            Authorized Representatives must have a username, a password and the required
            hardware and software as described on the Website.  See Section 6 herein for
            additional security information and requirements.  User and its Authorized
            Representatives must also comply with any other security procedures and
            policies RAF may establish from time to time.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>4.  Additional Provisions for the Service,
            Eligible Loan Applications and Online Financial Services. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Weblinks Through Service.  The Service
            may offer links to internet websites controlled by third parties (a &quot;Third
            Party Site&quot;). Third Party Sites may offer services related to Eligible
            Loan Applications or Online Financial Services.  If an Authorized
            Representative chooses to click on the link to any Third Party Site, the
            Authorized Representative will no longer be on RAF’s Website, and the level of
            security, terms of use and privacy policy applicable to RAF’s Website will not
            apply to the Third Party Site or any activity on the Third Party Site. 
            Instead, when an Authorized Representative accesses a Third Party Site all
            activity on the Third Party Site will be subject to the terms of use, security
            levels and privacy policy provided in connection with that Third Party Site and
            the contractual terms applicable to any procurement of products or services
            from the third party controlling that Third Party Site, if any.  RAF is not
            responsible in any way for any products or services that User or any Authorized
            Representative obtains or agrees to through the Third Party Site or the content
            of that Third Party Site, including any software User or any Authorized Representative
            may download from any Third Party Site and any viruses or other negative impact
            that the downloading of such software may cause.  User and Authorized
            Representatives should carefully review all terms and conditions applicable to
            use of any Third Party Site and to procurement of products or services through
            a Third Party Site.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Unavailability of Online Account
            Access.  The Website and the Service are offered only on an &quot;as
            available&quot; basis.  Access to Eligible Loan Applications and/or Online
            Financial Services is not guaranteed to be available and may not be available
            at any given time.  If User requires services of RAF during times when the
            Website or Service are unavailable, User should contact RAF’s National Service
            Center at 314-317-8000 between the hours of 7:30 am and 4:30 pm, Central Time
            on Business Days.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Consent to Electronic Communications. 
            User agrees to enter into this Agreement and any other agreement related to the
            Website, the Service, the Eligible Loan Applications and any Online Financial
            Services electronically and that any and all disclosures and communications
            from RAF regarding the Website, the Service, Eligible Loan Applications, any
            Online Financial Services or this Agreement may be made electronically in
            accordance with the notice provisions of this Agreement.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>D.  Other General Terms.  User and all
            Authorized Representatives shall comply with (i) all applicable state and
            federal laws and regulations, (ii) the requirements of the rules and
            regulations of any funds transfer system or payment system that is used in
            connection with an Online Financial Service or Eligible Loan Application, and
            (iii) RAF’s policies, procedures, terms and instructions, as any of them may be
            amended from time to time in the sole discretion of RAF, that apply to the
            Website, the Service, any Eligible Loan Application, or any Online Financial
            Service.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>5.  Using E-mail to Send Inquiries and
            Comments to RAF.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>The Service provides an e-mail address to
            which Authorized Representatives can transmit questions for RAF about Eligible
            Loan Applications or Online Financial Services or provide RAF with comments on
            the Service.  Because such e-mail transmissions may not be secure or
            confidential, neither User nor any Authorized Representative shall use this
            e-mail address to transmit any confidential information, including account
            information or personal information.  User and Authorized Representatives
            cannot use e-mail communications to RAF through this Service e-mail address to
            initiate transactions related to Eligible Loan Applications.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>6.  Security for Eligible Loan Applications
            and Online Financial Services. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>Each Authorized Representative must use a
            username and password unique to that Authorized Representative, and comply with
            such other security procedures as RAF may require from time to time, to access
            the Website and use the Service.  Control and dissemination of usernames and
            passwords and compliance by Authorized Representatives with other security
            procedures related to the Service shall be under exclusive control of User and
            the Authorized Representatives and shall be their responsibility.  RAF is not
            responsible for the activities of anyone using a username and/or password of an
            Authorized Representative and is entitled to rely and act upon instructions
            received under any such username and password and to rely on the genuineness of
            any such instructions.  User is responsible for any actions that may be taken
            by any such person using the Service after signing in with a username and password
            of any Authorized Representative.  User assumes the entire risk for any
            fraudulent, unauthorized or otherwise improper use of the Service by anyone
            using a username and/or password of one of the Authorized Representatives.  In
            the event of unauthorized use of an Authorized Representative username or
            password, User will be liable for resulting losses, to the extent permitted by
            law.  Each Authorized Representative shall: (i) keep the username and password
            of that Authorized Representative confidential; (ii) implement proper controls
            and systems safeguards (including but not limited to keeping anti-virus
            signatures current and running and anti-malware detection current and running)
            to facilitate confidentiality of the username and password of that Authorized
            Representative; (iii) sign off from the Service when a session is complete to
            prevent unauthorized persons from using the Service; and (iv) not allow any
            other person to use his or her username or password, and not transfer or
            disclose any of this information to any other person.  User is responsible for
            all fees associated with use of the Service by anyone using an Authorized
            Representative’s username and/or password, whether or not the action resulting
            in the fee was authorized.  User is responsible for regularly reviewing
            statements and other information regarding Eligible Loan Applications and for
            maintaining appropriate controls, including segregation of duties and functions
            of Authorized Representatives, officers, employees and other representatives,
            to identify any improper or unauthorized use of the Service.  RAF recommends
            that each Authorized Representative change his or her password regularly, and
            RAF may require such changes at certain intervals or in certain situations. 
            RAF may also require additional security procedures to access Eligible Loan
            Applications or Online Financial Services.  These additional security
            procedures may require special hardware, software or third-party services.  RAF
            may, at its option, offer additional, optional security procedures, such as
            random number generators, to enhance the security of Eligible Loan Applications
            and Online Financial Services.  RAF may require the use or activation of
            specific Internet browser software features, plug-ins and add-ons, such as JavaScript
            support and &quot;cookies&quot;, in order to utilize the Service.  RAF may also
            acquire detailed information concerning the computer or computers that each
            Authorized Representative uses to access the Service, including unique internal
            and network identifiers for these computer(s), in order to enhance and
            facilitate secure access to the Service.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>User hereby agrees that the security
            procedures applicable to an Eligible Loan Application or Online Financial
            Service under this Agreement are commercially reasonable methods of providing
            security against unauthorized activity, whether such security procedures
            involve only the username and password security procedures or involve any
            additional security procedures that may be required or offered in the
            discretion of RAF.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Creation of Username and Password.  The
            username and password of each Authorized Representative (i) must have the
            minimum number of characters RAF may specify from time to time; (ii) may be
            required by RAF to be a combination, as designated by RAF, of numbers, letters
            and/or special characters (for example, @, %, &amp;, #); (iii) may be required
            by RAF to be case-sensitive and/or to include a combination, as required by
            RAF, of lower-case and upper-case characters; and (iv) can be changed within
            the Service.  For security purposes, each Authorized Representative shall
            memorize his or her username and password and not write it down.  User and each
            Authorized Representative is responsible for ensuring that such Authorized
            Representative keeps his or her username and password confidential.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Protection of Security.  If User or any
            Authorized Representative suspects that any Authorized Representative username
            or password has been subject to any improper or unauthorized use or has been
            lost or stolen, or that someone has viewed, downloaded, or deleted electronic
            records from an Eligible Loan Application or Online Financial Service without
            proper permission, or suspects any fraudulent or inappropriate activity on an
            Eligible Loan Application, User or such Authorized Representative shall immediately
            notify RAF by calling RAF’s National Service Center at 314-317-8000 between the
            hours of 7:30 am and 4:30 pm Central Time, on Business Days or by writing to
            RAF at 12443 Olive, Suite 50, St. Louis, MO 63141. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C. Security Procedure Defined.  The term
            &quot;security procedure&quot; as used in this Agreement includes the use of a
            username and password and any other security procedure that may be required or
            offered by RAF for any Eligible Loan Application or Online Financial Service,
            and all related requirements, terms and conditions.  All security procedures
            other than the use of usernames and passwords are optional in the sole
            discretion of RAF, and RAF is under no obligation to require or offer any such
            optional security procedure with regard to any Eligible Loan Application or
            Online Financial Service.  User and each Authorized Representative hereby
            agrees to each security procedure in effect with regard to any Eligible Loan
            Application or Online Financial Service, including security procedures that RAF
            may in its discretion require or offer with regard to such Eligible Loan
            Application or Online Financial Service.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>7.  Privacy.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Privacy Policy.  Certain information
            gathered in connection with using the Website and the Service will be governed
            by the provisions of the RAF Privacy Policy.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Security of Applicant Information. 
            User and each of its Authorized Representatives agree that they will only
            access information through the Service with regard to Eligible Loan
            Applications of User.  All information provided by RAF to User or User’s
            Authorized Representatives, or that User or User’s Authorized Representatives
            view, print or download while accessing the Service, is confidential and may
            not be copied, posted, transmitted, disclosed or used by User or such
            Authorized Representatives in any manner except as authorized by the legal
            documents related to the specific Eligible Loan Applications from which such
            information is derived.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>User and User’s Authorized Representatives
            represent that they have implemented and will maintain appropriate security
            measures to safeguard the confidentiality of Applicant information including
            the nonpublic personal information of the Applicants.  Specifically, User and
            User’s Authorized Representatives have implemented measures to: (i) ensure the
            security and confidentiality of Applicant information; (ii) prevent the
            unauthorized disclosure, misuse, alternation or destruction of Applicant
            information; (iii) protect against any anticipated threats or hazards to the
            security or integrity of Applicant information; and (iv) protect against
            unauthorized access to or use of Applicant information that could result in
            substantial harm or inconvenience to any Applicant.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>8.  Additional Responsibilities.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Duty to Provide Accurate Information. 
            User and each Authorized Representative shall provide accurate, current and
            complete information about User and such Authorized Representative,
            respectively, and any other Authorized Representative when registering for,
            accessing and utilizing the Service.  User and each Authorized Representative
            shall not misrepresent the identity of User or such Authorized
            Representatives.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Proper Use of Service.  User and each
            Authorized Representative shall: (i) not use the Service or the Website for any
            illegal or unauthorized purposes; (ii) comply with all regulations, policies
            and procedures of networks through which they access and use the Service or the
            Website; (iii) not use the Service or the Website for spoofing or for any
            activity or use that may disrupt the Service or the Website or the networks
            through which they access or use the Website or the Service; (iv) not access or
            attempt to access any Service account for which User or such Authorized
            Representative, respectively, has no access authorization or for which access
            authorization has been revoked; (v) not duplicate, modify, distribute or
            display any of the data or files from any Service account for which User or such
            Authorized Representative has no access authorization or for which access
            authorization has been revoked; and (vi) not introduce or transmit through the
            Service or the Website or otherwise any virus, worm, spy ware or other
            destructive element.  RAF may use automated processes to detect any use of the
            Service or the Website that violates the terms of this Agreement or any
            applicable law.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Providing Means Necessary to Access
            Service.  User and Authorized Representatives are responsible for and must provide
            all internet connections, equipment, software (other than any software provided
            by RAF), hardware, and any other services necessary to access the Website and
            the Service. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>9.  Disclaimers; Liability Limitations.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Warranty Disclaimer.  To the fullest
            extent permitted by law, RAF hereby disclaims all warranties of any kind for
            the Service and the Website, either express or implied, including, but not
            limited to, implied warranties of merchantability, fitness for a particular
            purpose and noninfringement.  RAF does not warrant that the Service will be
            uninterrupted or error free, that defects will be corrected, or that the
            Website that makes the Service available is free of viruses or other harmful
            components.  RAF does not warrant achievement of any specific results through
            use of the Service.  The Service and the Website are offered on an &quot;as
            is&quot; and &quot;as available&quot; basis.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Damages Limitations.  In no event will
            RAF or any of its employees, officers, directors, shareholders, parent
            companies, subsidiaries, affiliates, agents, licensors, or third-party service
            providers be liable for any damages that are consequential, indirect,
            incidental, special, or punitive damages or any damages based on loss of data,
            files, profit or goodwill or on access by User or any Authorized Representative
            to or use of, or failure to obtain access to the Website or the Service, or on
            the costs of procurement of substitute goods or services, in an action under
            contract, negligence or any other theory, arising out of or in connection with
            this Agreement, the Website, the Service, or the inability to use the Service
            or the Website, even if advised of the possibility of such damages.  Some
            states may prohibit the limitation of liability for consequential or incidental
            damages.  In those states the limitation of liability with respect to
            consequential or incidental damages (or other damages specified by law in such
            state) may not apply.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Liability Limitations.  Except as
            required by applicable law, RAF (and its service providers or other agents)
            will not be liable for any loss or liability User or any Authorized
            Representative may incur resulting in whole or part from any failure or misuse
            of: (i) equipment of User or any Authorized Representative, or (ii) software
            provided by an external company such as an Internet browser provider, an
            Internet access provider, an online service provider or an agent or
            subcontractor of any of them.  In no event will RAF be liable for any failure
            of availability or performance due to any reason, including scheduled or
            unscheduled system maintenance or circumstances beyond RAF’s control (such as
            power outage, computer virus, system failure, fire, flood, earthquake, or
            extreme weather). </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>D.  Not for International Use.  RAF does
            not make any representation that any content or use of the Website or the
            Service is appropriate or available for use in locations outside of the United
            States.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>E.  Limits on RAF Duties.  RAF will not be
            obligated to honor, in whole or in part, any transaction or instruction which:
            (i) is not in accordance with any term or condition of this Agreement or any
            other agreement applicable to the relevant Online Financial Service or Eligible
            Loan Application; (ii) RAF has reason to believe (a) may not be properly
            authorized by any person whose authorization RAF believes is necessary or (b)
            involves funds or other property subject to a hold, dispute, restriction or
            legal process RAF believes prevents the transaction or instruction; (iii) would
            violate: (a) any applicable provision of any risk control program of the
            Federal Reserve, or (b) any applicable rule or regulation of any other federal
            or state regulatory authority; (iv) is not in accordance with any other
            requirement of RAF’s policies, procedures or practices; or (v) RAF has other
            reasonable cause not to honor for RAF’s or User’s protection.  RAF is also not
            obligated to review or monitor the activities of Authorized Representatives. 
            User agrees that RAF has no liability of any kind in connection with any action
            or omission, negligence or willful misconduct by any Authorized Representative.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>10.  Indemnification.  To the fullest
            extent permitted by law, User shall indemnify and hold harmless RAF, its
            directors, officers, employees and agents (collectively, the &quot;Indemnified
            Parties&quot;) from and against any and all losses, costs, expenses, fees,
            claims, damages, liabilities and causes of actions (including, but not limited
            to, reasonable attorney fees and disbursements) in any way resulting or arising
            from any one or more of the following: (i) any breach by User or any Authorized
            Representative of any representation, warranty or covenant under this
            Agreement; (ii) any failure by User or any Authorized Representative to abide
            by or perform any obligation imposed under this Agreement; (iii) any willful
            misconduct; fraud; criminal activity; intentional tort; negligence;
            infringement or violation of the rights of others; or misuse, misappropriation,
            unauthorized disclosure, alteration or destruction of information, data, files
            or other materials by User, any Authorized Representative or any other person
            or entity (other than RAF) involving the Service or the Website or under this
            Agreement; (iv) the issuance, distribution or use of any password, username or
            other security procedure, including the means by which RAF transmits such
            password, username or security procedure to User or Authorized Representatives;
            (v) any action, omission, commission, or use by User or any Authorized
            Representative of or in any way relating to the Service or the Website or under
            this Agreement, including any claim that use of any password, username or other
            security procedure provided for in this Agreement is not a commercially
            reasonable means of providing security against unauthorized actions involving
            any Eligible Loan Application; (vi) any transmission or instruction, whether or
            not authorized, when acted upon by RAF in good faith and in reliance upon any
            password and/or username or other security procedure; (vii) any delay in the
            execution of any instructions to authorize or terminate authorization of any
            Authorized Representative until the later of two (2) Business Days after RAF’s
            receipt of such instructions, or after RAF has had a commercially reasonable
            opportunity to act after receipt thereof; and (viii) any claims of a third
            party relating to any action taken or not taken by any Indemnified Party
            pursuant to this Agreement, unless such action or non-action constitutes gross
            negligence or willful misconduct by such Indemnified Party.  This
            indemnification is provided without regard to whether RAF’s claim for
            indemnification is due to the use of the Service or the Website by User, any
            Authorized Representative or any other person using a password, username or
            other security procedure of any Authorized Representative.  The obligations
            contained in this Section 10 will continue after the Agreement is terminated
            and after access to the Service is terminated.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>11.  Notices and Communication. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  General Rules for Communication. 
            Except as expressly provided otherwise in this Agreement, RAF will provide User
            and Authorized Representatives notices and other information regarding Eligible
            Loan Applications, Online Financial Services, or the Service (collectively
            &quot;Account Related Information&quot;) through mail, electronically or by
            other means available.  This information may be (but is not required to be)
            sent to the postal address or to the e-mail or other electronic address of any
            Authorized Representative (any such e-mail or other electronic address, the
            &quot;E-Address&quot;) as reflected in RAF’s records, unless a different
            address has been specified in accordance with procedures RAF may establish from
            time to time.  The E-Address shall be an e-mail address provided to RAF in
            accordance with terms established by RAF.  Account Related Information, notices
            and any other information transmitted to any E-Address or to any Authorized
            Representative’s other address as reflected in RAF’s records are deemed
            delivered and received by User and all Authorized Representatives at the time
            sent.  Account Related Information that is held for pick-up or posted on RAF’s
            Website will be deemed to be delivered and received at the earlier of the
            following: the time that RAF makes it available for pick-up, transmits it,
            displays it on the Website, or sends notice in accordance with this Agreement
            that it is posted on RAF’s Website, as applicable.  The person receiving the
            notice is responsible for providing copies of all Account Related Information
            to User and all Authorized Representatives.  RAF reserves the right at all
            times to communicate all Account Related Information through the U.S. Postal
            Service or overnight courier, at RAF’s sole option.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Electronic and Telephonic
            Communication.  Unless otherwise required by applicable law, if RAF is required
            to provide User or any Authorized Representative with information in writing,
            RAF may, at its option, send it by mail, or other manual means or
            electronically: (i) to any E-Address; (ii) by posting the information on RAF’s
            Website and sending a notice explaining that the information has been posted
            and providing instructions on how to view it to any E-Address or other
            Authorized Representative address reflected in RAF’s records; or (iii) by
            posting the information to RAF’s Website without other instruction or notice. 
            RAF will post a copy of RAF’s applicable Privacy Policy on the Website.  User
            and each Authorized Representative agree that RAF may from time to time make
            telephone calls to User or any Authorized Representative in order for RAF to
            service accounts, collect any amount owed, or discuss a business relationship,
            products and services.  The ways RAF may call User or any Authorized
            Representative include using prerecorded/artificial voice messages and/or
            through the use of an automatic dialing device.  RAF may call User or any
            Authorized Representative at any telephone number provided to RAF, including
            mobile/cellular telephone numbers that could result in charges to the owner of
            the telephone.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Returned Information.  Unless otherwise
            prohibited by applicable law, if two or more separate communications of Account
            Related Information are returned, RAF may discontinue sending Account Related
            Information communications through the delivery channel that resulted in the
            return of communications, until User or the applicable Authorized
            Representative provides RAF with a different valid postal address, E-Address or
            other electronic address.  Additionally, RAF may, at its sole option, and
            unless prohibited by applicable law: (i) destroy Account Related Information
            that is returned to RAF as undeliverable; (ii) hold such Account Related
            Information for the intended recipient; and/or (iii) utilize an alternative
            delivery channel to provide the information. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>D.  Changes in Address.  User and each
            Authorized Representative shall notify RAF immediately in the event of a change
            in address or electronic address, including any E-Address.  RAF will act on any
            such notice within a reasonable time after RAF receives the notice.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>E.  Effectiveness of Communications and
            Risk of Loss.  Any communication sent to RAF will not be effective until RAF
            actually receives it and has a reasonable opportunity to act on it.  If there
            is more than one Authorized Representative, RAF may send Account Related
            Information to any one of them, and it shall be deemed to be received by all of
            them and User upon being sent.  User assumes the risk of loss in the mail or
            otherwise in transit of any communication sent by any of them or by RAF.  Any
            notice or Account Related Information RAF sends will be effective when mailed,
            sent electronically or otherwise made available to the addressee.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>12.  General Terms.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  Amendments.  User agrees that from time
            to time RAF may amend this Agreement, including amendments to add further
            Online Financial Services.  RAF may initiate an amendment to this Agreement by
            notifying any Authorized Representative or by posting a notice on the Website. 
            User agrees to the terms of any such amendment to this Agreement when any
            Authorized Representative clicks on the &quot;AGREE&quot; tab provided with the
            amendment or amended Agreement or when any Authorized Representative uses the
            Service after notice of the amendment is provided as specified in this Section
            12.A.  No attempted amendment of this Agreement proposed by User or any
            Authorized Representative will be binding on RAF unless accepted in writing by
            RAF.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  Termination of Agreement.  Unless otherwise
            required by applicable law, this Agreement may be terminated at any time by
            either (i) RAF, or (ii) User; provided that for termination under Section
            12.B(ii), RAF must receive written notice of such termination at least two
            Business Days prior to the effectiveness of such termination.  Termination by
            RAF shall be effective immediately upon notice to User or any Authorized
            Representative.  The termination of this Agreement will not terminate
            obligations or rights arising under this Agreement before such termination.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  Termination, Suspension and
            Reinstatement of Access to Service.  RAF may, in its sole discretion at any
            time and without being limited by the procedure to terminate this Agreement,
            terminate, suspend and/or reinstate access to the Website and/or the Service,
            in whole or in part, by any Authorized Representative.  For example, although
            cause for termination of access is not required, if User violates any terms of
            this Agreement (including the failure to pay fees under any applicable fee
            schedule) or any other agreement with RAF, RAF may suspend or terminate access
            through the Service to any one or more of User’s Eligible Loan Applications for
            any or all Authorized Representatives on those Eligible Loan Applications. 
            Similarly, if any Authorized Representative engages in conduct that constitutes
            a violation of this Agreement, RAF may, in its sole discretion, suspend or
            terminate access to the Service for that Authorized Representative.  Normally,
            if User withdraws all of its pending Eligible Loan Applications and terminates
            all Online Financial Services, or withdraws from the Service, RAF will suspend
            or terminate access to the Service for Authorized Representatives of User.  RAF
            will not be required to reinstate or re-activate access after it has been
            terminated or suspended.  However, if access is reinstated, the terms of this
            Agreement effective at the time of reinstatement will control.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>D.  Survival of Terms.  The following
            provisions of this Agreement will survive the termination of this Agreement: 
            Sections 7, 9, 10, 12.D, 12.E, 12.F and 12.H. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>E.  Choice of Law and Venue.  This
            Agreement shall be governed by the laws of the State of Iowa, without regard to
            conflict-of-law rules.  This provision shall not affect the choice of law
            provisions in any other agreements related to User’s Eligible Loan Applications
            or Online Financial Services.  User irrevocably and unconditionally agrees,
            consents and hereby submits to the exclusive personal jurisdiction and venue of
            the state courts and federal courts in Blackhawk County, Iowa, with respect to
            all matters relating to this Agreement and access to or use of the Website or
            the Service.  User hereby irrevocably and unconditionally waives, to the
            fullest extent it may legally and effectively do so, (i) any objection which
            User may now or hereafter have to the laying of venue of any suit, action or
            proceeding arising out of or relating to this Agreement or access to or use of
            the Website or the Service in any court referred to in this Section 12.E, and
            (ii) the defense of an inconvenient forum to the maintenance of such action or
            proceeding in any such court.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>F.  Costs and Attorneys Fees.  In any legal
            action or claim regarding this Agreement, the prevailing party will be entitled
            to recover costs and reasonable attorney fees.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>G.  Assignment.  RAF may, without User’s
            consent, assign or transfer any or all of RAF’s interest in this Agreement to
            any party.  User may not assign or transfer any or all of User’s interest in
            this Agreement.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>H.  Proprietary Rights and Intellectual
            Property.  An Authorized Representative may make a single copy and/or print a
            single copy of any information displayed or transmitted on the Website or
            through the Service (collectively, the &quot;Content&quot;) that such
            Authorized Representative is authorized to access.  User acknowledges that RAF
            and its suppliers retain all right, title and interest in and to the original,
            and any copies, of the Content and any software, trademarks, service marks,
            audio clips, icons, images, logos, graphics, text and advertisements that may
            be available on the Website, and ownership of all patent, copyright, trademark,
            trade secret and other intellectual property rights pertaining thereto.  User
            shall not be an owner of any copies of, or any interest in the Content.  User
            and Authorized Representatives may not use RAF’s service marks, logo, or any of
            RAF’s copyrighted materials without RAF’s prior written consent.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>I.  Relationship between User and RAF.  It
            is expressly understood and agreed that nothing herein shall constitute, for
            any purpose whatsoever other than as specifically provided herein (a) RAF as
            the partner, agent, fiduciary, or legal representative of User or any
            Authorized Representative, or (b) User or any Authorized Representative as the
            partner, agent, fiduciary, or legal representative of RAF.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>J.  Representations, Warranties and
            Covenants of User.  User represents and warrants to RAF that: (i) User is duly
            organized, validly existing and in good standing under the laws of the state in
            which it is chartered, formed or otherwise organized; (ii) the execution,
            delivery and performance of this Agreement by or on behalf of User: (a) are
            within User’s power and authority; and (b) have been duly authorized by all
            necessary corporate, legislative, administrative, governmental or other action;
            (iii) this Agreement has been duly executed and delivered to RAF by User,
            regardless whether such execution is in paper form or in electronic media; (iv)
            each individual executing and delivering this Agreement (by clicking
            &quot;AGREE&quot; below or otherwise) is duly empowered and authorized to do
            so; (v) the execution, delivery, and performance by User of this Agreement, and
            any documentation relating to this Agreement to which it is a party or that it
            is required to deliver, do not violate, conflict with, or constitute a breach
            of or default under: (a) any federal, state or local law, regulation, ordinance
            or resolution binding on or adopted by User; (b) any provision of User’s
            charter, constitutional or other organizational documents; (c) any order or
            judgment of any court or other agency of government applicable to User or any
            of its assets; and (d) any contractual restriction binding on or affecting User
            or any of its assets; (vi) all governmental and other consents that are
            required to be obtained by User with respect to this Agreement (and any other
            documentation relating to this Agreement to which User is a party or that it is
            required to deliver) have been obtained and are in full force and effect, and all
            conditions of any such consents have been complied with; and (vii) this
            Agreement (and any other documentation relating to this Agreement to which User
            is a party or that User is required to deliver) is enforceable in accordance
            with its stated terms.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>K.  Sovereign Immunity Representations,
            Warranties and Covenants of User.  User also represents, warrants and covenants
            that it is not entitled to claim immunity on the grounds of sovereignty or
            other similar grounds with respect to itself or its revenues or assets
            (irrespective of their use or intended use) from: (i) suit; (ii) jurisdiction
            of any court; (iii) relief by way of injunction, order for specific performance
            or for recovery of property; (iv) attachment of its assets (whether before or
            after judgment); or (v) execution or enforcement of any judgment to which it or
            its revenues or assets might otherwise be made subject to legal proceeding in
            any courts.  No such immunity may be attributed to User or its revenues or
            assets, and User by its execution of this Agreement intends to and will waive,
            to the extent permitted by law, any defense of sovereign immunity with respect
            to any proceeding or other action by RAF to enforce any obligations of User
            under this Agreement.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>L.  Entire Agreement.  Subject to Section
            1.B, this Agreement represents the agreement between User and RAF in its
            entirety regarding the Website and the Service and merges and supersedes all
            previous and contemporaneous written or oral agreements and understandings
            regarding the subject of online access and/or the Website or the Service,
            except for duly executed and effective authorization and designation forms
            related to this Agreement as required by RAF.  Any term or condition contained
            in this Agreement which is inconsistent with the laws governing the Website or
            the Service will be deemed to have been modified to conform with such laws. 
            Except as otherwise expressly provided in this Agreement, if any provision of
            this Agreement is held to be invalid or otherwise unenforceable, the remainder
            of the provisions will remain in full force and effect and will in no way be
            invalidated or otherwise affected.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>M.  Waiver.  RAF may agree in writing to
            waive any provision of this Agreement, and RAF may revoke any such waiver. 
            RAF’s failure to act with respect to any breach does not waive RAF’s right to
            act with respect to subsequent or similar breaches.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>N.  Headings.  Headings in this Agreement
            are for reference only and in no way define, limit, construe, or describe the
            scope or extent of such section.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>O.  No Third Party Beneficiaries.  Except
            as otherwise expressly provided in this Agreement, User and RAF do not intend
            this Agreement to benefit or create any right or cause of action in or on
            behalf of any person or entity other than RAF and User.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>13.  Definitions.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>The following definitions apply to this
            Agreement: </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>A.  &quot;Applicant&quot; means any person
            or entity supplying information in an effort to obtain credit.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>B.  &quot;Authorized Representative&quot;
            means each natural person who is an officer, member, manager, partner, trustee,
            or other agent, as applicable, of User.  A User that is a natural person is an
            authorized user for himself or herself.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>C.  &quot;Business Day&quot; means Monday
            through Friday of each week, but does not include Saturday, Sunday or federal
            banking holidays. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>D.  &quot;Eligible Loan Application&quot;
            means each application or request for credit or information entered in or
            submitted through the Website, regardless of whether such application or
            request is complete or incomplete and regardless of whether such application or
            request results in the extension or denial of credit.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>E.  &quot;Include&quot; and
            &quot;including&quot; means the items following are not exclusive, are provided
            only as examples and are not the only possible items that could appear in the
            list. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>F.  &quot;Online Financial Service&quot;
            means each of the variety of products and services provided by RAF that User
            may access or enroll in through the Service and other related RAF services. 
            The term Online Financial Service includes actions an Authorized Representative
            takes, and instructions Authorized Representatives give RAF, through the
            Service concerning the opening or maintenance of Eligible Loan Applications.
            However, the term Online Financial Service does not include an Eligible Loan
            Application. </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>G.  &quot;Service&quot; means any and all
            services provided through the Website.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>H.  &quot;User&quot; means any person,
            persons, entity or entities accessing the Website as an Applicant or in an
            effort to obtain other information, services, or products from Rabo AgriFinance
            LLC.  </span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>Authorized Representative Certification.</span></p>

    <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>

    <p class=MsoNormal><span lang=EN-GB>Each person who clicks on the
            &quot;AGREE&quot; tab at the end of this Agreement or uses the Service hereby
            certifies that he or she is an Authorized Representative, as that term is
            defined in this Agreement, and that such Authorized Representative is bound by
            the terms of this Agreement in his or her capacity on behalf of User.  </span></p>
</div>