import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AssignedDealerPermission } from '../models/assigned-dealer-permission.model';
import { StateService } from '../../core/services/state.service';
import { UtilityService } from '../../core/services/utility.service';

@Component({
    selector: 'dol-welcome-bar',
    templateUrl: './welcome-bar.component.html',
    styleUrls: ['./welcome-bar.component.css']
})
export class WelcomeBarComponent implements OnInit {
    @Input() disabled: boolean;
    @Output() change = new EventEmitter<AssignedDealerPermission>();
    assignedDealers: Array<AssignedDealerPermission>;

    constructor(public stateService: StateService, private utilityService: UtilityService) { }

    ngOnInit() {
        const user = this.stateService.currentUser;
        this.assignedDealers = this.utilityService.orderBy(user.getUniqueAssignedDealers(), true, 'dealer.name', 'dealer.address.city');

        if (this.assignedDealers.length === 1) {
            this.handleAssignedDealerChange(this.assignedDealers[0]);
        }
    }

    handleAssignedDealerChange(assignedDealer: AssignedDealerPermission) {
        if (this.stateService.assignedDealer == null
            || this.stateService.assignedDealer.assignedDealerId !== assignedDealer.assignedDealerId) {
            this.stateService.assignedDealer = this.utilityService.isNullOrEmpty(assignedDealer) ? null : assignedDealer;
            this.change.emit(assignedDealer);
        }
    }
}
