import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { AppRoutingModule } from './app-routing.module';
// import { CoreModule } from '../app/core/core.module'; //TODO:MFA
import { CoreModule } from './core/core.module';
// import { LoginModule } from '../app/login/login.module'; // TODO:MFA
import { LoginModule } from './login/login.module';
import { PasswordModule } from './password/password.module'; //TODO:MFA
import { SharedModule } from '../app/shared/shared.module';
import { AppComponent } from './app.component';
//import { AppComponent } from '../app/app.component'; //TODO:MFA
import { HeaderBarComponent } from './core/header-bar/header-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IfAngularCoreModule } from 'if-angular-core';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { CONFIG } from '../environments/environment';
import { IfAngularSecurityModule, IfB2CConfigService} from 'if-angular-security';
import { AppModule } from '../app/app.module';
import { VERSIONCONFIG } from './version-config';
import { SiteComponent } from './site.component';

//TODO:MFA
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(IfB2CConfigService.getConfig(CONFIG));
  }

  /**
   * Set your default interaction type for MSALGuard here. If you have any
   * additional scopes you want the user to consent upon login, add them here as well.
   */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      loginFailedRoute: `/${VERSIONCONFIG.redirectPrefix}login-b2c`
    };
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        LoginModule,
        PasswordModule,
        SharedModule,        
        DialogModule,
        FontAwesomeModule,
        IfAngularCoreModule,
        IfAngularSecurityModule,
        AppModule // TODO:MFA
    ],
    declarations: [
        AppComponent,
        HeaderBarComponent,
        SiteComponent
    ],
    providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent] // TODO:MFA MsalRedirectComponent
})
export class AppModuleV2 { }
