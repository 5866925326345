import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ControlValueAccessor,
    FormGroup,
    FormBuilder,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';

import { buildZipMask } from '../../masks/masks';
import { Address } from '../../models/address.model';

@Component({
    selector: 'dol-address-input',
    templateUrl: './address-input.component.html',
    styles: [`
        .control-group {
            margin-top: 10px;
        }
        mat-form-field {
            width: 100%;
        }
    `],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AddressInputComponent }
    ]
})
export class AddressInputComponent implements ControlValueAccessor, OnInit {
    _onChange: (value: any) => void;

    addressGroup: FormGroup;
    @Input() required: boolean;
    // tslint:disable-next-line: no-input-rename
    @Input('showValidation') submitted: boolean;
    @Input() newDealerAddress: boolean;
    @Output() submit: EventEmitter<any> = new EventEmitter();

    zipMask = buildZipMask();

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.addressGroup = this.fb.group({
            line1: [null, this.required ? Validators.required : null],
            line2: null,
            city: [null, this.required ? Validators.required : null],
            state: [null, this.required ? Validators.required : null],
            zipcode: [null, this.required ? Validators.required : null],
            county: null,
            country: null
        });

        this.addressGroup.valueChanges.subscribe(address => {
            if (this.addressGroup.valid) {
                this._onChange(this.addressGroup.getRawValue());
            } else if (this.addressGroup.invalid && this.addressGroup.enabled) {
                this._onChange(null);
            }
        });
    }

    writeValue(address: Address) {
        if (address) {
            this.addressGroup.patchValue({
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                state: address.state,
                zipcode: address.zipcode,
                county: address.county,
                country: address.country
            });
        }
    }
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    registerOnTouched(fn: any) { }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.addressGroup.disable();
        } else {
            this.addressGroup.enable();
        }
    }

    bubbleSubmit() {
        this.submit.emit();
    }
}
