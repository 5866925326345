<form [formGroup]="addressGroup" (keyup.enter)="bubbleSubmit()">
    <div class="control-group">
        <mat-form-field>
            <input matInput placeholder="Line 1" formControlName="line1">
        </mat-form-field>
        <div class="error" *ngIf="submitted && addressGroup.get('line1').invalid">
            <p *ngIf="addressGroup.get('line1').hasError('required')">A street name is required</p>
        </div>
    </div>
    <div class="control-group">
        <mat-form-field>
            <input matInput placeholder="Line 2" formControlName="line2">
        </mat-form-field>
    </div>
    <div class="control-group">
        <mat-form-field>
            <input matInput placeholder="City" formControlName="city">
        </mat-form-field>
        <div class="error" *ngIf="submitted && addressGroup.get('city').invalid">
            <p *ngIf="addressGroup.get('city').hasError('required')">A city is required</p>
        </div>
    </div>
    <div class="control-group">
        <dol-state-input formControlName="state">
        </dol-state-input>
        <div class="error" *ngIf="submitted && addressGroup.get('state').invalid">
            <p *ngIf="addressGroup.get('state').hasError('required')">A state is required</p>
        </div>
    </div>
    <div class="control-group">
        <mat-form-field>
            <input matInput [textMask]="zipMask" placeholder="Zipcode" formControlName="zipcode">
        </mat-form-field>
        <div class="error" *ngIf="submitted && addressGroup.get('zipcode').invalid">
            <p *ngIf="addressGroup.get('zipcode').hasError('required')">A zip code is required</p>
        </div>
    </div>
    <div *ngIf="!newDealerAddress" class="control-group">
        <mat-form-field>
            <input matInput placeholder="County" formControlName="county">
        </mat-form-field>
    </div>
    <div *ngIf="!newDealerAddress" class="control-group">
        <mat-form-field>
            <input matInput placeholder="Country" formControlName="country">
        </mat-form-field>
    </div>
</form>