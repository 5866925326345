import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { parse } from 'json2csv';
import * as moment from 'moment';

import { CSVData } from '../../shared/models/csv-data.model';
import { CSVField } from '../../shared/models/csv-field.model';
import { CustomCustomerValue } from '../../shared/models/custom-customer-value.model';
import { ICustomCustomerValueContainer } from '../../shared/models/custom-customer-value-container.interface';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    constructor(private utilityService: UtilityService) { }

    export(label: string, json: CSVData) {
        const csv = parse(json.data, { fields: json.fields });
        const blob: Blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        const fileName = `${label}-${moment().format('MMDDYYYY-HHmmss')}.csv`;

        this.download(blob, fileName);
    }

    download(blob: Blob, name: string): void {
        saveAs(blob, name);
    }

    convertCustomCustomerValuesToCSVFields(results: Array<ICustomCustomerValueContainer>): Array<CSVField> {
        if (results && results[0] && results[0].customCustomerValues && results[0].customCustomerValues.length > 0) {
            const customCustomerValues = results[0].customCustomerValues;
            const csvFields = customCustomerValues.map(this.convertCustomCustomerValueToCSVField);
            return this.utilityService.orderBy(csvFields, true, 'label');
        } else {
            return [];
        }
    }

    private convertCustomCustomerValueToCSVField(customCustomerValue: CustomCustomerValue, index: number): CSVField {
        return {
            label: customCustomerValue.name,
            value: (row: ICustomCustomerValueContainer) => (row.customCustomerValues && row.customCustomerValues[index])
                ? row.customCustomerValues[index].value : ''
        };
    }

    adjustTransactionQuantityForCredit(data: any) {
        if (data) {
            data.forEach(x => {
                if (x.transaction.type === 'CreditInvoice' && x.transaction.quantity > 0) {
                    x.transaction.quantity = x.transaction.quantity * -1;
                }
            });
        }
    }
}
