import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DealerHeader } from '../models/dealer-header.model';
import { DealerService } from '../../core/services/dealer.service';
import { DealerQueryParameter } from '../models/dealer-query-parameter.model';

@Component({
    selector: 'dol-dealer-select',
    templateUrl: './dealer-select.component.html',
    styleUrls: ['./dealer-select.component.css'],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DealerSelectComponent }]
})
export class DealerSelectComponent implements OnInit, ControlValueAccessor {
    _onChange: (value: any) => void;
    @Input() layout: string;
    value: DealerHeader;
    dealers: Array<DealerHeader>;
    searching = false;
    searchForm: FormGroup;
    showSelect: boolean;

    constructor(private dealerService: DealerService, private fb: FormBuilder) {
        this.searchForm = this.fb.group({
            id: '',
            name: '',
            location: '',
            addressState: null
        });
    }

    ngOnInit() {
        this.showSelect = false;
    }

    writeValue(value: any) {
        if (value !== undefined) {
            this.value = value;
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }

    setAndSortDealers(dealers: DealerHeader[]) {
        this.dealers = dealers;
        this.sortDealers(this.dealers);
    }

    formatAddress(address): string {
        const streetAndCity = this.toTitleCase(address.line1 + ' ' + address.city + ',');
        return [streetAndCity, address.state, address.zipcode].join(' ');
    }

    toTitleCase(str: string): string {
        return str ? str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';
    }

    selectDealer(pd: DealerHeader) {
        this.showSelect = false;
        const pdPosition = this.dealers.indexOf(pd);
        this.dealers.splice(pdPosition, 1);

        // Throw back: if already selected AND search is unchanged
        if (this.value) {
            this.dealers.push(this.value);
            this.sortDealers(this.dealers);
        }

        this.value = pd;
        this._onChange(this.value);
        this.onExit();
    }

    reset() {
        this.searchForm.reset();
        this.dealers = [];
        this.value = null;
        this._onChange(this.value);
    }

    removeDealer() {
        // Throw back: if search is unchanged
        if (this.dealers) {
            this.dealers.push(this.value);
            this.setAndSortDealers(this.dealers);
        }

        this.value = null;
        this._onChange(this.value);
    }

    onExit() {
        this.searchForm.reset();
        this.dealers = null;
    }

    close() {
        this.onExit();
        this.showSelect = false;
    }

    search(e: any, searchForm: FormGroup): void {
        e.stopPropagation();
        const id = searchForm.get('id').value;
        const name = searchForm.get('name').value;
        const location = searchForm.get('location').value;
        const addressState = searchForm.get('addressState').value;

        this.searching = true;
        const query = new DealerQueryParameter();
        query.dealerNumber = id;
        query.name = name;
        query.location = location;
        query.addressState = addressState;
        query.isActive = true;
        this.dealerService.searchDealers(query)
            .subscribe((res) => {
                this.searching = false;
                if (res.length > 0) {
                    if (this.value) {
                        res = res.filter(d => d.dealerNumber !== this.value.dealerNumber);
                    }
                    this.dealers = res;
                    this.sortDealers(this.dealers);
                } else {
                    this.dealers = [];
                }
            }, (err: HttpErrorResponse) => {
                this.searching = false;
                console.log(err);
            });
    }

    sortDealers(dealers): void {
        dealers.sort((d1, d2) => {
            const compareNames = this.compare(d1.name, d2.name);
            if (compareNames !== 0) {
                return compareNames;
            }

            const compareCities = this.compare(d1.address.city, d2.address.city);
            if (compareCities !== 0) {
                return compareCities;
            }

            const compareStates = this.compare(d1.address.state, d2.address.state);
            if (compareStates !== 0) {
                return compareStates;
            }

            return this.compare(d1.address.line1, d2.address.line1);
        });
    }

    compare(a: any, b: any): number {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        } else {
            return 0;
        }
    }

}
