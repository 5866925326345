<div *ngIf="!value" class="select-button-wrapper">
    <button mat-raised-button type="button" class="btn btn-brand" (click)="showSelect = true">Select</button>
</div>
<div *ngIf="value" class="dealer-selection" [class.compact]="layout == 'compact'">
    <div class="dealer-selection-delete">
        <button mat-icon-button type="button" (click)="removeDealer()" title="Delete">
            <i class="fa fa-trash"></i>
        </button>
    </div>
    <div class="dealer-selection-value">
        <h3>{{value.name}} <span class="dealer-select-id">({{value.dealerNumber}})</span></h3>
        <label>{{formatAddress(value.address)}}</label>
    </div>
    <div class="dealer-selection-controls">
        <a mat-button (click)="showSelect = true">Change</a>
    </div>
</div>
<dol-modal-dialog [show]="showSelect" [large]="true" (close)="close()">
    <h1 class="title">Select a Dealer</h1>
    <div class="dealer-select">
        <form class="dealer-select-search" [formGroup]="searchForm" (ngSubmit)="search($event, searchForm)" novalidate>
            <div class="dealer-select-search-input">
                <mat-form-field>
                    <input matInput placeholder="ID" formControlName="id">
                </mat-form-field>
            </div>
            <div class="dealer-select-search-input">
                <mat-form-field>
                    <input matInput placeholder="Name" formControlName="name">
                </mat-form-field>
            </div>
            <div class="dealer-select-search-input">
                <mat-form-field>
                    <input matInput placeholder="Location" formControlName="location">
                </mat-form-field>
            </div>
            <div class="dealer-select-search-input">
                <dol-state-input formControlName="addressState">
                </dol-state-input>
            </div>
            <div class="dealer-select-search-controls">
                <button mat-raised-button type="submit" class="btn-brand">Search</button>
            </div>
        </form>
        <div class="dealer-select-content">
            <ul *ngIf="!searching" class="dealer-select-results">
                <li *ngFor="let pd of dealers" class="dealer-row">
                    <div class="dealer-row-header">
                        <h3>{{pd.name}} <span class="dealer-select-id">({{pd.dealerNumber}})</span></h3>
                        <label>{{formatAddress(pd.address)}}</label>
                    </div>
                    <div class="dealer-row-controls">
                        <button mat-raised-button type="button" class="btn-brand"
                            (click)="selectDealer(pd)">Select</button>
                    </div>
                </li>
                <li *ngIf="!dealers || dealers.length === 0" class="none">No results.</li>
            </ul>
            <div *ngIf="searching" class="loading-dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</dol-modal-dialog>