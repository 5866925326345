export class DescriptivePhoneNumber {
    phoneNumber: string;
    description: string;

    constructor(appData?: any) {
        if (appData) {
            this.phoneNumber = appData.PhoneNumber;
            this.description = appData.Description;
        }
    }
}
