<form class="login-form raised" [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <h1>Password Expired <dol-loading-feedback *ngIf="loading" [inline]="true">
        </dol-loading-feedback>
    </h1>
    <div *ngIf="serverError" class="error form-error">{{serverError}}</div>
    <div formGroupName="passwordGroup">
        <div>
            <mat-form-field>
                <input matInput type="password" placeholder="New Password" formControlName="newPassword" autofocus
                    autocomplete="off">
            </mat-form-field>
        </div>
        <ul *ngIf="submitted && form.get('passwords').get('newPassword').errors" class="error">
            <li *ngIf="form.get('passwords').get('newPassword').hasError('required')">A new password is required.</li>
            <li *ngIf="form.get('passwords').get('newPassword').hasError('passwordValidation')">
                {{passwordValidationMessage(form.get('passwords').get('newPassword').errors['passwordValidation'])}}
            </li>
        </ul>
        <div>
            <mat-form-field>
                <input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword"
                    autocomplete="off">
            </mat-form-field>
        </div>
        <ul *ngIf="submitted && form.get('passwords').get('confirmPassword').errors" class="error">
            <li *ngIf="form.get('passwords').get('confirmPassword').hasError('required')">Please re-type your password.
            </li>
        </ul>
        <ul *ngIf="submitted && form.get('passwords').errors" class="error">
            <li *ngIf="form.get('passwords').hasError('nomatch')">Your passwords do not match.</li>
        </ul>
    </div>
    <div class="login-form-controls">
        <button mat-raised-button type="submit" [disabled]="loading" class="btn-brand">Update Password</button>
    </div>
</form>