import { DealerBase } from './dealer-base.model';

export class AssignedDealerPermission {
    assignedDealerId: number;
    permission: string;
    dealer: DealerBase;

    constructor(appData?: any) {
        if (appData) {
            this.assignedDealerId = appData.AssignedDealerId;
            this.permission = appData.Permission;
            this.dealer = appData.Dealer ? new DealerBase(appData.Dealer) : null;
        }
    }
}
