<footer class="footer">
  <div class="container">
    <p>&copy; Rabobank {{year}} | <a href="https://www.rabobank.com/en/footer/disclaimer.html" target="_blank">Disclaimer</a>
      | <a href="https://www.raboag.com/security-privacy/privacy-policy-157" target="_blank">Privacy Statement</a>
      | <a routerLink="/contact">Contact Us</a></p>
    <p>This site uses cookies to provide for technical functionality as well as to make our website more enjoyable
      and relevant to you. By continuing your visit on the website, you consent to the use of these cookies.
    </p>
    <p>Rabo AgriFinance is glad to provide you with additional information. We want you to know that it is the policy of
      Rabo AgriFinance to maintain your private information as confidential. Rabo AgriFinance will not transmit personal
      information such as your social security number to you via e-mail, and we recommend that you do not e-mail such
      information to us. Please contact us directly by phone at (888) 395-8505 or in person for any questions regarding
      your account involving confidential information.</p>
  </div>
</footer>