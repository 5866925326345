<div class="welcome-bar group">
    <label>Welcome</label>
    <mat-form-field class="dealer-select" floatLabel="never">
        <mat-select [ngModel]="stateService.assignedDealer" (ngModelChange)="handleAssignedDealerChange($event)"
            [disabled]="disabled" placeholder="[ View all dealers ]">
            <mat-option *ngFor="let assignedDealer of assignedDealers" [value]="assignedDealer">
                {{assignedDealer.dealer.name}} from {{assignedDealer.dealer.address.city}},
                {{assignedDealer.dealer.address.state}}
            </mat-option>
            <mat-option *ngIf="assignedDealers && assignedDealers.length > 1" [value]="{}">[ View all dealers ]
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>