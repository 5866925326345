import { AssignedDealerPermission } from './assigned-dealer-permission.model';
import { MyCustomerResult } from './my-customer-result.model';
import { PermissionService } from '../../core/services/authorization/permission.service';
import {
    DEALER_PERMISSION_TRANSACT,
    DEALER_PERMISSION_USER_ACCESS_MANAGEMENT,
    DEALER_PERMISSION_VIEW,
    DEALER_PERMISSION_ADMIN,
    DEALER_PERMISSION_CREDIT_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR,
    DEALER_PERMISSION_RENEWALS,
    DEALER_PERMISSION_LOAN_DETAIL_REPORT,
    DEALER_PERMISSION_PAYOFF_QUOTE,
    DEALER_PERMISSION_LOAN_HISTORY,
    DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR,
    DEALER_PERMISSION_DOCUMENT_INBOX,
    DEALER_ROLE_PERMISSIONS,
    DEALER_LOCATION_PERMISSIONS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH
} from './dealer-permissions';
import {
    PROGRAM_DELETE,
    PROGRAM_VIEW,
    PROGRAM_EDIT,
    PROGRAM_APPROVE,
    ASSIGNED_DEALER_GET_ANY,
    ASSIGNED_DEALER_EDIT,
    DEALER_VIEW,
    DEALER_EDIT,
    DEALER_DOCUMENT_DELETE,
    COMMITMENT_MASTER_APPROVE,
    COMMITMENT_MASTER_VIEW,
    COMMITMENT_MASTER_STAGE,
    CANCEL_PENDING_MASTER_COMMITMENT,
    USER_SAVE,
    USER_VIEW,
    DEALER_LOCATION_PERMISSION_VIEW,
    DEALER_LOCATION_PERMISSION_EDIT,
    DEALER_SETTING_VIEW,
    DEALER_SETTING_EDIT,
    DEALER_TAGS_VIEW,
    DEALER_TAGS_EDIT,
    DEALER_STATUS_EDIT,
    TRANSACTION_LIMIT_VOUCHER_APPROVE,
    DEALER_UPDATE_TRANSACTION_LIMIT,
    BRIDGE_TRANSACTION_UPDATE,
    CONTACT_SCREEN
} from './permissions';

export class PermissionBase {
    PROGRAM_DELETE: string = PROGRAM_DELETE;
    PROGRAM_VIEW: string = PROGRAM_VIEW;
    PROGRAM_EDIT: string = PROGRAM_EDIT;
    PROGRAM_APPROVE: string = PROGRAM_APPROVE;
    ASSIGNED_DEALER_GET_ANY: string = ASSIGNED_DEALER_GET_ANY;
    ASSIGNED_DEALER_EDIT: string = ASSIGNED_DEALER_EDIT;
    DEALER_VIEW: string = DEALER_VIEW;
    DEALER_EDIT: string = DEALER_EDIT;
    DEALER_DOCUMENT_DELETE: string = DEALER_DOCUMENT_DELETE;
    COMMITMENT_MASTER_APPROVE: string = COMMITMENT_MASTER_APPROVE;
    COMMITMENT_MASTER_VIEW: string = COMMITMENT_MASTER_VIEW;
    COMMITMENT_MASTER_STAGE: string = COMMITMENT_MASTER_STAGE;
    CANCEL_PENDING_MASTER_COMMITMENT: string = CANCEL_PENDING_MASTER_COMMITMENT;
    USER_SAVE: string = USER_SAVE;
    USER_VIEW: string = USER_VIEW;

    DEALER_PERMISSION_ADMIN: string = DEALER_PERMISSION_ADMIN;
    DEALER_PERMISSION_VIEW: string = DEALER_PERMISSION_VIEW;
    DEALER_PERMISSION_TRANSACT: string = DEALER_PERMISSION_TRANSACT;
    DEALER_PERMISSION_USER_ACCESS_MANAGEMENT: string = DEALER_PERMISSION_USER_ACCESS_MANAGEMENT;
    DEALER_PERMISSION_CREDIT_ADMINISTRATOR: string = DEALER_PERMISSION_CREDIT_ADMINISTRATOR;
    DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR: string = DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR;
    DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR: string = DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR;
    DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR: string = DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR;
    DEALER_PERMISSION_RENEWALS: string = DEALER_PERMISSION_RENEWALS;
    DEALER_PERMISSION_LOAN_DETAIL_REPORT: string = DEALER_PERMISSION_LOAN_DETAIL_REPORT;
    DEALER_PERMISSION_PAYOFF_QUOTE: string = DEALER_PERMISSION_PAYOFF_QUOTE;
    DEALER_PERMISSION_LOAN_HISTORY: string = DEALER_PERMISSION_LOAN_HISTORY;
    DEALER_PERMISSION_DOCUMENT_INBOX: string = DEALER_PERMISSION_DOCUMENT_INBOX;
    DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS: string = DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS;
    DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS: string = DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS;
    DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH: string = DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH;
    DEALER_ROLE_PERMISSIONS: Array<string> = DEALER_ROLE_PERMISSIONS;
    DEALER_LOCATION_PERMISSIONS: Array<string> = DEALER_LOCATION_PERMISSIONS;

    DEALER_LOCATION_PERMISSION_VIEW: string = DEALER_LOCATION_PERMISSION_VIEW;
    DEALER_LOCATION_PERMISSION_EDIT: string = DEALER_LOCATION_PERMISSION_EDIT;
    DEALER_SETTING_VIEW: string = DEALER_SETTING_VIEW;
    DEALER_SETTING_EDIT: string = DEALER_SETTING_EDIT;
    DEALER_TAGS_VIEW: string = DEALER_TAGS_VIEW;
    DEALER_TAGS_EDIT: string = DEALER_TAGS_EDIT;
    DEALER_STATUS_EDIT: string = DEALER_STATUS_EDIT;
    TRANSACTION_LIMIT_VOUCHER_APPROVE: string = TRANSACTION_LIMIT_VOUCHER_APPROVE;
    DEALER_UPDATE_TRANSACTION_LIMIT: string = DEALER_UPDATE_TRANSACTION_LIMIT;
    BRIDGE_TRANSACTION_UPDATE: string = BRIDGE_TRANSACTION_UPDATE;
    CONTACT_SCREEN: string = CONTACT_SCREEN;

    constructor(protected permissionService: PermissionService) { }

    filterByPermission(dealerIds: Array<number>, permissionToFilterBy: string = null): Array<number> {
        return this.filterByPermissions(dealerIds, [permissionToFilterBy]);
    }

    filterByPermissions(dealerIds: Array<number>, permissionsToFilterBy: Array<string> = null): Array<number> {
        if (!dealerIds) {
            return null;
        }

        if (dealerIds.length === 1) {
            return dealerIds;
        }

        return dealerIds
            .filter(dealerId => permissionsToFilterBy
                .some(permission => this.permissionService.hasDealerPermissionSet(permission, dealerId)))
            .filter((dealerId, index, self) => self.indexOf(dealerId) === index);
    }

    canViewCustomers(assignedDealers: Array<AssignedDealerPermission>): boolean {
        // this method controls if the person should automatically be forwarded to the profile page
        // because they don't have permissions to view my customer page this is for user experience purposes
        return (assignedDealers && assignedDealers.length > 0)
            && assignedDealers.filter(assignedDealer =>
                this.DEALER_PERMISSION_USER_ACCESS_MANAGEMENT !== assignedDealer.permission
                && this.DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS !== assignedDealer.permission
                && this.DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS !== assignedDealer.permission
                && this.DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH !== assignedDealer.permission
            ).length !== 0;
    }

    hasPermission(permission: string): boolean {
        return this.permissionService.hasPermission(permission);
    }

    hasDealerPermission(permission: string, additionalActionTypes: Array<string> = null): boolean {
        return this.permissionService.hasDealerPermission(permission, additionalActionTypes);
    }

    canViewRenew(): boolean {
        return this.permissionService.canViewRenew();
    }

    canAnyDealerViewRenew(): boolean {
        return this.permissionService.canAnyDealerViewRenew();
    }

    canViewLoanDetailReport(): boolean {
        return this.permissionService.canViewLoanDetailReport();
    }

    canAnyDealerViewLoanDetailReport(): boolean {
        return this.permissionService.canAnyDealerViewLoanDetailReport();
    }

    canViewPayoffQuote(): boolean {
        return this.permissionService.canViewPayoffQuote();
    }

    canAnyDealerViewPayoffQuote(): boolean {
        return this.permissionService.canAnyDealerViewPayoffQuote();
    }

    canViewLoanHistory(): boolean {
        return this.permissionService.canViewLoanHistory();
    }

    canAnyDealerViewLoanHistory(): boolean {
        return this.permissionService.canAnyDealerViewLoanHistory();
    }

    canViewCustomerNumbers(parentDealerIds: number[]): boolean {
        return this.permissionService.canViewCustomerNumbers(parentDealerIds);
    }

    canViewDocumentInbox(): boolean {
        return this.permissionService.canViewDocumentInbox();
    }

    canAnyDealerViewDocumentInbox(): boolean {
        return this.permissionService.canAnyDealerViewDocumentInbox();
    }

    canViewCustomerValues(parentDealerIds: number[]): boolean {
        return this.permissionService.canViewCustomerValues(parentDealerIds);
    }

    doesAnyDealerHavePermission(permission: string, additionalActionTypes: Array<string> = null): boolean {
        return this.permissionService.doesAnyDealerHavePermission(permission, additionalActionTypes);
    }

    getReadablePermission(permission: string): string {
        return this.permissionService.getReadablePermission(permission);
    }

    canViewDealerInvoicingDocuments(): boolean {
        return this.permissionService.canViewDealerInvoicingDocuments();
    }

    canAnyDealerViewInvoicingDocuments(): boolean {
        return this.permissionService.canAnyDealerViewInvoicingDocuments();
    }

    canDealerTransact(customer: MyCustomerResult, currentDealerId: number): boolean {
        return (customer
            && (customer.canTransactCredit || customer.canTransactPurchase)
            && this.permissionService.hasDealerPermissionSet(this.DEALER_PERMISSION_TRANSACT, currentDealerId));
    }
}
