import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountService } from '../../core/services/account.service';
import { AuthService } from '../../core/services/authentication/auth.service';
import { validatePassword } from '../../shared/validators/validate-password.validator';
import { passwordMatcher } from '../../shared/validators/password-matcher.validator';

@Component({
    selector: 'dol-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
    form: FormGroup;
    invalidFormMessage: string;
    submitted: boolean;
    params: any;
    securityQuestion: string;
    isInvalidUserOrTokenInfo = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService) { }

    ngOnInit() {
        this.form = this.fb.group({
            passwords: this.fb.group({
                newPassword: ['', Validators.compose([Validators.required, validatePassword])],
                confirmPassword: ['', Validators.required]
            }, { validator: passwordMatcher }),
            answer: ['', Validators.required]
        });
        this.submitted = false;
        this.invalidFormMessage = '';
        this.route.queryParams.subscribe((params) => {
            this.params = params;

            const accountDetails = {
                Email: this.params.email,
                ResetPasswordToken: this.params.token,
                Password: null
            };
            this.isInvalidUserOrTokenInfo = false;
            this.accountService.getSecurityQuestion(accountDetails)
                .subscribe((question) => {
                    this.securityQuestion = question;
                    if (!question) {
                        this.form.removeControl('answer');
                    }
                }, (err: HttpErrorResponse) => {
                    console.log(err);

                    if (err.status === 401) {
                        this.invalidFormMessage = 'Invalid user or reset token has expired.';
                        this.isInvalidUserOrTokenInfo = true;
                    } else {
                        this.invalidFormMessage = 'We are experiencing issues. Please try again later.';
                        this.isInvalidUserOrTokenInfo = false;
                    }
                });
        });
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }
        return message;
    }

    submit(form): void {
        this.submitted = true;
        this.isInvalidUserOrTokenInfo = false;
        if (form.valid) {
            const requestDetails = {
                Question: this.securityQuestion,
                Answer: form.value.answer,
                SecurityQuestions: [],
                NewPassword: form.value.passwords.newPassword,
                Email: this.params.email,
                Token: this.params.token
            };
            this.authService.resetPassword(requestDetails)
                .subscribe(() => {
                    this.router.navigate(['/confirmation']);
                }, (err: HttpErrorResponse) => {
                    console.log(err);

                    if (err.status === 401) {
                        this.invalidFormMessage = 'Your security question answer is incorrect.';
                    } else if (!(err.error instanceof Error)) {
                        const error = err.error;
                        const message = error.Message
                            ? error.Message
                            : error;

                        if (message.includes('Cannot reuse old password')) {
                            this.invalidFormMessage = 'You\'ve used this password before. Please enter a different password.';
                        } else {
                            this.invalidFormMessage = message;
                        }
                    } else {
                        this.invalidFormMessage = 'We are experiencing issues. Please try again later.';
                    }
                });
        }
    }
}
