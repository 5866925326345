export const DEALER_PERMISSION_ADMIN = 'Admin';
export const DEALER_PERMISSION_USER_ACCESS_MANAGEMENT = 'UserAccessManagement';
export const DEALER_PERMISSION_VIEW = 'View';
export const DEALER_PERMISSION_TRANSACT = 'Transact';
export const DEALER_PERMISSION_RENEWALS = 'Renewals';
export const DEALER_PERMISSION_LOAN_DETAIL_REPORT = 'LoanDetailReport';
export const DEALER_PERMISSION_PAYOFF_QUOTE = 'PayoffQuote';
export const DEALER_PERMISSION_LOAN_HISTORY = 'LoanHistory';
export const DEALER_PERMISSION_CREDIT_ADMINISTRATOR = 'CreditAdministrator';
export const DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR = 'CustomerNumberAdministrator';
export const DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR = 'DocumentAdministrator';
export const DEALER_PERMISSION_DOCUMENT_INBOX = 'DocumentInbox';
export const DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR = 'CustomColumnAdministrator';
export const DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS = 'DealerStatement';
export const DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS = 'UnsettledTransactions';
export const DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH = 'AR2Cash';


export const DEALER_ROLE_PERMISSIONS = [
    DEALER_PERMISSION_ADMIN,
    DEALER_PERMISSION_CREDIT_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR,
    DEALER_PERMISSION_USER_ACCESS_MANAGEMENT,
    DEALER_PERMISSION_VIEW,
    DEALER_PERMISSION_TRANSACT,
    DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR,
    DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH
];

export const DEALER_LOCATION_PERMISSIONS = [
    DEALER_PERMISSION_RENEWALS,
    DEALER_PERMISSION_LOAN_DETAIL_REPORT,
    DEALER_PERMISSION_PAYOFF_QUOTE,
    DEALER_PERMISSION_LOAN_HISTORY,
    DEALER_PERMISSION_DOCUMENT_INBOX
];
