export class ProgramProductUnitOfMeasures {
    programProductUnitOfMeasureId: number;
    programId: number;
    productUnitOfMeasure: string;

    constructor(appData?: any) {
        if (appData) {
            this.programProductUnitOfMeasureId = appData.ProgramProductUnitOfMeasureId ? appData.ProgramProductUnitOfMeasureId : 0;
            this.programId = appData.ProgramId ? appData.ProgramId : null;
            this.productUnitOfMeasure = appData.ProductUnitOfMeasure ? appData.ProductUnitOfMeasure : null;
        }
    }
}
