export class PurchaseGroup {
    minimumAmount: number;
    combinationType: string;
    requiredProducts: Array<string>;
    productPurchaseAmounts: Array<number> = Array<number>();
    purchaseGroups: Array<PurchaseGroup> = Array<PurchaseGroup>();

    constructor(appData?: any) {
        if (appData) {
            this.combinationType = appData.CombinationType;
            this.minimumAmount = appData.MinimumAmount;
            this.purchaseGroups = appData.PurchaseGroups ? appData.PurchaseGroups.map((data) => new PurchaseGroup(data)) : null;
            this.productPurchaseAmounts = appData.ProductPurchaseAmounts
                ? appData.ProductPurchaseAmounts.map((data) => Number(data))
                : Array<number>();
            this.requiredProducts = appData.RequiredProducts ? appData.RequiredProducts.map((data) => data) : new Array<PurchaseGroup>();
        }
    }
}
