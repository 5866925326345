import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CONFIG } from '../../../../environments/environment';
import { EventModel } from '../models/event.model';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(private http: HttpClient) { }

    recordError(path: string, error: string, stackTrace: string, userId: string): Observable<string> {
        const apiUrl = CONFIG.apiBaseUrl + 'Logger/Error';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    recordInfo(path: string, error: string, stackTrace: string, userId: string): Observable<string> {
        const apiUrl = CONFIG.apiBaseUrl + 'Logger/Info';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    recordWarn(path: string, error: string, stackTrace: string, userId: string): Observable<string> {
        const apiUrl = CONFIG.apiBaseUrl + 'Logger/Warn';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    private record(apiUrl: string, path: string, error: string, stackTrace: string, userId: string): Observable<string> {
        const event = new EventModel();

        event.website = CONFIG.websiteName;
        event.path = path;
        event.error = error;
        event.stackTrace = stackTrace;
        event.userId = userId;
        event.userAgent = navigator.userAgent;

        return this.http.post(apiUrl, event, { responseType: 'text' });

    }
}
