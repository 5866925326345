export class IndexType {
    private constructor(value: string) {
        this.type = value;
    }
    private static typeToString: Map<IndexType, string> = new Map<IndexType, string>();
    private static valueToType: Map<string, IndexType> = new Map<string, IndexType>();

    private type: string;

    static initialize() {
        IndexType.mapType('Fixed', 'Fixed');
        IndexType.mapType('Prime', 'Prime');
        IndexType.mapType('CanadianPrime', 'Canadian Prime');
        IndexType.mapType('Libor30', 'Libor 30');
        IndexType.mapType('Recourse', 'Recourse');
        IndexType.mapType('Application', 'Application');
    }

    static getAllTypes(): IndexType[] {
        return Array.from(IndexType.typeToString.keys());
    }
    static getType(value: string) {
        return IndexType.valueToType.get(value);
    }

    static getRenewalTypes(): IndexType[] {
        const renewalIndexTypes = ['Fixed', 'Prime', 'Libor30'];
        return this.getAllTypes()
            .filter(x => renewalIndexTypes.includes(x.getValue()));
    }

    private static mapType(value: string, displayValue: string) {
        const type = new IndexType(value);
        IndexType.typeToString.set(type, displayValue);
        IndexType.valueToType.set(value, type);
    }

    getDisplayValue() {
        return IndexType.typeToString.get(this);
    }

    getValue() {
        return this.type;
    }
}
IndexType.initialize();
