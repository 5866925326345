import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../core/services/authentication/auth.service';
import { validateEmail } from '../../shared/validators/email.validator';

@Component({
    selector: 'dol-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
    @ViewChild('email') email: ElementRef;
    form: FormGroup;
    showConfirmation: boolean;
    submitted: boolean;
    invalidFormMessage: string;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private renderer: Renderer2,
        private location: Location) {
        this.form = this.fb.group({
            email: ['', [Validators.required, validateEmail]]
        });

        this.showConfirmation = false;
        this.submitted = false;
        this.invalidFormMessage = '';
    }

    goBack(): void {
        this.location.back();
    }

    submit(form): void {
        if (!this.showConfirmation) {
            this.submitted = true;
            if (form.valid) {
                this.form.disable();
                const email = form.get('email').value;
                this.authService.requestPasswordReset(email)
                    .pipe(
                        finalize(() => { this.form.enable(); })
                    )
                    .subscribe(() => {
                        this.showConfirmation = true;

                        this.email.nativeElement.blur();
                    }, (err: HttpErrorResponse) => {
                        console.log(err);
                        this.invalidFormMessage = 'We are experiencing issues. Please try again later.';
                    });
            }
        }
    }
}
