import * as moment from 'moment';

import { UserHeader } from '../../shared/models/user-header.model';
import { DealerHeader } from './dealer-header.model';
import { IDocument } from './document.interface';

export class DealerDocument implements IDocument {
    id: number;
    dealer: DealerHeader = new DealerHeader();
    name: string;
    createDate: moment.Moment;
    createdBy: UserHeader = new UserHeader();
    documentType: string;

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.Id;
            this.dealer = appData.Dealer ? new DealerHeader(appData.Dealer) : new DealerHeader();
            this.name = appData.Name;
            this.createDate = appData.CreateDate ? moment(appData.CreateDate) : null;
            this.createdBy = appData.CreatedBy ? new UserHeader(appData.CreatedBy) : new UserHeader();
            this.documentType = appData.DealerDocumentType;
        }
    }
}
