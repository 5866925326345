export class CustomCustomerColumn {
    customCustomerColumnId: number;
    dealerId: number;
    name: string;

    constructor(appData?: any) {
        if (appData) {
            this.customCustomerColumnId = appData.CustomCustomerColumnId;
            this.dealerId = appData.DealerId;
            this.name = appData.Name;
        }
    }
}
