import { Injectable } from '@angular/core';

import { CONFIG } from '../../../../environments/environment';
import {
    DEALER_PERMISSION_ADMIN,
    DEALER_PERMISSION_CREDIT_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR,
    DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR,
    DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR,
    DEALER_PERMISSION_DOCUMENT_INBOX,
    DEALER_PERMISSION_LOAN_DETAIL_REPORT,
    DEALER_PERMISSION_LOAN_HISTORY,
    DEALER_PERMISSION_PAYOFF_QUOTE,
    DEALER_PERMISSION_RENEWALS,
    DEALER_PERMISSION_TRANSACT,
    DEALER_PERMISSION_USER_ACCESS_MANAGEMENT,
    DEALER_PERMISSION_VIEW,
    DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS,
    DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH
} from '../../../shared/models/dealer-permissions';
import { User } from '../../../shared/models/user.model';
import { StateService } from '../state.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(private stateService: StateService) { }

    hasPermission(permission: string): boolean {
        const user = this.stateService.currentUser;
        return permission && (this.isAdmin(user) || user.permissions.indexOf(permission) !== -1);
    }

    hasDealerPermission(actionType: string, additionalActionTypes: Array<string> = null): boolean {
        const user = this.stateService.currentUser;
        if (this.isAdmin(user)) { return true; }

        const dealerId = this.stateService.getCurrentDealerId();
        return additionalActionTypes == null
            ? this.hasDealerPermissionSet(actionType, dealerId)
            : this.hasDealerPermissionSet(actionType, dealerId)
            && additionalActionTypes.some(additionalType => this.hasDealerPermissionSet(additionalType, dealerId));
    }

    hasDealerPermissionSet(actionType: string, dealerId: number): boolean {
        if (dealerId === null) { return false; }

        switch (actionType) {
            case DEALER_PERMISSION_CREDIT_ADMINISTRATOR:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_CREDIT_ADMINISTRATOR]);
            case DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR:
                return this.hasDealerPermissionInternal(dealerId,
                    [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR]);
            case DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR]);
            case DEALER_PERMISSION_ADMIN:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN]);
            case DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR]);
            case DEALER_PERMISSION_TRANSACT:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_TRANSACT]);
            case DEALER_PERMISSION_USER_ACCESS_MANAGEMENT:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_USER_ACCESS_MANAGEMENT]);
            case DEALER_PERMISSION_VIEW:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_VIEW]);
            case DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS:
                return this.hasDealerPermissionInternal(dealerId,
                    [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS]);
            case DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS:
                return this.hasDealerPermissionInternal(dealerId,
                    [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS]);
            case DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH:
                return this.hasDealerPermissionInternal(dealerId, [DEALER_PERMISSION_ADMIN, DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH]);

            default:
                return this.hasDealerPermissionInternal(dealerId, [actionType]);
        }
    }

    canViewRenew(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_RENEWALS, [DEALER_PERMISSION_CREDIT_ADMINISTRATOR]));
    }

    canAnyDealerViewRenew(): boolean {
        return (this.doesAnyDealerHavePermission(DEALER_PERMISSION_RENEWALS, [DEALER_PERMISSION_CREDIT_ADMINISTRATOR]));
    }

    canViewLoanDetailReport(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_LOAN_DETAIL_REPORT, [DEALER_PERMISSION_VIEW]));
    }

    canAnyDealerViewLoanDetailReport(): boolean {
        return (this.doesAnyDealerHavePermission(DEALER_PERMISSION_LOAN_DETAIL_REPORT, [DEALER_PERMISSION_VIEW]));
    }

    canViewPayoffQuote(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_PAYOFF_QUOTE, [DEALER_PERMISSION_VIEW, DEALER_PERMISSION_TRANSACT]));
    }

    canAnyDealerViewPayoffQuote(): boolean {
        return (this.doesAnyDealerHavePermission(DEALER_PERMISSION_PAYOFF_QUOTE, [DEALER_PERMISSION_VIEW, DEALER_PERMISSION_TRANSACT]));
    }

    canViewLoanHistory(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_LOAN_HISTORY, [DEALER_PERMISSION_VIEW, DEALER_PERMISSION_TRANSACT]));
    }

    canAnyDealerViewLoanHistory(): boolean {
        return (this.doesAnyDealerHavePermission(DEALER_PERMISSION_LOAN_HISTORY, [DEALER_PERMISSION_VIEW, DEALER_PERMISSION_TRANSACT]));
    }

    canViewDocumentInbox(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_DOCUMENT_INBOX, [DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR]));
    }

    canAnyDealerViewDocumentInbox(): boolean {
        return this.doesAnyDealerHavePermission(DEALER_PERMISSION_DOCUMENT_INBOX, [DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR]);
    }

    canViewCustomerNumbers(parentDealerIds: number[]): boolean {
        if (!parentDealerIds || this.stateService.getCurrentDealerId() == null) {
            return false;
        }

        return (this.hasDealerPermission(DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR)
            && (parentDealerIds.indexOf(this.stateService.getCurrentDealerId()) !== -1));
    }

    canViewCustomerValues(parentDealerIds: number[]): boolean {
        if (!parentDealerIds || this.stateService.getCurrentDealerId() == null) {
            return false;
        }

        return (this.hasDealerPermission(DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR)
            && (parentDealerIds.indexOf(this.stateService.getCurrentDealerId()) !== -1));
    }

    canViewDealerInvoicingDocuments(): boolean {
        return (this.hasDealerPermission(DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS)
            || this.hasDealerPermission(DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS)
            || this.hasDealerPermission(DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH));
    }

    canAnyDealerViewInvoicingDocuments(): boolean {
        return (this.doesAnyDealerHavePermission(DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS)
            || this.doesAnyDealerHavePermission(DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS)
            || this.doesAnyDealerHavePermission(DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH));
    }

    doesAnyDealerHavePermission(actionType: string, additionalActionTypes: Array<string> = null): boolean {
        const user = this.stateService.currentUser;
        const assignedDealers = user.assignedDealers;

        return additionalActionTypes == null
            ? assignedDealers.some(element => this.hasDealerPermissionSet(actionType, element.dealer.rowId))
            : assignedDealers.some(element => this.hasDealerPermissionSet(actionType, element.dealer.rowId)
                && additionalActionTypes.some(additionalType => this.hasDealerPermissionSet(additionalType, element.dealer.rowId)));
    }

    getReadablePermission(permission: string): string {
        switch (permission) {
            case DEALER_PERMISSION_TRANSACT:
                return 'Transactions';
            case DEALER_PERMISSION_USER_ACCESS_MANAGEMENT:
                return 'User Access Management';
            case DEALER_PERMISSION_VIEW:
                return 'Reporting';
            case DEALER_PERMISSION_ADMIN:
                return 'Administrator';
            case DEALER_PERMISSION_CREDIT_ADMINISTRATOR:
                return 'Credit Administrator';
            case DEALER_PERMISSION_CUSTOMER_NUMBER_ADMINISTRATOR:
                return 'Customer Number Administrator';
            case DEALER_PERMISSION_CUSTOM_COLUMN_ADMINISTRATOR:
                return 'Custom Column Administrator';
            case DEALER_PERMISSION_RENEWALS:
                return 'Renewals';
            case DEALER_PERMISSION_LOAN_DETAIL_REPORT:
                return 'Loan Detail Report';
            case DEALER_PERMISSION_PAYOFF_QUOTE:
                return 'Payoff Quote';
            case DEALER_PERMISSION_LOAN_HISTORY:
                return 'Loan History';
            case DEALER_PERMISSION_DOCUMENT_ADMINISTRATOR:
                return 'Document Administrator';
            case DEALER_PERMISSION_DOCUMENT_INBOX:
                return 'Document Inbox';
            case DEALER_PERMISSION_DOCUMENT_INVOICING_STATEMENTS:
                return 'Statements';
            case DEALER_PERMISSION_DOCUMENT_INVOICING_UNSETTLED_TRANSACTIONS:
                return 'Unsettled Transactions';
            case DEALER_PERMISSION_DOCUMENT_INVOICING_AR2CASH:
                return 'Ar2Cash';
        }
        return null;
    }

    protected isAdmin(user: User): boolean {
        return user.roles.indexOf('Admin') !== -1;
    }

    protected hasDealerPermissionInternal(dealerId: number, allowedPermissions: Array<string>): boolean {
        const permissions = this.stateService.currentUser.permissions;

        return this.stateService.currentUser
            && allowedPermissions.some(permission => permissions.indexOf('DealerSpecific:' + dealerId + ':' + permission) !== -1);
    }
}
