import * as moment from 'moment';

import { PurchaseGroup } from './purchase-group.model';
import { RateStep } from './rate-step.model';

export class ProgramIncentive {
    requiredProducts: PurchaseGroup;
    minimumPurchaseAmount: number;
    rateSteps: Array<RateStep>;
    programIncentiveId: number;
    programId: number;
    incentiveTypeId: number;

    constructor(appData?: any) {
        if (appData) {
            this.minimumPurchaseAmount = appData.MinimumPurchaseAmount ? appData.MinimumPurchaseAmount : null;
            this.rateSteps = appData.RateSteps
                ? appData.RateSteps
                    .map((data) => new RateStep(data))
                    .sort((p1, p2) => p1.appliedOrder - p2.appliedOrder)
                : [];
            this.requiredProducts
                = (appData.RequiredProducts
                    && appData.RequiredProducts.PurchaseGroups
                    && appData.RequiredProducts.PurchaseGroups.length > 0)
                    ? new PurchaseGroup(appData.RequiredProducts)
                    : null;
            this.programIncentiveId = appData.ProgramIncentiveId;
            this.programId = appData.ProgramId;
            this.incentiveTypeId = appData.IncentiveTypeId;
        }
    }

    getIntroductoryGrowerRate(): string {
        if (!this.rateSteps || this.rateSteps.length === 0) {
            return null;
        }
        this.rateSteps.sort((p1, p2) => p1.appliedOrder - p2.appliedOrder);
        const rate = this.rateSteps[0].growerRate;

        return rate == null ? null : rate.toString();
    }
}
