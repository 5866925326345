// **NOTE**: When you add new types ot CreditTypes, go to the Code, Raf.Lending.Data.LoanDataAccess.cs and update the CreditTypes list
export const CREDIT_TYPES: string[] = [
    'CreditInvoice',
    'SponsoringDealerCreditInvoice',
    'SponsoringDealerReverseCreditInvoice',
    'ReverseCreditInvoice',
    '1200',
    '51200',
    '51201',
    '1201',
    '1203'];
