import * as moment from 'moment';

export class Comment {
    id: number;
    userName: string;
    createDate: moment.Moment;
    text: string;

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.Id;
            this.userName = appData.UserName;
            this.createDate = appData.CreateDate ? moment(appData.CreateDate) : null;
            this.text = appData.Text;
        }
    }
}
