import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../authentication/auth.service';
import { StateService } from '../state.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private stateService: StateService, private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedIn = this.authService.loggedIn();

        if (!loggedIn) {
            this.stateService.redirectUrl = state.url;
            this.router.navigate(['/login']);
        }

        return loggedIn;
    }
}
