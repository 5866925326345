import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { PermissionService } from './permission.service';
import { StateService } from '../state.service';
import {
    DEALER_PERMISSION_DOCUMENT_INBOX,
    DEALER_PERMISSION_LOAN_DETAIL_REPORT,
    DEALER_PERMISSION_LOAN_HISTORY,
    DEALER_PERMISSION_PAYOFF_QUOTE,
    DEALER_PERMISSION_RENEWALS
} from '../../../shared/models/dealer-permissions';

@Injectable({
    providedIn: 'root'
})
export class DealerPermissionGuard implements CanActivate {
    constructor(private permissionService: PermissionService, private router: Router, private stateService: StateService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const actionType = route.data['permission'];
        const allowedList = actionType.map(x => this.canActivateWithPermission(x));
        const allowed = !(allowedList.indexOf(false) >= 0);

        if (!allowed && route.data['redirectTo']) {
            this.router.navigate([route.data['redirectTo']]);
        }

        return allowed;
    }

    private canActivateWithPermission(actionType: string): boolean {
        const assignedDealer = this.stateService.assignedDealer;
        return (assignedDealer && !!assignedDealer.dealer && this.hasDealerPermission(actionType));
    }

    private hasDealerPermission(actionType: string): boolean {
        switch (actionType) {
            case DEALER_PERMISSION_RENEWALS:
                return this.permissionService.canViewRenew();
            case DEALER_PERMISSION_LOAN_DETAIL_REPORT:
                return this.permissionService.canViewLoanDetailReport();
            case DEALER_PERMISSION_PAYOFF_QUOTE:
                return this.permissionService.canViewPayoffQuote();
            case DEALER_PERMISSION_LOAN_HISTORY:
                return this.permissionService.canViewLoanHistory();
            case DEALER_PERMISSION_DOCUMENT_INBOX:
                return this.permissionService.canViewDocumentInbox();
        }

        return this.permissionService.hasDealerPermission(actionType);
    }
}
