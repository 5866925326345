import { Subscription, timer } from 'rxjs';

export class Timer {
    private observable: any;
    private subscription: Subscription;
    private timeoutMilliseconds: number;
    private onComplete: any;

    constructor(timeoutSeconds?: number) {
        if (timeoutSeconds) {
            this.timeoutMilliseconds = timeoutSeconds * 1000;
        }
    }

    setOnComplete(onComplete: any): void {
        this.onComplete = onComplete;
    }

    start(timeoutSeconds?: number): void {
        if (timeoutSeconds) {
            this.timeoutMilliseconds = timeoutSeconds * 1000;
        }
        if (!this.timeoutMilliseconds) {
            throw Error('Attempt to start uninitialized timer.');
        }
        this.initializeTimerAndGo();
    }

    restart(): void {
        this.stop();
        this.initializeTimerAndGo();
    }

    stop(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private initializeTimerAndGo(): void {
        this.observable = timer(this.timeoutMilliseconds);
        this.subscription = this.observable.subscribe(() => {
            if (this.onComplete) {
                this.onComplete();
            }
        });
    }
}
