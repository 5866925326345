import { Component, OnInit } from '@angular/core';
import { StringDict } from 'if-angular-security/lib/models/string-dict.type';
import { CONFIG } from '../../../environments/environment';
import { IfAuthService } from 'if-angular-security';
import { IRedirectRequestParams } from 'if-angular-security/lib/models/redirect-request-params.interface';
import { ActivatedRoute } from '@angular/router';
import { DealerOnlineWebsiteName } from "../../shared/models/constants";

@Component({
  selector: 'app-update-password-login',
  templateUrl: './update-password-login.component.html',
  styleUrls: ['./update-password-login.component.css']
})
export class UpdatePasswordLoginComponent implements OnInit {

  constructor(private ifAuthSecurityService: IfAuthService,
    private route: ActivatedRoute
  ) 
  { }

  ngOnInit(): void {
    const queryParams: StringDict = {};

    this.route.queryParams.subscribe(routeParams => {
      const encodedEmail = encodeURIComponent(routeParams['email']);
      const encodedToken = encodeURIComponent(routeParams['token']);

      queryParams['email'] = encodedEmail;
      queryParams['token'] = encodedToken; 
      queryParams['app'] = DealerOnlineWebsiteName;
  
      const redirectRequest: IRedirectRequestParams = {
        policy : CONFIG.b2cUpdatePasswordFrame.policy,
        redirectUri: CONFIG.b2cUpdatePasswordFrame.redirectUri,
        extraQueryParameters: queryParams
      }
  
      this.ifAuthSecurityService.loginRedirect(redirectRequest);
    })
  }
}
