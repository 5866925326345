import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UpdatePasswordConfirmationComponent } from './update-password/update-password-confirmation/update-password-confirmation.component';

const routes = [
    { path: 'reset', component: ForgotPasswordComponent },
    { path: 'reset-password', component: UpdatePasswordComponent },
    { path: 'confirmation', component: UpdatePasswordConfirmationComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule
    ],
    declarations: [ForgotPasswordComponent, UpdatePasswordComponent, UpdatePasswordConfirmationComponent]
})
export class PasswordModule { }
