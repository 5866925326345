import * as moment from 'moment';

import { InterestRate } from './interest-rate.model';

export class RateStep {
    rateStepId: number;
    appliedOrder: number;
    expirationDate: moment.Moment;
    expirationDays: number;
    dealerRate: InterestRate;
    growerRate: InterestRate;
    targetRate: InterestRate;

    constructor(appData?: any) {
        if (appData) {
            this.rateStepId = appData.RateStepId ? appData.RateStepId : 0;
            this.appliedOrder = appData.AppliedOrder;
            this.expirationDate = appData.ExpirationDate ? moment(appData.ExpirationDate) : null;
            this.expirationDays = appData.ExpirationDays ? appData.ExpirationDays : null;
            this.dealerRate = appData.DealerRate ? new InterestRate(appData.DealerRate) : null;
            this.growerRate = appData.GrowerRate ? new InterestRate(appData.GrowerRate) : null;
            this.targetRate = appData.TargetRate ? new InterestRate(appData.TargetRate) : null;
        }
    }

}
