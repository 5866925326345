import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '../../../app/core/services/authorization/permission.service';
import { MessageCenterService } from '../../../app/core/services/message-center/message-center.service';
import { StateService } from '../../../app/core/services/state.service';
import { HeaderBarComponent as HeaderBarComponentV1} from '../../../app/core/header-bar/header-bar.component';
import { AuthService} from '../../../app/core/services/authentication/auth.service';
import { AuthService as AuthServiceV2 } from '../services/authentication/auth.service';

@Component({
  selector: 'dol-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['../../../app/core/header-bar/header-bar.component.css']
})
export class HeaderBarComponent extends HeaderBarComponentV1 {

  constructor( //TODO:MFA
    private authService1: AuthService,
    private messageService1: MessageCenterService,
    protected permissionService1: PermissionService,
    private router1: Router,
    private stateService1: StateService,
    private authServiceV2: AuthServiceV2) {
    super(authService1, messageService1, permissionService1, router1, stateService1);
  }

  logout() { //TODO:MFA
    this.stateService1.clear();
    this.messageService1.clearMessages();
    this.authServiceV2.logout();
  }
}
