<div class="stage container">
    <form class="form-wrapper raised-card" [formGroup]="form" (ngSubmit)="submit(form)">
        <h2>Password Reset</h2>
        <div class="question">What is your email address?</div>
        <div *ngIf="invalidFormMessage" class="error form-error">{{invalidFormMessage}}</div>
        <mat-form-field>
            <input matInput placeholder="Email" formControlName="email" autofocus #email>
        </mat-form-field>
        <ul *ngIf="submitted && form.invalid" class="error">
            <li *ngIf="form.get('email').hasError('required')">An email address is required.</li>
            <li *ngIf="form.get('email').value && form.get('email').hasError('validateEmail')">
                {{form.get('email').value}} is not a valid email address.</li>
        </ul>
        <div class="form-controls">
            <button mat-button type="button" class="btn-default" (click)="goBack()">Back</button>
            <button mat-raised-button type="submit" class="btn-brand">Reset</button>
        </div>
    </form>
</div>
<dol-modal-dialog [show]="showConfirmation" (close)="showConfirmation = false">
    <div class="modal-body">
        <h1>We sent you an e-mail.</h1>
        <p>Please check your inbox for further instructions.</p>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" class="btn btn-brand" (click)="showConfirmation = false">Ok</button>
    </div>
</dol-modal-dialog>