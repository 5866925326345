import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router
} from '@angular/router';

import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class HasLoginPropertyGuard implements CanActivate {
    constructor(private loginService: LoginService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const requiredProperties = route.data['requiredProperties'];
        const hasRequiredProperties = requiredProperties
            .map(requiredProperty => !!this.loginService[requiredProperty])
            .reduce((prev, curr) => prev && curr);

        if (!hasRequiredProperties) {
            this.router.navigate(['/login']);
        }

        return hasRequiredProperties;
    }
}
