import * as moment from 'moment';

import { AssignedDealerPermission } from '../../shared/models/assigned-dealer-permission.model';
import { UserPreferences } from '../../shared/models/user-preferences.model';

export class User {
    userID: string;
    firstName: string;
    lastName: string;
    userName: string;
    fullName: string;
    authToken: string;
    refreshToken: string;
    expires: moment.Moment;
    permissions: Array<string>;
    roles: Array<string>;
    acceptedEula: boolean;
    securityImageId: number;
    trusteerOptOut: boolean;
    isInternal: boolean;
    preferences: UserPreferences = new UserPreferences();
    assignedDealers: Array<AssignedDealerPermission>;

    constructor(token) {
        if (token) {
            this.userID = token.userID;
            this.firstName = token.firstName;
            this.lastName = token.lastName;
            this.userName = token.userName;
            this.authToken = token.access_token != null ? token.access_token : token.authToken;
            this.refreshToken = token.refresh_token != null ? token.refresh_token : token.refreshToken;
            this.expires = moment(token['.expires'] != null ? new Date(token['.expires']) : token.expires);
            this.permissions = token.permissions == null ? null : token.permissions instanceof Array
                ? token.permissions
                : JSON.parse(token.permissions);
            this.roles = token.roles == null ? null : token.roles instanceof Array ? token.roles : JSON.parse(token.roles);
            this.acceptedEula = token.acceptedEula;
            this.securityImageId = token.securityImageId;
            this.trusteerOptOut = token.trusteerOptOut;
            this.isInternal = token.isInternal;

            const rawCustomReports = token.customReports ? JSON.parse(token.customReports) : null;
            if (rawCustomReports) {
                this.preferences = new UserPreferences({ CustomReports: rawCustomReports });
            }

            this.assignedDealers = token.assignedDealers;
        }
    }

    updateAuthorization(user: User) {
        if (user) {
            this.authToken = user.authToken;
            this.refreshToken = user.refreshToken;
            this.expires = user.expires;
            this.permissions = user.permissions;
            this.roles = user.roles;
        }
    }

    getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    isAuthExpired(): boolean {
        return this.expires <= moment();
    }

    hasAssignedDealers(): boolean {
        return (this.assignedDealers && this.assignedDealers.length > 0);
    }

    getUniqueAssignedDealers(): Array<AssignedDealerPermission> {
        if (!this.assignedDealers || this.assignedDealers.length < 2) {
            return this.assignedDealers;
        }

        return this.assignedDealers.reduce(function (prev, current) {
            if (!prev.some(function (existing) { return existing.assignedDealerId === current.assignedDealerId; })) {
                prev.push(current);
            }

            return prev;
        }, []);
    }
}
