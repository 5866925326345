import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NotificationService } from 'if-angular-core';
import { StateService } from '../state.service';
import { StorageService } from '../storage.service';
import { AuthService } from './auth.service';
import { AuthServiceV2} from '../../../../appshared/public-api';
import { AppVersionHelper } from '../../../../version-helper';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedHttp {
    constructor(
        private auth: AuthService,
        private authv2: AuthServiceV2,
        private http: HttpClient,
        private notificationService: NotificationService,
        private stateService: StateService,
        private storageService: StorageService) { }

    post<T>(url: string, body?: any, options?: any, refreshToken: boolean = true): Observable<any> {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers, refreshToken);
        return this.http.post<T>(url, body, options);
    }

    get<T>(url: string, options?: any, refreshToken: boolean = true): Observable<any> {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers, refreshToken);
        return this.http.get<T>(url, options);
    }

    delete<T>(url: string, options?: any, refreshToken: boolean = true): Observable<any> {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers, refreshToken);
        return this.http.delete<T>(url, options);
    }

    private createAuthorizationHeader(headers: HttpHeaders, refreshToken: boolean): HttpHeaders {
        headers = headers || new HttpHeaders();
        const user = this.stateService.currentUser;

        if (user) {
            if (user.isAuthExpired()) {
                this.stateService.clear();
                this.stateService.deleteUser();
                this.storageService.clearSession();
                console.log('Authorization expired');
            } else {
                headers = headers.set('Authorization', 'Bearer ' + user.authToken);

                if (refreshToken) {                    
                    //TODO:MFA
                    const errorHandler = (err: HttpErrorResponse | Error) => {
                        const reason = (err instanceof HttpErrorResponse) ? err.error : err;
                        console.log('Could not refresh token in the Authorization Header:', reason);
                        this.notificationService.push(
                            {
                                severity: 'error',
                                summary: 'An error occurred',
                                detail: 'An error occurred while refreshing the token.'
                            });
                    };

                    if (AppVersionHelper.isVersion2()) {
                        this.authv2.refreshToken().subscribe(() => { }, errorHandler);
                    } else {
                        this.auth.refreshToken().subscribe(() => { }, errorHandler);
                    }            
                }
            }
        }
        return headers;
    }
}
