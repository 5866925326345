import { IDocument } from '../../models/document.interface';

export class DocumentState {
    document: IDocument;
    pendingDelete: boolean;
    pendingDownload: boolean;

    constructor(props?: any) {
        if (props) {
            this.document = props.document;
        }
    }

    get pendingAction(): boolean {
        return this.pendingDelete || this.pendingDownload;
    }
}
