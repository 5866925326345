import { DealerBase } from './dealer-base.model';

export class DealerLocationPermission {
    dealerLocationPermissionId: number;
    permission: string;
    dealer: DealerBase;
    childrenDealerIds: string[];

    constructor(appData?: any) {
        if (appData) {
            this.dealerLocationPermissionId = appData.DealerLocationPermissionId;
            this.permission = appData.Permission;
            this.dealer = appData.Dealer ? new DealerBase(appData.Dealer) : null;
            this.childrenDealerIds = appData.ChildrenDealerIds ? appData.ChildrenDealerIds : null;
        }
    }
}
