import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(val) {
        if (val) {
            val = val.replace(/<[^<>]*\/script[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*script[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*onshow[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*onload[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*onclick[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*ondblclick[^<>]*>/gi, '');
            val = val.replace(/<[^<>]*onmouse[^<>]*>/gi, '');
            return this.sanitizer.bypassSecurityTrustHtml(val);
        } else {
            return null;
        }

    }
}
