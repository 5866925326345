import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dol-year-input',
    template: `
        <input type="text" class="form-control" [value]="value" (input)="inputUpdate($event)" placeholder="0000" [disabled]="disabled">
    `,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: YearInputComponent }
    ]
})
export class YearInputComponent implements ControlValueAccessor, OnInit {
    @Input() default = '';
    @Input() disabled = false;
    value = '';
    _onChange: (value: any) => void;

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    ngOnInit() {
        if (this.default !== '') {
            this.value = this.default;
        }
    }

    inputUpdate($event: any): void {
        const candidate = this.cleanInput($event.target.value);

        $event.target.value = candidate;

        if (candidate === '') {
            this.value = null;
        } else {
            this.value = this.parseCandidate(candidate.toString());
        }

        if (this.value !== $event.target.value) {
            $event.target.value = this.value;
        }

        this._onChange(this.value);
    }

    cleanInput(input: string): string {
        const illegalChars = /[^\d]/gi;
        return input.replace(illegalChars, '');
    }

    parseCandidate(candidate: string): string {
        if (candidate === '.') {
            return candidate;
        }

        const decimalLike = '(\\d{1,4})?';
        const match = candidate.match(decimalLike);

        return match[0];

    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }
}
