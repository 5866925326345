import { Injectable } from '@angular/core';

import { RenewalSetup } from '../../renewals/models/renewal-setup.model';
import { AssignedDealerPermission } from '../../shared/models/assigned-dealer-permission.model';
import { Dealer } from '../../shared/models/dealer.model';
import { MyCustomerResult } from '../../shared/models/my-customer-result.model';
import { ProgramQueryParameter } from '../../shared/models/program-query-parameter.model';
import { TransactionHistory } from '../../shared/models/transaction-history.model';
import { User } from '../../shared/models/user.model';
import { StorageService } from './storage.service';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    trusteerScriptsInitialized = false;
    programSearchParameters: ProgramQueryParameter;
    renewalSetup: RenewalSetup;

    //TODO:MFA
    get redirectUrl(): string {
        return this.storageService.getFromSession('redirectUrl');
    }
    set redirectUrl(url: string) {
        this.storageService.storeInSession('redirectUrl', url);
    }

    dealer: State<Dealer> = {
        current: null,
        query: null,
        results: []
    };

    customer: State<MyCustomerResult> = {
        current: null,
        query: null,
        results: []
    };

    currentUser: User = this.storageService.loadUser('loggedInUser');
    private _assignedDealer: AssignedDealerPermission;

    transactionHistory: Array<TransactionHistory> = null;
    transactionHistoryEmailErrorMessage: string = null;

    constructor(private storageService: StorageService, private utilityService: UtilityService) { }

    saveUser(user: User): void {
        this.currentUser = user;
        this.storageService.storeInSession('loggedInUser', JSON.stringify(user));
    }

    deleteUser(): void {
        this.currentUser = null;
        this.storageService.deleteFromSession('loggedInUser');
    }

    get assignedDealer(): AssignedDealerPermission {
        if (this._assignedDealer && !this.utilityService.isNullOrEmpty(this._assignedDealer)) { return this._assignedDealer; }
        const user = this.currentUser;
        const assignedDealer = (user.assignedDealers && user.assignedDealers.length === 1)
            ? user.assignedDealers[0]
            : null;
        this._assignedDealer = assignedDealer;

        return this._assignedDealer;
    }

    set assignedDealer(newAssignedDealer) {
        this._assignedDealer = newAssignedDealer;
    }

    getCurrentDealerId(): number {
        const assignedDealer = this.assignedDealer;
        return (assignedDealer && assignedDealer.dealer) ? assignedDealer.dealer.rowId : null;
    }

    getCurrentDealerIds(): Array<number> {
        const dealerId = this.getCurrentDealerId();
        if (dealerId) { return [dealerId]; }
        if (this.currentUser && this.currentUser.assignedDealers) {
            return this.currentUser.assignedDealers.map(ad => ad.dealer.rowId);
        }
        return null;
    }

    getCurrentDealerNumber(): string {
        const assignedDealer = this.assignedDealer;
        return (assignedDealer && assignedDealer.dealer) ? assignedDealer.dealer.dealerNumber : null;
    }

    clear() {
        this.dealer.current = null;
        this.dealer.query = null;
        this.dealer.results = [];

        this.customer.current = null;
        this.customer.query = null;
        this.customer.results = [];

        this.transactionHistory = null;

        this.programSearchParameters = null;
    }
}

interface State<T> {
    current: T;
    query: any;
    results: Array<T>;
}
