import { Injectable } from '@angular/core';

import { User } from '../../shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    storeInSession(key: string, payload: string): void {
        window.sessionStorage.setItem(key, payload);
    }

    storeObjectInSession(key: string, payload: any): void { //TODO:MFA
        const value = JSON.stringify(payload);
        this.storeInSession(key, value);
    }

    loadUser(key: string): User {
        const rawUser = this.getFromSession(key);
        return (rawUser) ? new User(JSON.parse(rawUser)) : null;
    }

    getFromSession(key: string): string {
        return window.sessionStorage.getItem(key);
    }

    getObjectFromSession(key: string): any { //TODO:MFA
        return JSON.parse(this.getFromSession(key));
    }

    deleteFromSession(key: string): void {
        window.sessionStorage.removeItem(key);
    }

    clearSession(): void {
        window.sessionStorage.clear();
    }
}
