import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AccountService } from '../../core/services/account.service';
import { AuthService } from '../../core/services/authentication/auth.service';
import { NotificationService } from 'if-angular-core';
import { LoginService } from '../services/login.service';

@Component({
    selector: 'dol-eula-form',
    templateUrl: './eula-form.component.html',
    styleUrls: ['./eula-form.component.css']
})
export class EulaFormComponent implements OnInit {
    loading: boolean;
    disableControls: boolean;

    constructor(
        private accountService: AccountService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private loginService: LoginService,
        private router: Router) { }

    ngOnInit() {
        window.scrollTo(0, 0);
    }

    updateEula(acceptEula: boolean) {
        this.loading = true;
        this.disableControls = true;

        this.accountService.updateEula(acceptEula)
            .pipe(
                finalize(() => { this.loading = false; })
            )
            .subscribe(() => {
                if (acceptEula) {
                    this.notificationService.push({ severity: 'success', summary: 'Success', detail: 'You have accepted the EULA.' });
                    this.loginService.user.acceptedEula = true;
                    this.authService.saveUser(this.loginService.user);
                    this.loginService.getAssignedDealers();
                } else {
                    this.notificationService.push(
                        {
                            severity: 'warning',
                            summary: 'Success',
                            detail: 'You have declined the EULA, you are being logged out.'
                        });
                    this.authService.logout();
                    this.router.navigate(['/login']);
                }
            }, (err: HttpErrorResponse) => {
                console.log(err);
                this.notificationService.push(
                    {
                        severity: 'error',
                        summary: 'An error occurred',
                        detail: 'Your response was not recorded.'
                    });
                this.disableControls = false;
            });
    }
}
