import * as moment from 'moment';

import { CreditLine } from './credit-line.model';

export class MasterCommitment {
    subcommitments: Array<any>;
    principalDue: number;
    interestDue: number;
    creditAvailable: number;
    pastDue: number;
    drawUsed: number;
    drawAvailable: number;
    transactionsUsed: number;
    transactionsRemaining: number;
    creditLine: CreditLine;
    maturityDate: moment.Moment;

    constructor(appData?: any) {
        if (appData) {
            this.subcommitments = appData.Subcommitments;
            this.principalDue = appData.PrincipalDue;
            this.interestDue = appData.InterestDue;
            this.creditAvailable = appData.CreditAvailable;
            this.pastDue = appData.PastDue;
            this.drawUsed = appData.DrawUsed;
            this.drawAvailable = appData.DrawAvailable;
            this.transactionsUsed = appData.TransactionsUsed;
            this.transactionsRemaining = appData.TransactionsRemaining;
            this.creditLine = new CreditLine(appData.CreditLine);
            this.maturityDate = appData.MaturityDate ? moment(appData.MaturityDate) : null;
        }
    }
}
