import * as moment from 'moment';

import { PurchaseGroup } from './purchase-group.model';

export class GrowerDiscount {
    growerDiscountId: string;
    programId: string;
    billingFrequency: string;
    discountDate: moment.Moment;
    percent: number;
    fixedAmount: number;
    requiredProducts: PurchaseGroup;
    minimumPurchaseAmount: number;

    constructor(appData?: any) {
        if (appData) {
            this.growerDiscountId = appData.GrowerDiscountId;
            this.programId = appData.ProgramId;
            this.billingFrequency = appData.BillingFrequency;
            this.discountDate = appData.DiscountDate ? moment(appData.DiscountDate) : null;
            this.percent = appData.Percent;
            this.fixedAmount = appData.FixedAmount;
            this.requiredProducts
                = (appData.RequiredProducts
                    && appData.RequiredProducts.PurchaseGroups
                    && appData.RequiredProducts.PurchaseGroups.length > 0)
                    ? new PurchaseGroup(appData.RequiredProducts)
                    : null;
            this.minimumPurchaseAmount = appData.MinimumPurchaseAmount;
        }
    }
}
