import { SecurityQuestion } from '../../shared/models/security-question.model';

export class SecurityChallengeUpdates {
    email: string;
    password: string;
    securityImageId: number;
    securityQuestions: Array<SecurityQuestion>;

    constructor(props?: any) {
        if (props) {
            this.email = props.email;
            this.password = props.password;
            this.securityImageId = props.securityImageId;
            this.securityQuestions = props.securityQuestions;
        }
    }
}
