import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CONFIG } from '../../../environments/environment';
import { AssignedDealers } from '../../shared/models/assigned-dealers.model';
import { CountSummary } from '../../shared/models/data/count-summary.model';
import { PagedData } from '../../shared/models/data/paged-data.interface';
import { Dealer } from '../../shared/models/dealer.model';
import { DealerBase } from '../../shared/models/dealer-base.model';
import { DealerContactInformation } from '../../shared/models/dealer-contact-information.model';
import { DealerHeader } from '../../shared/models/dealer-header.model';
import { DealerLocationPermission } from '../../shared/models/dealer-location-permission.model';
import { DealerLocationPermissionBindingModel } from '../../shared/models/dealer-location-permission-binding.model';
import { DealerSettings } from '../../shared/models/dealer-settings.model';
import { DealerQueryParameter } from '../../shared/models/dealer-query-parameter.model';
import { ParticipatingTag } from '../../shared/models/participating-tag.model';
import { TransactionLimit } from '../../shared/models/transaction-limit.model';
import { UpdateDealerTagBindingModel } from '../../shared/models/update-dealer-tag-binding.model';
import { AuthenticatedHttp } from './authentication/authenticated-http';

@Injectable({
    providedIn: 'root'
})
export class DealerService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: AuthenticatedHttp) { }

    searchDealers(query: DealerQueryParameter): Observable<DealerBase[]> {
        const url = `${CONFIG.apiBaseUrl}dealer/find`;

        return this.http.post<DealerBase[]>(url, query)
            .pipe(
                map((res) => {
                    const results = Array.from(res, data => query.returnFullObject ? new Dealer(data) : new DealerBase(data));
                    return results;
                })
            );
    }

    getDealer(rowId: number): Observable<Dealer> {
        const url = `${CONFIG.apiBaseUrl}dealer/${rowId}`;

        return this.http.get<Dealer>(url)
            .pipe(
                map((res) => {
                    return res ? new Dealer(res) : null;
                }),
                catchError((err) => {
                    return throwError(err);
                })

            );
    }

    getContactInfo(dealerId: number): Observable<DealerContactInformation> {
        const url = `${CONFIG.apiBaseUrl}dealer/contactinfo/${dealerId}`;

        return this.http.get<DealerContactInformation>(url)
            .pipe(
                map((res) => {
                    return res ? new DealerContactInformation(res) : null;
                })
            );
    }

    getDealerLocationPermissions(dealerId: number): Observable<DealerLocationPermission[]> {
        const url = `${CONFIG.apiBaseUrl}dealer/LocationPermission/${dealerId}`;

        return this.http.get<DealerLocationPermission[]>(url)
            .pipe(
                map((res) => {
                    return res ? res.map(d => new DealerLocationPermission(d)) : null;
                })
            );
    }

    saveDealerLocationPermissions(
        dealerId: number,
        dealerLocationPermissionBindingModel: DealerLocationPermissionBindingModel): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/LocationPermission/${dealerId}`;

        return this.http.post(url, dealerLocationPermissionBindingModel, { responseType: 'text' });
    }

    getDealerSettings(dealerId: number): Observable<DealerSettings> {
        const url = `${CONFIG.apiBaseUrl}dealer/Setting/${dealerId}`;

        return this.http.get<DealerSettings>(url)
            .pipe(
                map((res) => {
                    return res ? new DealerSettings(res) : null;
                })
            );
    }

    saveDealerSettings(dealerId: number, dealerSetting: DealerSettings): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/Setting/${dealerId}`;

        return this.http.post(url, dealerSetting, { responseType: 'text' });
    }

    getDealerTags(dealerId: number): Observable<Array<string>> {
        const url = `${CONFIG.apiBaseUrl}dealer/tags/${dealerId}`;

        return this.http.get<Array<string>>(CONFIG.apiBaseUrl + 'dealer/tags/' + dealerId);
    }

    getExistingTags(): Observable<Array<string>> {
        const url = `${CONFIG.apiBaseUrl}dealer/tags/existing`;

        return this.http.get<Array<string>>(url);
    }

    saveDealerTags(dealerId: number, tagUpdates: UpdateDealerTagBindingModel): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/tags/${dealerId}`;

        return this.http.post(url, tagUpdates, { responseType: 'text' });
    }

    editDealer(dealer: any): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/edit`;

        return this.http.post(`${CONFIG.apiBaseUrl}dealer/edit`, dealer, { responseType: 'text' });
    }

    disableDealers(dealerIds: Array<number>): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/status/disable`;

        return this.http.post(url, dealerIds, { responseType: 'text' });
    }

    enableDealers(dealerIds: Array<number>): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/status/enable`;

        return this.http.post(url, dealerIds, { responseType: 'text' });
    }

    addComment(dealerId: number, text: string): Observable<number> {
        const url = `${CONFIG.apiBaseUrl}dealer/${dealerId}/comments`;

        return this.http.post<number>(url, JSON.stringify(text), { headers: this.headers });
    }

    deleteComment(dealerId: number, commentId: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/${dealerId}/comments/${commentId}`;

        return this.http.delete(url, { responseType: 'text' });
    }

    updateTransactionLimit(dealerId: number, transactionLimit: TransactionLimit): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/${dealerId}/transactionLimit`;

        return this.http.post(url, transactionLimit, { responseType: 'text' });
    }

    screenDealer(dealerId: number): Observable<void> {
        const url = `${CONFIG.apiBaseUrl}dealer/${dealerId}/screen`;

        return this.http.post(url, {responseType: 'text'});
    }
}
