import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../../environments/environment';
import { Credentials } from '../../../../app/login/models/credentials.model';
import { User } from '../../../../app/shared/models/user.model';
import { IdleService } from '../../../../app/core/services/idle/idle.service';
import { StateService } from '../../../../app/core/services/state.service';
import { StorageService } from '../../../../app/core/services/storage.service';
import { IUserLoginData } from 'if-angular-security/lib/models/user-login-data.interface';
import { MsalService } from '@azure/msal-angular';
import { IfAuthService } from 'if-angular-security';
import { DealerUserSecurityProfile } from '../../../../app/shared/models/dealer-user-security-profile.model';
import { UserPreferences } from '../../../../app/shared/models/user-preferences.model';
import { AppVersionHelper } from '../../../../version-helper';
import { IPostLoginOperationModel } from '../../../login/models/post-login-operation.model';
import { EndSessionRequest } from '@azure/msal-browser';
import { StringDict } from 'if-angular-security/lib/models/string-dict.type';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private readonly PostLoginOperationStorageKey: string = 'PostLoginOperations';

    constructor(
        private http: HttpClient,
        private idleService: IdleService,
        private stateService: StateService,
        private storageService: StorageService,
        private msalService: MsalService,
        private ifAuthService: IfAuthService) {
            this.onUserLogin = this.onUserLogin.bind(this); // bind to so that this works as callback function
            this.loggedIn = this.loggedIn.bind(this);
    }

    loggedIn(skipEula: boolean = false): boolean {
        const user = this.stateService.currentUser;
        return user && user.refreshToken && user.assignedDealers && !user.isAuthExpired() && (skipEula || user.acceptedEula);
    }

    login(credentials: Credentials): Observable<User> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/DealerLogin';

        return this.http.post<User>(apiUrl, credentials, { headers: this.headers })
            .pipe(
                map((res) => {
                    const user = new User(res);
                    this.saveUser(user);
                    return user;
                })
            );
    }

    logout(request?: EndSessionRequest): void {
        this.stateService.deleteUser();
        this.idleService.stop();
        this.storageService.clearSession();

        // We need to specify the client id in order to have an application scope for B2C policies
        const params: StringDict = { 'client_id': CONFIG.b2cConfig.clientId };
        if (request) {
            request.extraQueryParameters = params;
            this.msalService.logoutRedirect(request);
        } else {
            this.msalService.logoutRedirect({
                extraQueryParameters: params
            });
        }
    }

    refreshToken(): Observable<any> {
        const user = this.stateService.currentUser;

        if (!user || !user.refreshToken) {
            throwError(new Error('Missing user or refresh token'));
        }

        // TODO:MFA
        return this.ifAuthService.getAccessToken().pipe(map((userData) => {
            this.onTokenRefresh(userData);
            return userData;
        }));
    }

    requestPasswordReset(email: string): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'v2/Account/InitiatePasswordReset';
        const body = { email: email, websiteName: 'DealerOnline' };

        return this.http.post(apiUrl, body, { responseType: 'text', headers: this.headers });
    }

    requestSecurityQuestionReset(email: string): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/InitiateSecurityQuestionReset';
        const body = { email: email, websiteName: 'DealerOnline' };

        return this.http.post(apiUrl, body, { responseType: 'text', headers: this.headers });
    }

    resetPassword(requestDetails: any): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/ResetPassword';

        return this.http.post(apiUrl, requestDetails, { responseType: 'text', headers: this.headers });
    }

    updateUser(userData: any): void {
        const user = this.stateService.currentUser;
        const newUser = new User(userData);
        user.updateAuthorization(newUser);
        this.saveUser(user);
    }

    // TODO:MFA
    // NOTE : On login, we are likely inside a frame; os, use window.top to navigate to destination screen
    onUserLogin(userData: IUserLoginData): void {
        // Embedded custom policies can trigger a redirect event which calls this method as a callback
        // In these cases, the user is already logged in and should not be redirected away from the current page
        const shouldRedirect = !this.loggedIn();
        this.processUserLoginData(userData).subscribe(user => {
            this.stateService.assignedDealer = null;

            const shouldActivateTrusteer = user.acceptedEula ? !user.trusteerOptOut : false;
            const postLoginOperations: IPostLoginOperationModel = {
                shouldActivateTrusteer : shouldActivateTrusteer
            };
            this.storageService.storeObjectInSession(this.PostLoginOperationStorageKey, postLoginOperations);

            if (!user.acceptedEula) {
                window.top.location.replace(AppVersionHelper.versionRoutePrefix + '/login/eula');
            } else if (shouldRedirect) {
                this.redirectPostLogin();
            }
        });
    }

    // Return post login operation data and clear out the data
    public popPostLoginOperationData(): IPostLoginOperationModel {
        const postLoginOperations = this.storageService.getObjectFromSession(this.PostLoginOperationStorageKey);
        this.storageService.deleteFromSession(this.PostLoginOperationStorageKey);
        return postLoginOperations;
    }

    onTokenRefresh(userData: IUserLoginData): void {
        this.processUserLoginData(userData).subscribe();
    }

    private processUserLoginData(tokenData: IUserLoginData): Observable<User> {
        return this.getDealerUserSecurityProfile(tokenData)
            .pipe(map(profile => {
                const user = this.buildUserObject(tokenData, profile);
                this.saveUser(user);
                return user;
            }));
    }

    public redirectPostLogin() {
        const redirect = this.stateService.redirectUrl ? this.stateService.redirectUrl : AppVersionHelper.versionRoutePrefix + '/customers';
        this.stateService.redirectUrl = '';

        window.top.location.replace(redirect);
    }
    // </MFA:TODO>

    saveUser(user: User): void {
        const duration = moment.duration(user.expires.diff(moment()));
        this.idleService.start(duration.asSeconds());
        this.stateService.saveUser(user);
    }

    public GetLastLoginMessage(username: string): Observable<any> {
        const data = { Email: username };
        const apiUrl = CONFIG.apiBaseUrl + 'Account';

        const browserDate = new Date();
        // javascript treats the offset direction the opposite way that dotnet does.
        const offsetMinutes = -1 * browserDate.getTimezoneOffset();

        return this.http
        .get(apiUrl + `/GetLastLoginMessage/?email=${username}&utcOffsetMinutes=${offsetMinutes}`, { headers: this.headers });
    }

    private getDealerUserSecurityProfile(tokenData: IUserLoginData): Observable<DealerUserSecurityProfile> {
        const apiUrl = CONFIG.apiBaseUrl + 'DealerUserSecurityProfile';
        this.setHeadersAuthorization(tokenData);
        return this.http.get<DealerUserSecurityProfile>(apiUrl, { headers: this.headers, observe: 'response' })
        .pipe(
            map((res) => {
                return res ? new DealerUserSecurityProfile(res.body) : null;
            })
        );
    }

    private setHeadersAuthorization(tokenData: IUserLoginData) {
        this.headers = this.headers.set('Authorization', 'Bearer ' + tokenData.accessToken);
    }

    private buildUserObject(tokenData: IUserLoginData, profile: DealerUserSecurityProfile): User {
        const user = new User(null);

        user.authToken = tokenData.accessToken;
        user.userName = tokenData.userName;
        user.expires = moment(tokenData.expires);

        // We are not really doing much with the refreshToken, but it still needs to be populated to avoid crashes
        user.refreshToken = tokenData.accessToken;

        if (profile) {
            user.userID = profile.id;
            user.firstName = profile.firstName;
            user.lastName = profile.lastName;
            user.permissions = profile.permissions;
            user.roles = profile.roles;
            user.acceptedEula = profile.acceptedEula;
            user.securityImageId = profile.securityImageId;
            user.trusteerOptOut = profile.trusteerOptOut;
            user.isInternal = profile.isInternal;
            user.assignedDealers = profile.assignedDealers.dealers;

            const rawCustomReports = profile.customReports;
            if (rawCustomReports) {
                user.preferences = new UserPreferences({ CustomReports: rawCustomReports });
            }
        }

        return user;
    }
}
