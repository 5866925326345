import * as moment from 'moment';

export class CreditLine {
    creditLineId: number;
    creditLimit: number;
    maxNumberOfDraws: number;
    drawLimit: number;
    maxDrawAmount: number;
    minDrawAmount: number;
    drawUntilDate: moment.Moment;
    maturityDate: moment.Moment;
    rowId: number;
    commitmentNumber: string;

    constructor(appData?: any) {
        if (appData) {
            this.creditLineId = appData.CreditLineId;
            this.creditLimit = appData.CreditLimit;
            this.maxNumberOfDraws = appData.MaxNumberOfDraws;
            this.drawLimit = appData.DrawLimit;
            this.maxDrawAmount = appData.MaxDrawAmount;
            this.minDrawAmount = appData.MinDrawAmount;
            this.drawUntilDate = appData.DrawUntilDate ? moment.utc(appData.DrawUntilDate) : null;
            this.maturityDate = appData.MaturityDate ? moment.utc(appData.MaturityDate) : null;
        }
    }
}
