import { Component, Input } from '@angular/core';

import { SecurityImageBasePath, SecurityImages } from '../data/security-images';
import { SecurityImage } from '../models/security-image.model';

@Component({
    selector: 'dol-security-image-preview',
    templateUrl: './security-image-preview.component.html',
    styleUrls: ['./security-image-preview.component.css']
})
export class SecurityImagePreviewComponent {
    securityImageUri: string;
    @Input() set id(id: number) {
        if (id !== null) {
            const currentSecurityImage = this.findSecurityImageById(id);
            if (currentSecurityImage) {
                this.securityImageUri = SecurityImageBasePath + currentSecurityImage.uri;
            }
        } else {
            this.securityImageUri = null;
        }
    }

    private findSecurityImageById(id: number): SecurityImage {
        const queryResults = SecurityImages.filter(securityImage => securityImage.id === id);
        return (queryResults && queryResults.length === 1) ? queryResults[0] : null;
    }
}
