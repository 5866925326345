import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from 'if-angular-core';
import { Observable, Subject } from 'rxjs';
import { finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { ProgramService } from '../../../core/services/program.service';
import { UtilityService } from '../../../core/services/utility.service';
import { ProgramHeader } from '../../../shared/models/program-header.model';


@Component({
    selector: 'dol-participating-programs',
    templateUrl: './participating-programs.component.html',
    styleUrls: ['./participating-programs.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ParticipatingProgramsComponent }
    ]
})
export class ParticipatingProgramsComponent implements OnInit, ControlValueAccessor, OnDestroy {
    _onChange: (_: any) => void;
    loading: boolean;
    programHeaders: Array<ProgramHeader> = [];
    filteredProgramHeaders: Observable<ProgramHeader[]>;
    programSearch: FormControl = new FormControl();
    selectedProgramHeaders: Array<ProgramHeader> = [];
    disabled = false;
    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private programService: ProgramService,
        private notificationService: NotificationService,
        private utilityService: UtilityService) { }

    ngOnInit() {
        this.initializeOptions();

        this.filteredProgramHeaders = this.programSearch.valueChanges
            .pipe(
                takeUntil(this.unsubscribe),
                // tslint:disable-next-line: deprecation  https://github.com/ReactiveX/rxjs/issues/4772
                startWith(null),
                map((query: string | ProgramHeader) => {
                    if (query instanceof ProgramHeader) {
                        return [query];
                    } else if (query) {
                        return this.programHeaders
                            .filter(programHeader => programHeader.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
                    } else {
                        return this.programHeaders.slice();
                    }
                })
            );
    }

    writeValue(programHeaders: Array<ProgramHeader>) {
        this.selectedProgramHeaders = programHeaders ? programHeaders : [];
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.programSearch.disable();
        } else {
            this.programSearch.enable();
        }
    }

    displayFn(programHeader: ProgramHeader) {
        return programHeader ? programHeader.name : programHeader;
    }

    selectProgramHeader(programHeader: ProgramHeader) {
        if (programHeader instanceof ProgramHeader) {
            if (this.selectedProgramHeaders.indexOf(programHeader) === -1) {
                this.selectedProgramHeaders.push(programHeader);
            }
            this.programSearch.reset();
            this._onChange(this.selectedProgramHeaders);
        }
    }

    deselectProgramHeader(index: number) {
        this.selectedProgramHeaders.splice(index, 1);
        this._onChange(this.selectedProgramHeaders);
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched() { }

    private initializeOptions() {
        this.loading = true;
        this.programService.getPublicPrograms()
            .pipe(
                finalize(() => { this.loading = false; })
            )
            .subscribe(programHeaders => {
                this.programHeaders = programHeaders;
                this.utilityService.orderBy(this.programHeaders, true, 'name');
                this.programSearch.updateValueAndValidity();
            }, err => {
                console.log('error', err);
                this.notificationService.push(
                    {
                        severity: 'error',
                        summary: 'An error occurred',
                        detail: 'An error occurred while retrieving programs'
                    });
            });
    }

}
