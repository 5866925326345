import * as moment from 'moment';

import { DealerHeader } from './dealer-header.model';
import { TransactionLimit } from './transaction-limit.model';

export class ParticipatingDealer {
    participatingDealerId: number;
    startDate: moment.Moment;
    endDate: moment.Moment;
    dealer: DealerHeader = new DealerHeader();
    participantType = 'Included';
    transactionLimit: TransactionLimit;

    constructor(appData?: any) {
        if (appData) {
            this.participatingDealerId = appData.ParticipatingDealerId ? appData.ParticipatingDealerId : 0;
            this.startDate = appData.StartDate ? moment(appData.StartDate) : null;
            this.endDate = appData.EndDate ? moment(appData.EndDate) : null;
            this.dealer = appData.Dealer ? new DealerHeader(appData.Dealer) : null;
            this.participantType = appData.ParticipantType;
            this.transactionLimit = appData.TransactionLimit ? new TransactionLimit(appData.TransactionLimit) : null;
        }
    }
}
