import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'dol-image-picker',
    templateUrl: './image-picker.component.html',
    styleUrls: ['./image-picker.component.css']
})
export class ImagePickerComponent {
    @Input() selectedIndex: number;
    @Input() imageUris: Array<string>;
    @Output() selection: EventEmitter<number> = new EventEmitter();

    selectImage(index: number) {
        this.selectedIndex = (index === this.selectedIndex) ? null : index;
        this.selection.emit(this.selectedIndex);
    }
}
