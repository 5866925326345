import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'dol-decimal-input',
    templateUrl: './decimal-input.component.html',
    styleUrls: ['./decimal-input.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DecimalInputComponent }
    ]
})
export class DecimalInputComponent implements ControlValueAccessor {
    @Input() disabled = false;
    @Input() decimalplaces: number;
    @Input() id: string;
    value = '';
    _onChange: (value: any) => void;
    @ViewChild('input', { static: true }) inputElement;

    constructor(private renderer: Renderer2) { }

    writeValue(value: any) {
        if (value !== undefined) {
            this.value = value;
            this.renderer.setProperty(this.inputElement.nativeElement, 'value', value);
        }
    }

    clear() {
        this.value = '';
        this._onChange(this.value);
    }

    getPlaceholder(): string {
        return '0.' + '0'.repeat(this.decimalplaces);
    }

    inputUpdate($event: any): void {
        const candidate = this.cleanInput($event.target.value);
        const position = $event.target.selectionStart - ($event.target.value.length - candidate.length);

        this.renderer.setProperty(this.inputElement.nativeElement, 'value', candidate);
        if (candidate) {
            this.renderer.setProperty(this.inputElement.nativeElement, 'selectionStart', position);
            this.renderer.setProperty(this.inputElement.nativeElement, 'selectionEnd', position);
        }

        this._onChange(candidate);
    }

    cleanInput(input: string): string {
        const illegalChars = /[^\d/\-\.]/gi;
        return input.replace(illegalChars, '');
    }

    parseDecimal(candidate: string): string {
        if (candidate === '.') {
            return candidate;
        }

        let decimalLike = '\\-?\\d*(\\.\\d{0,DECIMALPLACES})?';
        decimalLike = decimalLike.replace('DECIMALPLACES', this.decimalplaces.toString());
        const match = candidate.match(decimalLike);

        if (match != null && match[0] != null) {
            return match[0];
        } else {
            return this.value.toString();
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() { }

}
