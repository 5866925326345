export class PaymentTerms {
    private constructor(value: string) {
        this.type = value;
    }
    private static typeToString: Map<PaymentTerms, string> = new Map<PaymentTerms, string>();
    private static valueToType: Map<string, PaymentTerms> = new Map<string, PaymentTerms>();

    private type: string;

    static initialize() {
        PaymentTerms.mapType('AtMaturity', 'At Maturity');
        PaymentTerms.mapType('MonthlyInterestPrincipalAtMaturity', 'Monthly Interest, Principal At Maturity');
    }

    static getAllTerms(): PaymentTerms[] {
        return Array.from(PaymentTerms.typeToString.keys());
    }

    static getTerm(value: string) {
        return PaymentTerms.valueToType.get(value);
    }

    static getDefaultTerm() {
        return PaymentTerms.getTerm('AtMaturity');
    }

    private static mapType(value: string, displayValue: string) {
        const type = new PaymentTerms(value);
        PaymentTerms.typeToString.set(type, displayValue);
        PaymentTerms.valueToType.set(value, type);
    }

    getDisplayValue() {
        return PaymentTerms.typeToString.get(this);
    }

    getValue() {
        return this.type;
    }
}
PaymentTerms.initialize();
