import { Component, ElementRef, Input, Renderer2, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'dol-numeric-input',
    templateUrl: './numeric-input.component.html',
    styles: [`
        :host-context(.full-width) mat-form-field {
            width: 100%;
        }
        :host-context(.mid-width) mat-form-field {
            width: 350px;
        }
    `],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: NumericInputComponent }
    ]
})
export class NumericInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    _onChange: (value: any) => void;
    @Input() digits = 0;
    @Input() commas: boolean;
    @Input() placeholder: string;
    @Input() prefix: string;
    @Input() suffix: string;
    @Input() signed: boolean;
    @Input() isDisabled = false;
    @ViewChild('inputElement') inputElement: ElementRef;
    inputControl: FormControl = new FormControl();
    previousValue: string;
    private _inputControlSubscription: Subscription;

    writeValue(value: number) {
        if (value !== null && value !== undefined) {
            const formattedInput = this.formatNumber(this.previousValue, value.toString());
            const paddedValue: string = this.padWithZeros(formattedInput, this.digits);

            this.previousValue = formattedInput;
            this.inputControl.patchValue(paddedValue, { emitEvent: false });
        } else {
            this.inputControl.reset(null, { emitEvent: false });
        }
    }

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
        this._inputControlSubscription = this.inputControl
            .valueChanges
            .subscribe(this.handleValueChange.bind(this));
    }

    ngOnDestroy() {
        this._inputControlSubscription.unsubscribe();
    }

    handleValueChange(unformattedInput: string) {
        if (unformattedInput) {
            const originalCursorPosition = this.inputElement.nativeElement.selectionStart;
            const formattedInput = this.formatNumber(this.previousValue, unformattedInput);
            const invalidRawNumbers = ['.', '-', '-.'];
            const rawNumber = (invalidRawNumbers.indexOf(formattedInput) === -1) ? formattedInput.replace(/[^-\d\.]/gi, '') : null;

            this._onChange(rawNumber ? parseFloat(rawNumber) : null);
            this.previousValue = formattedInput;
            this.inputControl.patchValue(formattedInput, { emitEvent: false });

            if (unformattedInput !== formattedInput) {
                this.adjustCursorPosition(originalCursorPosition, unformattedInput.length, formattedInput.length);
            }
        } else {
            this._onChange(null);
        }
    }

    formatNumber(
        prev: string,
        curr: string,
        signed: boolean = this.signed,
        commas: boolean = this.commas,
        digits: number = this.digits): string {
        let sign = '';
        let wholeNumbers = '';
        let delimeter = '';
        let fractionNumbers = '';

        if (signed && curr[0] === '-') {
            sign = '-';
        }

        if (digits) {
            const prevDecimalSplit = prev ? prev.split('.') : null;
            const currDecimalSplit = curr.split('.');
            let wholeNumbersRaw;
            let fractionNumbersRaw;

            if (prevDecimalSplit && prevDecimalSplit.length > 1 && currDecimalSplit.length > 2) {
                const addedRight = (prevDecimalSplit[0] === currDecimalSplit[0]);
                wholeNumbersRaw = (addedRight) ? currDecimalSplit[0] + currDecimalSplit[1] : currDecimalSplit[0];
                fractionNumbersRaw = (addedRight) ? currDecimalSplit[2] : currDecimalSplit[1] + currDecimalSplit[2];
            } else {
                wholeNumbersRaw = currDecimalSplit[0];
                fractionNumbersRaw = (currDecimalSplit.length === 1) ? null : currDecimalSplit[1];
            }

            wholeNumbers = wholeNumbersRaw.replace(/[^\d]/gi, '');
            fractionNumbers = fractionNumbersRaw ? fractionNumbersRaw.replace(/[^\d]/gi, '') : null;
            fractionNumbers = fractionNumbers ? fractionNumbers.substr(0, digits) : '';
            delimeter = (curr[curr.length - 1] === '.' || fractionNumbers) ? '.' : '';
        } else {
            wholeNumbers = curr.replace(/[^\d]/gi, '');
        }

        if (commas) {
            wholeNumbers = this.formatWithCommas(wholeNumbers);
        }

        return sign + wholeNumbers + delimeter + fractionNumbers;
    }

    formatWithCommas(unformatted: string) {
        const numericCandidate = unformatted.replace(/[^\d]/gi, '');
        let cleaned = '';

        for (let cursor = numericCandidate.length - 1; cursor > -1; cursor--) {
            cleaned = ((numericCandidate.length - 1 !== cursor) && ((numericCandidate.length - 1 - cursor) % 3 === 0))
                ? numericCandidate[cursor] + ',' + cleaned : numericCandidate[cursor] + cleaned;
        }

        return cleaned;
    }

    adjustCursorPosition(originalPosition: number, unformattedLength: number, formattedLength: number) {
        const lengthDifference = formattedLength - unformattedLength;
        const cursorPosition = (lengthDifference < -1 || lengthDifference > 1) ? originalPosition : originalPosition + lengthDifference;

        this.renderer.setProperty(this.inputElement.nativeElement, 'selectionStart', cursorPosition);
        this.renderer.setProperty(this.inputElement.nativeElement, 'selectionEnd', cursorPosition);
    }

    handleInputBlur() {
        const inputValue: string = this.inputControl.value;
        const blurFormattedNumber = (inputValue === '-') ? '' : this.padWithZeros(inputValue, this.digits);

        this.inputControl.patchValue(blurFormattedNumber, { emitEvent: false });
    }

    padWithZeros(inputValue: string, digits: number): string {
        const invalidRawNumbers = ['.', '-', '-.'];

        if (invalidRawNumbers.indexOf(inputValue) !== -1) {
            return '';
        } else if (digits > 0 && inputValue) {
            const lastDecimalIndex: number = inputValue.lastIndexOf('.');
            const wholeNumberPadding: string = (lastDecimalIndex === 0) ? '0' : '';
            const delimeter: string = (lastDecimalIndex === -1) ? '.' : '';
            let zerosCount = 0;

            if (lastDecimalIndex === -1 || lastDecimalIndex === inputValue.length - 1) {
                zerosCount = digits;
            } else if (lastDecimalIndex >= inputValue.length - digits) {
                zerosCount = digits - (inputValue.length - lastDecimalIndex - 1);
            }

            const zeros = (new Array(zerosCount)).fill('0').join('');
            const scale = delimeter + zeros;
            return wholeNumberPadding + inputValue + scale;
        }

        return inputValue;
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    registerOnTouched() { }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputControl.disable();
        } else {
            this.inputControl.enable();
        }
    }
}
