export class UserHeader {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;

    constructor(appData?: any) {
        if (appData) {
            this.userId = appData.UserId;
            this.firstName = appData.FirstName;
            this.lastName = appData.LastName;
            this.email = appData.Email;
        }
    }

    get fullName(): string {
        if (this.lastName || this.firstName) {
            return this.lastName + ', ' + this.firstName;
        }

        return '';
    }
}
