import * as moment from 'moment';

export class ProgramHeader {
    id: number;
    name: string;
    cropYear: number;

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.Id;
            this.name = appData.Name;
            this.cropYear = appData.CropYear ? appData.CropYear : this.getDefaultCropYear();
        }
    }

    private getDefaultCropYear(): number {
        const today = moment();
        return today.get('year') + (today.get('month') + 1 <= 6 ? 0 : 1);
    }
}
