import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../../environments/environment';
import { AuthenticatedHttp } from '../authentication/authenticated-http';
import { NotificationService } from 'if-angular-core';
import { Message } from './message.model';

@Injectable({
    providedIn: 'root'
})
export class MessageCenterService {
    private _messages: Message[];
    serviceCalled = false;

    constructor(private http: AuthenticatedHttp, private notificationService: NotificationService) { }

    getMessages(): Observable<Message[]> {
        if (this.serviceCalled) {
            of(this._messages);
        } else {
            const url = CONFIG.apiBaseUrl + 'messages/Dealer';
            return this.http.get(url)
                .pipe(
                    map((response) => {
                        return response ? response.map(d => new Message(d)) : null;
                    })
                );
        }
    }

    clearMessages() {
        this.serviceCalled = false;
        this._messages = null;
    }
}
