import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/authentication/auth.service';
import { VERSIONCONFIG } from '../../version-config';

@Component({
    selector: 'dol-forgot-password-confirmation',
    template: ''
})
export class ForgotPasswordConfirmationComponent implements OnInit {
    constructor(private authService: AuthService) { }

    ngOnInit() {
        if (window.top.location != window.location) {
            window.top.location = window.location;
        }

        this.authService.logout({
            postLogoutRedirectUri: `/${VERSIONCONFIG.redirectPrefix}password-confirmation`
        });
    }
}
