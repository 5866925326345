import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CONFIG } from '../environments/environment';
import { VERSIONCONFIG } from './version-config';
import { AuthGuard } from '../app/core/services/authentication/auth-guard.service';
import { TokenAuthComponent } from 'if-angular-security';
import { PermissionGuard } from '../app/core/services/authorization/permission-guard.service';
import { PROGRAM_VIEW } from '../app/shared/models/permissions';
import { LoggedInGuard } from '../app/login/services/logged-in-guard.service';

import { ForgotPasswordComponent } from './password/forgot-password/forgot-password.component';
import { SiteComponent } from './site.component';
import { AppComponent } from './app.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}home`,
        pathMatch: 'full'
    },
    {
        path: 'home',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}home`,
    },
    {
        path: 'login',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}login`,
    },
    {
        path: 'reset',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}reset`,
    },
    {
        path: 'auth',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}auth`,
    },
    {
        path: 'contact',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}contact`,
    },
    {
        path: 'customers',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}customers`,
    },
    {
        path: 'dealers',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}dealers`,
    },
    {
        path: 'documents',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}documents`,
    },
    {
        path: 'help',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}help`,
    },
    {
        path: 'my-profile',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}my-profile`,
    },
    {
        path: 'my-programs',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}my-programs`,
    },
    {
        path: 'programs',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}programs`,
    },
    {
        path: 'reports',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}reports`,
    },
    {
        path: 'users',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}users`,
    },
    {
        path: 'renewals',
        redirectTo: `${VERSIONCONFIG.redirectPrefix}renewals`,
    },
    {
        path: VERSIONCONFIG.path,
        component: AppComponent,    // AppComponent advises if the browswer is supported and provides header-free components for B2C content iframes
        children: [
            // the following components have header/footer displayed
            {
                path: '',
                component: SiteComponent,   // SiteComponent provides the header, footer and other static elements
                children: [
                    {
                        path: '',
                        redirectTo: `/${VERSIONCONFIG.redirectPrefix}home`,
                        pathMatch: 'full'
                    },
                    {
                        path: 'contact',
                        loadChildren: () => import('../app/contact/contact.module').then(m => m.ContactModule)
                    },
                    {
                        path: 'customers',
                        loadChildren: () => import('../app/customers/customers.module').then(m => m.CustomersModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'dealers',
                        loadChildren: () => import('../app/dealers/dealers.module').then(m => m.DealersModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'documents',
                        loadChildren: () => import('../app/dealer-documents/dealer-documents.module').then(m => m.DealerDocumentsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'help',
                        loadChildren: () => import('../app/help/help.module').then(m => m.HelpModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'my-programs',
                        loadChildren: () => import('../app/my-programs/my-programs.module').then(m => m.MyProgramsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'programs',
                        loadChildren: () => import('../app/programs/programs.module').then(m => m.ProgramsModule),
                        canActivate: [AuthGuard, PermissionGuard],
                        data: {
                            permission: PROGRAM_VIEW,
                            redirectTo: CONFIG.redirectToPath
                        }
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('../app/reports/reports.module').then(m => m.ReportsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'users',
                        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'renewals',
                        loadChildren: () => import('../app/renewals/renewals.module').then(m => m.RenewalsModule),
                        canActivate: [AuthGuard]
                    },
                ],
            },
            // the following components are displayed in B2C content frames without header/footer
            {
                path: 'auth',
                component: TokenAuthComponent,
                canActivate: [MsalGuard]
            },
            {
                path: 'my-profile',
                loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule), // parent of routes in MyProfileRoutingModule
                canActivate: [AuthGuard]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)], // TODO:MFA
    exports: [RouterModule]
})
export class AppRoutingModule { }
