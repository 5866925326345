import { AssignedDealers } from './assigned-dealers.model';
import { CustomReport } from './custom-report.model';
import { DealerLocationPermission } from './dealer-location-permission.model';
import { UserExtendedSecurityProfile } from './user-extended-security-profile.model';

export class DealerUserSecurityProfile extends UserExtendedSecurityProfile {
    acceptedEula: boolean;
    securityImageId?: number;
    trusteerOptOut?: boolean;
    customReports: Array<CustomReport>;
    assignedDealers: AssignedDealers;
    dealerLocationPermissions: Array<DealerLocationPermission>;

    constructor(appData?: any) {
        super(appData);
        if (appData) {
            this.acceptedEula = appData.AcceptedEula;
            this.securityImageId = appData.SecurityImageId;
            this.trusteerOptOut = appData.TrusteerOptOut;
            this.assignedDealers = new AssignedDealers(appData.AssignedDealers);
            this.dealerLocationPermissions = appData.DealerLocationPermissions;
        }
    }
}