import * as moment from 'moment';

import { DealerHeader } from './dealer-header.model';

export class CreditServiceFee {
    percent: number;
    payor: DealerHeader;
    dealerSelectionType: string;
    billingFrequency: string;
    initialBillingDate: moment.Moment;
    rebatePercent: number;
    rebateRecipient: DealerHeader;
    rebateDealerSelectionType: string;

    constructor(appData?: any) {
        if (appData) {
            this.percent = appData.Percent;
            this.payor = appData.Payor ? new DealerHeader(appData.Payor) : null;
            this.dealerSelectionType = appData.DealerSelectionType;
            this.billingFrequency = appData.BillingFrequency;
            this.initialBillingDate = appData.InitialBillingDate ? moment(appData.InitialBillingDate) : null;
            this.rebatePercent = appData.RebatePercent;
            this.rebateRecipient = appData.RebateRecipient ? new DealerHeader(appData.RebateRecipient) : null;
            this.rebateDealerSelectionType = appData.RebateDealerSelectionType;
        }
    }
}
