import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { User } from '../../shared/models/user.model';
import { runScript } from '../../shared/utilities';
import { Credentials } from '../models/credentials.model';
import { SecurityChallenge } from '../models/security-challenge.model';

import { AuthService } from '../../core/services/authentication/auth.service';
import { AssignedDealersService } from '../../core/services/assigned-dealers.service';
import { NotificationService } from 'if-angular-core';
import { StateService } from '../../core/services/state.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    credentials: Credentials;
    securityChallenge: SecurityChallenge;
    user: User;
    lastLoginMessage: string;

    constructor(
        private authService: AuthService,
        private assignedDealerService: AssignedDealersService,
        private notificationService: NotificationService,
        private stateService: StateService,
        private router: Router) { }

    getAssignedDealers() {
        this.assignedDealerService.getMyAssignedDealers()
            .subscribe((assignedDealers) => {
                if (this.user) {
                    this.user.assignedDealers = assignedDealers ? assignedDealers.dealers : [];
                    this.authService.saveUser(this.user);
                    this.stateService.assignedDealer = null;

                    if (!this.user.trusteerOptOut) {
                        runScript('/assets/security/snip3.js', true);
                    }
                }

                const redirect = this.stateService.redirectUrl ? this.stateService.redirectUrl : '/customers';
                this.stateService.redirectUrl = '';
                this.router.navigate([redirect]);
            }, (err: HttpErrorResponse) => {
                console.log(err);
                this.notificationService.push(
                    {
                        severity: 'error',
                        summary: 'An error occurred',
                        detail: 'An error occurred while getting your assigned dealers.'
                    });
                this.authService.logout();
                this.router.navigate(['/login']);
            });
    }
}

