<form [formGroup]="securityForm" class="security-form raised-card" (ngSubmit)="handleSubmit()">
    <h1>Update Account Security <dol-loading-feedback *ngIf="loading" [inline]="true">
        </dol-loading-feedback>
    </h1>
    <div *ngIf="securityForm.get('securityImageId')" class="security-form-section security-image-section">
        <h3>Security Image</h3>
        <div class="security-image-section-description">
            <p>We noticed that your account does not have a security image. To protect your account, please choose an
                image below:</p>
        </div>
        <div class="security-image-section-picker">
            <dol-security-image-picker formControlName="securityImageId">
            </dol-security-image-picker>
        </div>
        <div class="error" *ngIf="submitted && securityForm.get('securityImageId').invalid">
            <p *ngIf="securityForm.get('securityImageId').hasError('required')">A security image is required.</p>
        </div>
    </div>
    <div *ngIf="securityForm.get('securityQuestions')" class="security-form-section raised-card">
        <h3>Security Questions</h3>
        <p>We noticed that your account does not have any security questions. To protect your account, please answer the
            following:</p>
        <div *ngIf="submitted && securityForm.get('securityQuestions').hasError('duplicates')" class="error ng-invalid">
            <div>The same question cannot be used more than once.</div>
            <div>Please review your choices:</div>
        </div>
        <div formArrayName="securityQuestions">
            <div *ngFor="let _ of securityForm.get('securityQuestions')['controls']; let i = index"
                class="control-group-sub" [formGroupName]="i">
                <div class="security-question">
                    <mat-form-field>
                        <mat-select formControlName="question" [placeholder]="'Question #' + (i+1)">
                            <mat-option *ngFor="let question of availableQuestions" [value]="question">{{question}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="error"
                        *ngIf="submitted && securityForm.get('securityQuestions')['controls'][i].get('question').invalid">
                        <p
                            *ngIf="securityForm.get('securityQuestions')['controls'][i].get('question').hasError('required')">
                            A question is required.</p>
                    </div>
                </div>
                <mat-form-field>
                    <input matInput placeholder="Answer" formControlName="answer" autocomplete="off">
                </mat-form-field>
                <div class="error"
                    *ngIf="submitted && securityForm.get('securityQuestions')['controls'][i].get('answer').invalid">
                    <p *ngIf="securityForm.get('securityQuestions')['controls'][i].get('answer').hasError('required')">
                        An answer is required.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="security-form-controls">
        <button mat-raised-button type="submit" class="btn-brand" [disabled]="securityForm.disabled">Submit</button>
    </div>
</form>