import { Pipe, PipeTransform } from '@angular/core';

import { IndexType } from '../models/index-type.model';

@Pipe({ name: 'targetPipe' })
export class TargetPipe implements PipeTransform {
    transform(value: Array<IndexType>, kind: string): Array<IndexType> {
        if (kind && kind !== 'target') {
            return value.filter(indexType => indexType.getValue().toLocaleLowerCase() !== 'recourse');
        }

        return value;
    }
}
