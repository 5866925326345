import * as moment from 'moment';

import { ColumnDefinition } from './column-definition.model';
import { DealerHeader } from './dealer-header.model';
import { ProgramDocumentHeader } from './program-document-header.model';
import { ProgramHeader } from './program-header.model';
import { ProgramSponsorReference } from './program-sponsor-reference.model';
import { PurchaseGroup } from './purchase-group.model';
import { RateStep } from './rate-step.model';

export class ProgramResult extends ProgramHeader {

    get availability(): string {
        const start = this.startDate ? this.startDate.format('MM/DD/YYYY') : 'Unknown';
        const end = this.endDate ? this.endDate.format('MM/DD/YYYY') : 'Unknown';

        return start + ' TO ' + end;
    }

    get growerRates(): Array<string> {
        if (this.useCommitmentValues) {
            return ['Use Grower Values'];
        }

        if (!this.rateSteps || this.rateSteps.length === 0) {
            return ['Unknown'];
        }

        this.rateSteps.sort((p1, p2) => p1.appliedOrder - p2.appliedOrder);
        return this.rateSteps.slice().map(x => this.formatGrowerRate(x));
    }

    constructor(appData?: any) {
        super(appData);

        if (appData) {
            this.startDate = appData.StartDate ? moment(appData.StartDate) : null;
            this.endDate = appData.EndDate ? moment(appData.EndDate) : null;
            this.maturityDate = appData.MaturityDate ? moment(appData.MaturityDate) : null;
            this.sponsoringDealer = appData.SponsoringDealer ? new DealerHeader(appData.SponsoringDealer) : null;
            this.useCommitmentValues = appData.UseCommitmentValues;
            this.documents = appData.Documents ? appData.Documents.map((data) => new ProgramDocumentHeader(data)) : [];
            this.rateSteps = appData.RateSteps
                ? appData.RateSteps
                    .map((data) => new RateStep(data))
                    .sort((p1, p2) => p1.appliedOrder - p2.appliedOrder)
                : [];
            this.performanceRateSteps = appData.PerformanceRateSteps
                ? appData.PerformanceRateSteps
                    .map((data) => new RateStep(data))
                    .sort((p1, p2) => p1.appliedOrder - p2.appliedOrder)
                : [];
            this.allowedProducts = appData.AllowedProducts ? appData.AllowedProducts : null;
            this.vendorProducts = appData.VendorProducts ? appData.VendorProducts : null;
            this.requiredProducts = (appData.RequiredProducts
                && appData.RequiredProducts.PurchaseGroups
                && appData.RequiredProducts.PurchaseGroups.length > 0)
                ? new PurchaseGroup(appData.RequiredProducts)
                : null;
            this.performanceRequiredProducts = (appData.PerformanceRequiredProducts
                && appData.PerformanceRequiredProducts.PurchaseGroups
                && appData.PerformanceRequiredProducts.PurchaseGroups.length > 0)
                ? new PurchaseGroup(appData.PerformanceRequiredProducts)
                : null;
            this.allowedUnitOfMeasure = appData.AllowedUnitOfMeasure ? appData.AllowedUnitOfMeasure : null;
            this.isProductQuantityRequired = appData.IsProductQuantityRequired;
            this.isProductUnitOfMeasureRequired = appData.IsProductUnitOfMeasureRequired;
            this.isVendorProductPercentEnabled = appData.IsVendorProductPercentEnabled;
            this.vendorProductPercent = appData.VendorProductPercent;
            this.isBridgeEnabled = appData.IsBridgeEnabled;
            this.bridgeStartDate = appData.BridgeStartDate;
            this.bridgeEndDate = appData.BridgeEndDate;
            this.programSponsorReference = appData.ProgramSponsorReference ? new ProgramSponsorReference(appData.ProgramSponsorReference) : null;
        }
    }

    public static columnDefinitions: Array<ColumnDefinition> = [{
        field: 'name',
        header: 'Program Name',
        serverSortProperty: 'name',
        sortable: false,
        format: 'default'
    }, {
        field: 'cropYear',
        header: 'Crop Year',
        serverSortProperty: 'cropYear',
        sortable: false,
        class: 'datatable-center',
        format: 'default'
    }, {
        field: 'sponsoringDealer',
        header: 'Sponsor',
        serverSortProperty: 'sponsoringDealer',
        sortable: false,
        format: 'objectName'
    }, {
        field: 'growerRates',
        header: 'Rates',
        serverSortProperty: 'growerRates',
        sortable: false,
        class: 'rates',
        format: 'stringArray'
    }, {
        field: 'maturityDate',
        header: 'Maturity Date',
        serverSortProperty: 'maturityDate',
        sortable: false,
        format: 'date'
    }, {
        field: 'availability',
        header: 'Availability',
        serverSortProperty: 'availability',
        sortable: false,
        format: 'default'
    }, {
        field: 'documents',
        header: 'Attachments',
        serverSortProperty: 'documents',
        sortable: false,
        class: 'additional-details-column datatable-center',
        format: 'isArrayPopulated'
    }, {
        field: 'allowedProducts',
        header: 'Qualifying Products',
        serverSortProperty: 'allowedProducts',
        sortable: false,
        class: 'additional-details-column datatable-center',
        format: 'isArrayPopulated'
    }, {
        field: 'vendorProducts',
        header: 'Vendor Products',
        serverSortProperty: 'vendorProducts',
        sortable: false,
        class: 'additional-details-column datatable-center',
        format: 'isArrayPopulated'
    }, {
        field: 'requiredProducts',
        header: 'Required Products',
        serverSortProperty: 'requiredProducts',
        sortable: false,
        class: 'additional-details-column datatable-center',
        format: 'isRequiredProductsPopulated'
    }].map(column => new ColumnDefinition(column));

    startDate: moment.Moment;
    endDate: moment.Moment;
    maturityDate: moment.Moment;
    sponsoringDealer: DealerHeader;
    documents: Array<ProgramDocumentHeader>;
    rateSteps: Array<RateStep>;
    performanceRateSteps: Array<RateStep>;
    requiredProducts: PurchaseGroup;
    performanceRequiredProducts: PurchaseGroup;
    allowedProducts: Array<string>;
    allowedUnitOfMeasure: Array<string>;
    vendorProducts: Array<string>;
    useCommitmentValues: boolean;
    isProductQuantityRequired: boolean;
    isProductUnitOfMeasureRequired: boolean;
    vendorProductPercent: number;
    isVendorProductPercentEnabled: boolean;
    isBridgeEnabled: boolean;
    bridgeStartDate: moment.Moment;
    bridgeEndDate: moment.Moment;
    programSponsorReference: ProgramSponsorReference;

    getIntroductoryGrowerRate(): string {
        if (!this.rateSteps || this.rateSteps.length === 0) {
            return null;
        }

        this.rateSteps.sort((p1, p2) => p1.appliedOrder - p2.appliedOrder);
        const rate = this.rateSteps[0].growerRate;

        return rate == null ? null : rate.toString();
    }

    getPerformanceIntroductoryGrowerRate(): string {
        if (!this.performanceRateSteps || this.performanceRateSteps.length === 0) {
            return null;
        }

        this.performanceRateSteps.sort((p1, p2) => p1.appliedOrder - p2.appliedOrder);
        const rate = this.performanceRateSteps[0].growerRate;

        return rate == null ? null : rate.toString();
    }

    private formatGrowerRate(rate: RateStep) {
        const rateString = rate.growerRate
            ? rate.growerRate.toString()
            : 'Determined at time of first transaction';

        if (rate.expirationDays) {
            return rateString + ' for ' + rate.expirationDays + ' days';
        } else if (rate.expirationDate) {
            return rateString + ' until ' + rate.expirationDate.format('MM/DD/YYYY');
        }

        return rateString;
    }
}
