<div class="eula">
    <div class="eula-section">
        <h1>EULA Acceptance <dol-loading-feedback *ngIf="loading" [inline]="true">
            </dol-loading-feedback>
        </h1>
        <p>Please review the End User License Agreement and select one of the options below:</p>
        <div>
            <iframe width="100%" height="1000" src="/assets/Online%20Services%20Agreement.pdf"></iframe>
        </div>
    </div>
    <div class="eula-controls">
        <dol-loading-feedback *ngIf="loading" [inline]="true">
        </dol-loading-feedback>
        <button mat-button type="button" class="btn btn-default" (click)="updateEula(false)"
            [disabled]="disableControls">Decline</button>
        <button mat-raised-button type="button" class="btn btn-brand" (click)="updateEula(true)"
            [disabled]="disableControls">Accept</button>
    </div>
</div>