import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'dol-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.css']
})
export class ModalDialogComponent {
    @Input() show: boolean;
    @Input() large: boolean;
    @Output() close: EventEmitter<any>;

    constructor() {
        this.close = new EventEmitter();
    }

    closeModal(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeydown(event): void {
        const ESCAPE = 27;

        if (this.show && event.keyCode === ESCAPE) {
            this.close.emit();
        }
    }
}
