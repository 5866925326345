import { DealerHeader } from './dealer-header.model';

export class DealerBase extends DealerHeader {
    usesRaboApplications: boolean;
    sendGrowerTransactionEmails: boolean;
    isLogicalRoot: boolean;

    constructor(appData?: any) {
        super(appData);

        if (appData) {
            this.usesRaboApplications = appData.UsesRaboApplications == null ? true : appData.UsesRaboApplications;
            this.sendGrowerTransactionEmails = appData.SendGrowerTransactionEmails == null ? true : appData.SendGrowerTransactionEmails;
            this.isLogicalRoot = appData.IsLogicalRoot == null ? false : appData.IsLogicalRoot;
        }
    }
}
