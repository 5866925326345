const POBOX_ABBREVIATIONS =
    [
        'pobox',
        'po box',
        'p.o.'
    ];

export class Address {
    line1 = '';
    line2 = '';
    city = '';
    state = '';
    county = '';
    country = '';
    zipcode = '';

    constructor(appData?: any, isProps: boolean = false) {
        if (!isProps) {
            this.loadAppData(appData);
        } else {
            this.loadProps(appData);
        }
    }

    loadAppData(appData?: any) {
        if (appData) {
            this.city = appData.City;
            this.county = appData.County;
            this.country = appData.Country;
            this.line1 = appData.Line1;
            this.line2 = appData.Line2;
            this.state = appData.State;
            this.zipcode = appData.ZipCode;
        }
    }

    loadProps(props?: any) {
        if (props) {
            this.city = props.city;
            this.county = props.county;
            this.country = props.country;
            this.line1 = props.line1;
            this.line2 = props.line2;
            this.state = props.state;
            this.zipcode = props.zipcode;
        }
    }

    isPhysicalAddress(): boolean {
        let pobox = false;
        let i = 0;

        while (i < POBOX_ABBREVIATIONS.length && !pobox) {
            if (this.line1.toLowerCase().indexOf(POBOX_ABBREVIATIONS[i]) !== -1) {
                pobox = true;
            }
            i++;
        }

        return !pobox;
    }

    toString(): string {
        let formattedAddress = '';
        formattedAddress += this.line1 ? this.line1 + ', ' : '';
        formattedAddress += this.city ? this.city + ', ' : '';
        formattedAddress += this.state ? this.state + ' ' : '';
        formattedAddress += this.zipcode ? this.zipcode + ' ' : '';
        formattedAddress += this.county ? this.county + ' ' : '';
        formattedAddress += this.country ? this.country + ' ' : '';

        return formattedAddress;
    }

    get isComplete(): boolean {
        return this.line1.length > 0 && this.state.length > 0 && this.city.length > 0 && this.zipcode.length > 0;
    }
}
