export enum LinkDescription {
    // tslint:disable-next-line: max-line-length
    ApplicationStatus = 'A listing of all the applications that RAF has received where a specific retailer\'s location has been listed in the dealer information on the customer\'s application.',
    DealerTransaction = 'Displays transactions within the date range selected.',
    BridgeDealerTransaction = 'Displays bridge transactions within the date range selected.',
    LoanDetails = 'Display the details of the loan for the crop years selected.',
    // tslint:disable-next-line: max-line-length
    PastDueAccounts = 'Will display the customers that have balances on their accounts once the maturity date for the financing program has passed.',
    Renewals = 'Displays the status of customer accounts during the renewal process.',
    Settlement = 'Display payments that are remitted by RAF to reconcile and identify transactions that remain settled and unsettled.',
    UnusedAccounts = 'Lists the active customer accounts that do not have a balance.',
    // tslint:disable-next-line: max-line-length
    VolumeComparison = 'Compare customer\'s financed purchases from one date range to another and display the change in the customer\'s history.',
    StatementsInvoices = 'Allows viewing and printing of current and historical monthly statements and invoices for dealers.'
}
