import { Address } from './address.model';

export class DealerHeader {
    rowId: number;
    dealerNumber: string;
    name: string;
    address: Address = new Address();

    constructor(appData?: any) {
        if (appData) {
            this.rowId = appData.RowId;
            this.dealerNumber = appData.DealerNumber;
            this.name = appData.Name;
            this.address = new Address(appData.Address);
        }
    }
}
