import { Component, OnInit } from "@angular/core";
import { IfAuthService } from 'if-angular-security';
import { IRedirectRequestParams } from 'if-angular-security/lib/models/redirect-request-params.interface';
import { StringDict } from 'if-angular-security/lib/models/string-dict.type';
import { CONFIG } from '../../../environments/environment';
import { DealerOnlineWebsiteName } from "../../shared/models/constants";

@Component({
    selector: 'b2c-login',
    template: ''
})
export class B2CLoginComponent implements OnInit {
    constructor(private ifAuthSecurityService: IfAuthService) { }

    ngOnInit() {
        const queryParams: StringDict = {};
        queryParams['app'] = DealerOnlineWebsiteName;

        const redirectRequest: IRedirectRequestParams = {
            policy : CONFIG.b2cSigninFrame.policy,
            redirectUri: CONFIG.b2cConfig.redirectUri,
            extraQueryParameters: queryParams
        }
      
        this.ifAuthSecurityService.loginRedirect(redirectRequest);
    }
}