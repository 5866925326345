<div class="stage container">
    <div *ngIf="!disableUpload" class="datatable-controls">
        <input type="file" class="file-input" multiple (change)="handleFileInput(fileInputElement)" #fileInputElement>
        <button mat-raised-button type="button"
            [disabled]="disable || loading || (this.uploads && this.uploads.length > 0)" class="btn-success"
            (click)="fileInputElement.click()">
            <i class="fa fa-upload"></i>
            <span>Upload</span>
        </button>
    </div>
    <ul class="documents">
        <li *ngIf="errorMessage" class="error">
            <h3>An error occurred while uploading your documents</h3>
            <p>{{errorMessage}}</p>
        </li>
        <li *ngFor="let upload of uploads" class="raised document uploading">
            <div class="document-header">
                <h3>{{upload.name}}</h3>
            </div>
            <div class="loading-wrapper">
                <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
            </div>
        </li>
        <li *ngFor="let document of documentStates" class="raised document">
            <div class="document-header">
                <h3><a (click)="downloadDocument(document)">{{document.document.name}}</a></h3>
                <h4 *ngIf="document.document?.createdBy">Uploaded by {{document.document.createdBy.firstName}}
                    {{document.document.createdBy.lastName}}
                    on {{document.document.createDate?.format('MM/DD/YYYY')}}</h4>
            </div>
            <div class="document-actions">
                <button *ngIf="!disableDelete" mat-button type="button" class="btn-default"
                    (click)="confirmDocumentDelete(document)" [disabled]="document.pendingAction">Delete</button>
                <button *ngIf="!disableDownload" mat-raised-button type="button" class="btn-brand"
                    (click)="downloadDocument(document)" [disabled]="document.pendingAction">Download</button>
            </div>
            <div *ngIf="document.pendingDelete" class="loading-wrapper">
                <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
            </div>
            <div *ngIf="document.pendingDownload" class="loading-wrapper">
                <mat-progress-bar mode="query" color="primary"></mat-progress-bar>
            </div>
        </li>
        <!-- input here -->
        <li *ngIf="!uploads && (!documentStates || (documentStates && documentStates.length === 0)) && !loading"
            class="empty-message">
            <h3>No Documents</h3>
            <p *ngIf="noDocumentsMessage">{{noDocumentsMessage}}</p>
        </li>
    </ul>
</div>
<dol-modal-dialog [show]="showDeleteDocumentConfirmation" (close)="cancelDocumentDelete()">
    <div class="modal-body">
        <h1>Are you sure?</h1>
        <p>Are you sure you want to delete {{documentToDelete?.document.name}}?</p>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" class="btn-danger" (click)="cancelDocumentDelete()">No</button>
        <button mat-raised-button type="button" class="btn-success"
            (click)="deleteDocument(documentToDelete)">Yes</button>
    </div>
</dol-modal-dialog>