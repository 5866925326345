export class MessageGroup {
    header: string;
    messages: string[];

    constructor(data?: any) {
        if (data) {
            this.header = data.header;
            this.messages = data.messages;
        }
    }
}
