import { DescriptivePhoneNumber } from './descriptive-phone-number.model';
import { FullAddress } from './full-address.model';

export class DealerContactInformation {
    phoneNumbers: Array<DescriptivePhoneNumber>;
    addresses: Array<FullAddress>;

    constructor(appData?: any) {
        if (appData) {

            this.phoneNumbers = this.convertPhoneNumbers(appData.PhoneNumbers);
            this.addresses = this.convertAddresses(appData.Addresses);
        }
    }

    convertPhoneNumbers(numbers: any): Array<DescriptivePhoneNumber> {
        const correctedPhoneNumbers: Array<DescriptivePhoneNumber> = new Array<DescriptivePhoneNumber>();
        let tempNumber: DescriptivePhoneNumber;
        for (const phone of numbers) {
            tempNumber = new DescriptivePhoneNumber(phone);
            correctedPhoneNumbers.push(tempNumber);
        }
        return correctedPhoneNumbers;
    }

    convertAddresses(addresses: any): Array<FullAddress> {
        const correctedAddresses: Array<FullAddress> = new Array<FullAddress>();
        let tempAddress: FullAddress;
        for (const address of addresses) {
            tempAddress = new FullAddress(address);
            correctedAddresses.push(tempAddress);
        }

        return correctedAddresses;
    }
}
