import { Component, OnInit } from '@angular/core';

import { CONFIG } from '../../../environments/environment';

@Component({
    selector: 'dol-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
    loginImage: { uri: string, redirectTo: string } = CONFIG.loginImage;
    b2cFrameSource: string =  CONFIG.b2cSigninFrame.src;
    b2cFrameHeight: string = CONFIG.b2cSigninFrame.height;

    constructor() { }

    ngOnInit() {
        window.scrollTo(0, 0);
    }
}
