import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators/finalize';

import { CONFIG } from '../../../environments/environment';
import { isNumeric } from '../../shared/utilities';
import { validateEmail } from '../../shared/validators/email.validator';
import { Credentials } from '../models/credentials.model';
import { SecurityChallenge } from '../models/security-challenge.model';

import { AccountService } from '../../core/services/account.service';
import { NotificationService } from 'if-angular-core';
import { LoginService } from '../services/login.service';
import { AuthService } from '../../core/services/authentication/auth.service';

@Component({
    selector: 'dol-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
    @ViewChild('password') password: ElementRef;
    form: FormGroup;
    loading: boolean;
    submitted: boolean;
    invalidFormMessage: string;
    loginImage: { uri: string, redirectTo: string } = CONFIG.loginImage;
    passwordExpired: boolean;
    passwordControl = new FormControl('', Validators.required);
    email: FormControl = new FormControl('', Validators.compose([Validators.required, validateEmail]));

    constructor(
        private http: HttpClient,
        private fb: FormBuilder,
        private accountService: AccountService,
        private notificationService: NotificationService,
        private renderer: Renderer2,
        private router: Router,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private authService: AuthService) { }

    ngOnInit() {
        window.scrollTo(0, 0);

        this.form = this.fb.group({
            email: this.email,
            password: this.passwordControl
        });
    }

    submit(): void {
        this.submitted = true;

        if (this.form.valid) {
            const credentials = new Credentials({
                email: this.form.get('email').value,
                password: this.form.get('password').value
            });
            this.loading = true;
            this.form.disable();
            this.invalidFormMessage = '';

            this.accountService.getDealerSecurityChallenge(credentials)
                .pipe(
                    finalize(() => {
                        this.submitted = false;
                        this.loading = false;
                        this.form.enable();
                    })
                )
                .subscribe((securityChallenge: SecurityChallenge) => {
                   
                    this.loginService.credentials = credentials;
                    this.loginService.securityChallenge = securityChallenge;

                    if (securityChallenge && isNumeric(securityChallenge.securityImageId)) {

                        /*********Now that we're reasonably sure of their identity, display last login date message*********/
                        this.authService.GetLastLoginMessage(this.loginService.credentials.email) 
                        .subscribe((result) => {
                            let {message}:{message:string} = JSON.parse(result);
                            this.loginService.lastLoginMessage = message;
                            
                            this.router.navigate(['challenge'], { relativeTo: this.route, skipLocationChange: true });
                        },
                        (err) => {
                            console.log('Last login error msg', err);

                        });
                    } else {
                        this.router.navigate(['update-challenge'], { relativeTo: this.route, skipLocationChange: true });
                    }
                }, (err: HttpErrorResponse) => {
                    console.log(err);
                    if (err.error === 'Password expired') {
                        this.passwordExpired = true;
                        return;
                    }
                    this.notificationService.push(
                        {
                            severity: 'error',
                            summary: 'An error occurred',
                            detail: 'An error occurred while logging you in.'
                        });

                    if (!(err.error instanceof Error)) {
                        if (err.status === 400) {
                            const error = err.error;
                            this.invalidFormMessage = error.error_description;
                        } else if (err.status === 401) {
                            this.invalidFormMessage = err.error;
                        } else {
                            this.invalidFormMessage = 'We are experiencing issues. Please try again later.';
                        }
                    }

                    this.form.get('password').reset();

                    this.password.nativeElement.focus();
                });
        }
    }

    onExpiredPasswordUpdated(newPassword: string) {
        this.passwordExpired = false;
        this.form.get('password').setValue(newPassword);
        this.submit();
    }

}
