import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import * as Bowser from 'bowser';

import { CONFIG } from '../environments/environment';
import { AuthService } from './core/services/authentication/auth.service'; //TODO:MFA
import { IdleService } from '../app/core/services/idle/idle.service';
import { NotificationService } from 'if-angular-core';
import { IfAuthService, IVfAuthServiceInitOptions} from 'if-angular-security';
import { Subject } from 'rxjs';
import { runScript } from '../app/shared/utilities';

// AppComponent advises if the browser is supported and provides header-free components for B2C content iframes
@Component({
  selector: 'dol-root',
  templateUrl: './app.component.html',
  styleUrls: ['../app/app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  showWarning: boolean;
  showLogout: boolean;
  countdown: number;
  showBowserAlert: boolean;
  private readonly _destroying$ = new Subject<void>();
  showVisibleComponents : boolean = true;

  constructor(
    private authService: AuthService,
    private idleService: IdleService,
    private notificationService: NotificationService,
    private router: Router,
    private ifAuthService: IfAuthService
    ) {

      const vfServiceInitOptions: IVfAuthServiceInitOptions = {
        appDestroyedSubject : this._destroying$,
        onLoginCallback : authService.onUserLogin,
        isLoggedInInApp : authService.loggedIn,
      };      
      this.ifAuthService.initService(vfServiceInitOptions);
  }

  ngOnInit() {
    this.browserCheck();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && this.authService.loggedIn()) {
        this.authService.refreshToken()
          .subscribe(() => {

          }, (err: HttpErrorResponse | Error) => {
            const reason = (err instanceof HttpErrorResponse) ? err.error : err;
            console.log('Could not refresh token during AppComponent start:', reason);
          });
      }
    });

    this.idleService.event$.subscribe((event) => {
      if (event.type === 'warning') {
        this.showWarning = true;
      } else if (event.type === 'tick') {
        this.countdown = event.content;
      } else if (event.type === 'logout') {
        this.showLogout = true;
        this.logout();
      }
    });

    this.performPostLoginOperations();
  }

  okWarning() {
    this.showWarning = false;
    this.authService.refreshToken()
      .subscribe(() => {

      }, (err: HttpErrorResponse | Error) => {
        const reason = (err instanceof HttpErrorResponse) ? err.error : err;
        console.log('Could not refresh token:', reason);
        this.notificationService.push(
          {
            severity: 'error',
            summary: 'An error occurred',
            detail: 'An error occurred while refreshing the token.'
          });
      });
  }

  logout(): void {
    this.showWarning = false;
    this.authService.logout();
  }

  browserCheck() {
    const browser = Bowser.getParser(window.navigator.userAgent);

    this.showBowserAlert = !browser.satisfies({
      chrome: '>=87',
      firefox: '>=80',
      edge: '>=88'
    });
  }

  private performPostLoginOperations() {
    const postLoginOperationData = this.authService.popPostLoginOperationData();
    if (postLoginOperationData) {
      if(postLoginOperationData.shouldActivateTrusteer === true) {
        runScript('/assets/security/snip3.js', true);
      }
    }
  }

  ngOnDestroy(): void { //TODO:MFA
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
