import { RateStep } from './rate-step.model';

export class PostMaturityRateStep extends RateStep {
    recourseTypes: Array<string>;

    constructor(appData?: any) {
        super(appData);
        if (appData) {
            this.recourseTypes = appData.RecourseTypes;
        }
    }


}
