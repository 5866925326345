import { interval, Observable, Subscription } from 'rxjs';

export class Interval {
    private observable: Observable<number>;
    private subscription: Subscription;
    private tick: number;
    private timeRemaining: number;
    private onComplete: any;
    private onTick: any;

    constructor() {
        this.tick = 1000;
        this.timeRemaining = 0;
    }

    setOnComplete(onComplete: any): void {
        this.onComplete = onComplete;
    }

    setOnTick(onTick: any): void {
        this.onTick = onTick;
    }

    countdown(timeRemaining: number): void {
        if (timeRemaining) {
            this.timeRemaining = timeRemaining;
            this.initializeIntervalAndGo();
        }
    }

    restart(): void {
        this.stop();
        this.initializeIntervalAndGo();
    }

    stop(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private initializeIntervalAndGo(): void {
        if (this.onTick) {
            this.onTick(this.timeRemaining);
        }

        this.observable = interval(this.tick);
        this.subscription = this.observable.subscribe((num) => {
            this.timeRemaining = this.timeRemaining - 1;

            if (this.onTick) {
                this.onTick(this.timeRemaining);
            }

            if (this.onComplete && this.timeRemaining === 0) {
                this.stop();
                this.onComplete();
            }
        });
    }
}
