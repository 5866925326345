import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../../environments/environment';
import { Credentials } from '../../../login/models/credentials.model';
import { User } from '../../../shared/models/user.model';
import { IdleService } from '../idle/idle.service';
import { StateService } from '../state.service';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(
        private http: HttpClient,
        private idleService: IdleService,
        private stateService: StateService,
        private storageService: StorageService) { }

    loggedIn(): boolean {
        const user = this.stateService.currentUser;
        return user && user.refreshToken && user.assignedDealers && !user.isAuthExpired() && user.acceptedEula;
    }

    login(credentials: Credentials): Observable<User> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/DealerLogin';

        return this.http.post<User>(apiUrl, credentials, { headers: this.headers })
            .pipe(
                map((res) => {
                    const user = new User(res);
                    this.saveUser(user);
                    return user;
                })
            );
    }

    logout() {
        this.stateService.deleteUser();
        this.idleService.stop();
        this.storageService.clearSession();
    }

    refreshToken(): Observable<any> {
        const user = this.stateService.currentUser;

        if (!user || !user.refreshToken) {
            throwError(new Error('Missing user or refresh token'));
        }

        const data = { RefreshToken: user.refreshToken };
        const url = `${CONFIG.apiBaseUrl}Account/DealerRefreshLogin`;

        return this.http
            .post(url, JSON.stringify(data), { headers: this.headers })
            .pipe(
                map((res) => this.updateUser(res)));
    }

    requestPasswordReset(email: string): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/InitiatePasswordReset';
        const body = { email: email, websiteName: 'DealerOnline' };

        return this.http.post(apiUrl, body, { responseType: 'text', headers: this.headers });
    }

    requestSecurityQuestionReset(email: string): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/InitiateSecurityQuestionReset';
        const body = { email: email, websiteName: 'DealerOnline' };

        return this.http.post(apiUrl, body, { responseType: 'text', headers: this.headers });
    }

    resetPassword(requestDetails: any): Observable<any> {
        const apiUrl = CONFIG.apiBaseUrl + 'Account/ResetPassword';

        return this.http.post(apiUrl, requestDetails, { responseType: 'text', headers: this.headers });
    }

    updateUser(userData: any): void {
        const user = this.stateService.currentUser;
        const newUser = new User(userData);
        user.updateAuthorization(newUser);
        this.saveUser(user);
    }

    saveUserData(userData: any): void {
        const user = new User(userData);
        this.saveUser(user);
    }

    saveUser(user: User): void {
        const duration = moment.duration(user.expires.diff(moment()));
        this.idleService.start(duration.asSeconds());
        this.stateService.saveUser(user);
    }

    public GetLastLoginMessage(username: string): Observable<any> {
        const data = { Email: username,};
        const apiUrl = CONFIG.apiBaseUrl + 'Account';
        
        let browserDate = new Date();
        let offsetMinutes = -1 * browserDate.getTimezoneOffset(); //javascript treats the offset direction the opposite way that dotnet does.

        return this.http
        .get(apiUrl + `/GetLastLoginMessage/?email=${username}&utcOffsetMinutes=${offsetMinutes}`, { headers: this.headers });
    }
}
