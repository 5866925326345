export class IdleEvent {
    type: string;
    content: any;

    constructor(type: string, content?: any) {
        this.type = type;
        if (content) {
            this.content = content;
        }
    }
}
