const migrateV2 = true;
const v2path = migrateV2 ? '' : '/v2';

export const CONFIG = {
    env: 'prod',
    apiBaseUrl: 'https://api.raboag.com/',
    websiteName: 'DealerOnline',
    runAsProd: true,
    idle: {
        countdown: 60
    },
    recaptchaSiteKey: '6LevSwkUAAAAAD3ZkPBaHxXp-wmLUy1p4Bcyd0XI',
    enableDealerRegistration: true,
    loginImage: {
        // this background image can be changed via an ITI manual step (copy to the virtual directory on IIS servers)
        uri: '/assets/images/DOL-Web-Ad2a.jpg',
        redirectTo: ''
    },
    redirectToPath: '/customers',
    b2cConfig: {
        clientId: '763bbc59-d5b5-4ee5-9b3a-1ab984a68dd3',
        tenantId: 'ifssoprd',
        redirectUri: v2path + '/auth',
        postLogoutRedirectUri: v2path + '/home'
    },
    b2cSigninFrame: {
        src: v2path + '/login-b2c',
        height: '444px',
        policy: 'B2C_1A_Signup_Signin'
    },
    b2cUpdatePasswordFrame: {
        src: v2path + '/reset-password-login',
        height: '520px',
        policy: 'B2C_1A_PasswordUpdate',
        redirectUri: v2path + '/forgot-password-confirmation',
    },
    b2cPasswordResetFrame: {
        src: v2path + '/forgot-password-login',
        height: '500px',
        policy: 'B2C_1A_PasswordReset',
        redirectUri: v2path + '/forgot-password-confirmation'
    },
    b2cPasswordChangeFrame: {
        src: v2path + '/my-profile/change-password-frame',
        height: '640px',
        policy: 'B2C_1A_PasswordChange',
        redirectUri: v2path + '/my-profile/change-password-confirmation'
    },
    b2cEmailChangeFrame: {
        src: v2path + '/my-profile/change-email-frame',
        height: '400px',
        policy: 'B2C_1A_SignInNameChange',
        redirectUri: v2path + '/my-profile/change-email-confirmation'
    },
    enableAppV2: true,
    migrateAppV2: migrateV2,
    b2cFrameDelay: 2000 // Show a loading indicator for X ms to let the custom policy load behind the scenes
};
