import { CustomReport } from './custom-report.model';

export class UserPreferences {
    customReports: Array<CustomReport> = [];

    constructor(appData?: any) {
        if (appData) {
            this.customReports = appData.CustomReports.map(report => new CustomReport(report));
        }
    }
}
