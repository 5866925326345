import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VERSIONCONFIG } from '../version-config';
import { SharedModule } from '../../app/shared/shared.module';
import { SharedModule as SharedModuleV2 } from "../shared/shared.module";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordLoginComponent } from './forgot-password/forgot-password-login.component';
import { ForgotPasswordConfirmationComponent } from './forgot-password/forgot-password-confirmation.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UpdatePasswordLoginComponent } from './update-password/update-password-login.component';
import { PasswordConfirmationComponent } from './password-confirmation.component';
import { AppComponent } from '../app.component';
import { SiteComponent } from '../site.component';

const routes = [
    {
        path: VERSIONCONFIG.path,
        component: AppComponent,
        children: [
            {
                path: '',
                component: SiteComponent,
                children: [
                    { path: 'reset', component: ForgotPasswordComponent },
                    { path: 'reset-password', component: UpdatePasswordComponent },
                    { path: 'password-confirmation', component: PasswordConfirmationComponent },
                ]
            },
            { path: 'forgot-password-login', component: ForgotPasswordLoginComponent },
            { path: 'forgot-password-confirmation', component: ForgotPasswordConfirmationComponent },
            { path: 'reset-password-login', component: UpdatePasswordLoginComponent },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        SharedModuleV2
    ],
    declarations: [
        ForgotPasswordComponent,
        ForgotPasswordLoginComponent,
        ForgotPasswordConfirmationComponent,
        UpdatePasswordComponent,
        UpdatePasswordLoginComponent,
        PasswordConfirmationComponent
    ]
})
export class PasswordModule { }
