import { Component, AfterViewInit, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../core/services/authentication/auth.service';
import { NotificationService } from 'if-angular-core';
import { User } from '../../shared/models/user.model';
import { isNumeric } from '../../shared/utilities';
import { SecurityChallenge } from '../models/security-challenge.model';
import { LoginService } from '../services/login.service';

@Component({
    selector: 'dol-challenge-form',
    templateUrl: './challenge-form.component.html',
    styleUrls: ['./challenge-form.component.css']
})
export class ChallengeFormComponent implements OnInit, AfterViewInit {
    @ViewChild(MatInput) answerInput: MatInput;
    form: FormGroup;
    submitted: boolean;
    loading: boolean;
    invalidFormMessage: string;
    isNumeric: (value: any) => boolean = isNumeric;
    securityChallenge: SecurityChallenge;
    lastLoginMessage: string;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService,
        private loginService: LoginService,
        private route: ActivatedRoute) {
        this.securityChallenge = this.loginService.securityChallenge;
        this.lastLoginMessage = this.loginService.lastLoginMessage;
    }

    ngOnInit() {
        this.form = this.fb.group({
            answer: ['', Validators.required]
        });
    }

    ngAfterViewInit() {
        setTimeout(_ => {
            this.answerInput.focus();
            window.scrollTo(0, 0);
        });
    }

    submit(): void {
        this.submitted = true;

        if (this.form.valid) {
            this.loading = true;
            this.form.disable();
            this.loginService.credentials.question = this.securityChallenge.question;
            this.loginService.credentials.answer = this.form.get('answer').value;

            this.invalidFormMessage = '';
            this.authService.login(this.loginService.credentials)
                .pipe(
                    finalize(() => {
                        this.submitted = false;
                        this.loading = false;
                    })
                )
                .subscribe((user: User) => {
                    this.loginService.user = user;

                    if (!user.acceptedEula) {
                        this.router.navigate(['../eula'], { relativeTo: this.route, skipLocationChange: true });
                    } else {
                        this.loginService.getAssignedDealers();
                    }
                }, (err: HttpErrorResponse) => {
                    console.log(err);
                    this.notificationService.push(
                        {
                            severity: 'error',
                            summary: 'An error occurred',
                            detail: 'An error occurred while logging you in'
                        });
                    this.form.enable();

                    if (err.status === 400 && !(err.error instanceof Error)) {
                        const error = err.error;
                        this.invalidFormMessage = error.error_description;
                    } else if (err.status === 401) {
                        this.invalidFormMessage = 'That is incorrect. Could you recheck your answer?';
                    } else {
                        this.invalidFormMessage = 'We are experiencing issues. Please try again later.';
                    }
                });
        }
    }

}
