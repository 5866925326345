<div class="security-challenge-wrapper raised">
    <h1>Security Challenge <dol-loading-feedback *ngIf="loading" [inline]="true">
        </dol-loading-feedback>
    </h1>
    <div *ngIf="isNumeric(securityChallenge.securityImageId)" class="security-image-wrapper">
        <div>
            <label>Security Image</label>
            <dol-security-image-preview [id]="securityChallenge.securityImageId"></dol-security-image-preview>
        </div>
        <div *ngIf="securityChallenge.question">
            <p>If this image matches the Security Image you have selected for your account, please answer the security
                question and then click Log In.</p>
            <p>If this is not the security image you selected, please contact our Customer Experience team at 888-395-8505.</p>
        </div>
        <div *ngIf="!securityChallenge.question">
            <p>If the image below matches the Security Image you have selected for your account, please click Continue.
            </p>
            <div class="security-image-controls">
                <button mat-raised-button type="button" class="btn btn-brand" routerLink="../update-challenge"
                    skipLocationChange>Continue</button>
            </div>
        </div>
    </div>
    <form *ngIf="securityChallenge.question" [formGroup]="form" (ngSubmit)="submit()"
        class="security-question-form raised-card">
        <div class="security-question">{{securityChallenge.question}}</div>
        <div *ngIf="invalidFormMessage" class="error form-error">{{invalidFormMessage}}</div>
        <mat-form-field>
            <input matInput placeholder="Answer" formControlName="answer" autocomplete="off">
        </mat-form-field>
        <ul *ngIf="submitted && form.get('answer').invalid" class="error">
            <li *ngIf="form.get('answer').hasError('required')">An answer is required.</li>
        </ul>
        <div class="form-controls">
            <button mat-raised-button type="submit" [disabled]="form.disabled" class="btn-brand">Log In</button>
        </div>
    </form>
    <div class="security-challenge-loginmessage" *ngIf="lastLoginMessage">
        <p>{{lastLoginMessage}}</p>
    </div>
</div>