<div class="stage container">
    <form [formGroup]="registerForm" class="register-form raised-card" (ngSubmit)="submitForReview()">
        <div *ngIf="!reviewMode" class="register-form-header">
            <h1>Register</h1>
        </div>
        <div *ngIf="reviewMode" class="register-form-header">
            <h1>Review</h1>
            <p>Please review your information before completing the registration.</p>
        </div>
        <div class="control-group">
            <mat-form-field>
                <input matInput placeholder="Company Legal Name" formControlName="legalName" autofocus>
            </mat-form-field>
            <div class="error" *ngIf="submitted && registerForm.get('legalName').invalid">
                <p *ngIf="registerForm.get('legalName').hasError('required')">A legal name is required</p>
            </div>
        </div>
        <div class="control-group card-body">
            <h4>Contact Info</h4>
            <div id="email" class="control-group-sub">
                <mat-form-field>
                    <input matInput placeholder="Email" type="email" formControlName="email">
                </mat-form-field>
                <div class="error" *ngIf="submitted && registerForm.get('email').invalid">
                    <p *ngIf="registerForm.get('email').hasError('validateEmail')">"{{registerForm.get('email').value}}"
                        is not a valid email address</p>
                    <p *ngIf="registerForm.get('email').hasError('required')">An email address is required</p>
                </div>
            </div>
            <div class="control-group-sub">
                <mat-form-field>
                    <input matInput placeholder="First Name" formControlName="firstName">
                </mat-form-field>
                <div class="error" *ngIf="submitted && registerForm.get('firstName').invalid">
                    <p *ngIf="registerForm.get('firstName').hasError('required')">A first name is required</p>
                </div>
            </div>
            <div class="control-group-sub">
                <mat-form-field>
                    <input matInput placeholder="Last Name" formControlName="lastName">
                </mat-form-field>
                <div class="error" *ngIf="submitted && registerForm.get('lastName').invalid">
                    <p *ngIf="registerForm.get('lastName').hasError('required')">A last name is required</p>
                </div>
            </div>
            <div class="control-group">
                <mat-form-field>
                    <input matInput [textMask]="phoneMask" placeholder="Phone Number" formControlName="phone">
                </mat-form-field>
                <div class="error" *ngIf="submitted && registerForm.get('phone').invalid">
                    <p *ngIf="registerForm.get('phone').hasError('required')">A phone number is required</p>
                </div>
            </div>
            <div class="control-group">
                <mat-form-field>
                    <input matInput [textMask]="phoneMask" placeholder="Fax Number" formControlName="fax">
                </mat-form-field>
            </div>
        </div>

        <div class="control-group card-body">
            <h4>Address</h4>
            <dol-address-input formControlName="address" [newDealerAddress]="newDealerAddress" [required]="true"
                [showValidation]="submitted" (submit)="submitForReview()"></dol-address-input>
        </div>

        <div class="control-group card-body">
            <div class="remit-address-head">
                <h4>Remit-To Address</h4>
                <div *ngIf="!reviewMode">
                    <button mat-button type="button" class="btn btn-default"
                        (click)="copy(registerForm.get('address'), registerForm.get('remitAddress'))"
                        [disabled]="registerForm.get('address').invalid">Same as Mailing</button>
                </div>
            </div>
            <dol-address-input formControlName="remitAddress" [newDealerAddress]="newDealerAddress" [required]="true"
                [showValidation]="submitted" (submit)="submitForReview()"></dol-address-input>
        </div>
        <div class="control-group">
            <h4>Programs</h4>
            <dol-participating-programs formControlName="participatingPrograms"></dol-participating-programs>
        </div>
        <p *ngIf="errorMessage" class="error">{{errorMessage}}</p>
        <div *ngIf="!reviewMode" class="form-actions">
            <button mat-raised-button type="submit" class="btn-brand" [disabled]="submitDisabled">Continue</button>
        </div>
    </form>
    <form *ngIf="reviewMode" class="confirm-form" (ngSubmit)="register()">
        <div class="control-group">
            <dol-re-captcha (captchaResponse)="recaptchaSolved($event)" (captchaExpired)="recaptchaToken = ''">
            </dol-re-captcha>
            <div *ngIf="submittedRegister && !recaptchaToken" class="error">You must complete the captcha above</div>
        </div>
        <div *ngIf="reviewMode" class="form-actions">
            <button mat-button type="button" class="btn-default" (click)="switchMode('edit')"
                [disabled]="submitDisabled">Back</button>
            <button mat-raised-button type="submit" class="btn-brand" [disabled]="submitDisabled">Register</button>
        </div>
    </form>
</div>