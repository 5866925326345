import { AbstractControl } from '@angular/forms';

const minPasswordLength = 8;
const maxPasswordLength = 999;
const mustContainNumber = true;
const mustContainLowercaseLetter = true;
const mustContainCapitalLetter = true;
const mustContainNonAlphaNumeric = false;

export function validatePassword(c: AbstractControl) {
    if (!c.value || c.value.length === 0) {
        return null;
    }
    const result = { passwordValidation: {} };
    if (c.value.length < minPasswordLength) {
        result['passwordValidation']['minlength'] = 'Must be ' + minPasswordLength + ' characters';
    }
    if (mustContainNumber && !(new RegExp('[0-9]').test(c.value))) {
        result['passwordValidation']['nonumber'] = 'Must have a number';
    }
    if (mustContainCapitalLetter && !(new RegExp('[A-Z]').test(c.value))) {
        result['passwordValidation']['nocapital'] = 'Must have a capital letter';
    }
    if (mustContainLowercaseLetter && !(new RegExp('[a-z]').test(c.value))) {
        result['passwordValidation']['nolowercase'] = 'Must have a lowercase letter';
    }
    if (mustContainNonAlphaNumeric && !(new RegExp('[^a-zA-Z0-9]').test(c.value))) {
        result['passwordValidation']['nospecial'] = 'Must have a non alphanumeric character';
    }
    if (c.value.length > maxPasswordLength) {
        result['passwordValidation']['maxlength'] = 'Can not be over ' + maxPasswordLength + ' characters';
    }
    if (Object.keys(result.passwordValidation).length === 0) {
        return null;
    }
    return result;
}
