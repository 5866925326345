import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: string) {
        if (!phoneNumber || phoneNumber === null || phoneNumber.length === 0) {
            return phoneNumber;
        }

        if (phoneNumber[0] === '(') {
            return phoneNumber;
        }

        let formatted = phoneNumber;
        phoneNumber = phoneNumber.replace(/-/g, '');
        if (phoneNumber && phoneNumber.length === 10) {
            formatted = '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6);
        }

        return formatted;
    }
}
