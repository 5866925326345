<nav class="toolbar hide-on-print" [class.toolbar-scroll]="eulaRouteActive" role="navigation">
    <div class="container">
        <a class="toolbar-logo" routerLink="/"><img src="assets/images/rabo-logo.png" alt="Rabo AgriFinance logo"></a>
        <ul *ngIf="loggedIn()" class="toolbar-controls">
            <li *ngIf="showCustomers()" routerLinkActive="current"><a routerLink="/customers">Customers</a></li>
            <li *ngIf="hasPermission(DEALER_VIEW)" routerLinkActive="current"><a routerLink="/dealers">Dealers</a></li>
            <li *ngIf="canAnyDealerViewDocumentInbox()" routerLinkActive="current"><a
                    routerLink="/documents">Documents</a></li>
            <li *ngIf="!hasPermission(PROGRAM_VIEW) && (doesAnyDealerHavePermission(DEALER_PERMISSION_VIEW) || doesAnyDealerHavePermission(DEALER_PERMISSION_TRANSACT))"
                routerLinkActive="current"><a routerLink="/my-programs">Programs</a></li>
            <li *ngIf="hasPermission(PROGRAM_VIEW)" routerLinkActive="current"><a routerLink="/programs">Programs</a>
            </li>
            <li *ngIf="hasPermission(BRIDGE_TRANSACTION_UPDATE) || hasPermission(TRANSACTION_LIMIT_VOUCHER_APPROVE)"
                routerLinkActive="current">
                <button type="button" [matMenuTriggerFor]="utilitiesMenu">Utilities <i
                    class="fa fa-caret-down"></i></button>
                <mat-menu #utilitiesMenu="matMenu">
                    <ng-container *ngIf="hasPermission(BRIDGE_TRANSACTION_UPDATE)">
                        <button mat-menu-item routerLink="/reports/bridge-transactions">Bridge Expiration</button>
                    </ng-container>
                    <ng-container *ngIf="hasPermission(TRANSACTION_LIMIT_VOUCHER_APPROVE)">
                        <button mat-menu-item routerLink="/reports/vouchers-on-hold">Vouchers On Hold</button>
                    </ng-container>
                </mat-menu>
            </li>
            <li *ngIf="doesAnyDealerHavePermission(DEALER_PERMISSION_VIEW) || canAnyDealerViewInvoicingDocuments()"
                routerLinkActive="current">                
                <button type="button" [matMenuTriggerFor]="reportsMenu">Reports <i
                        class="fa fa-caret-down"></i></button>
                <mat-menu #reportsMenu="matMenu">
                    <ng-container *ngIf="doesAnyDealerHavePermission(DEALER_PERMISSION_VIEW)">
                        <button mat-menu-item routerLink="/reports/dealer-transaction-report"
                            [title]="linkDescription.DealerTransaction">Dealer Transaction</button>
                        <button mat-menu-item routerLink="/reports/bridge-dealer-transaction-report"
                            [title]="linkDescription.BridgeDealerTransaction">Bridge Transactions</button>
                        <button mat-menu-item routerLink="/reports/past-due-report"
                            [title]="linkDescription.PastDueAccounts">Past Due</button>
                        <button mat-menu-item routerLink="/reports/unused-accounts-report"
                            [title]="linkDescription.UnusedAccounts">Unused Accounts</button>
                        <button mat-menu-item routerLink="/reports/volume-comparison-report"
                            [title]="linkDescription.VolumeComparison">Volume Comparison</button>
                        <button mat-menu-item routerLink="/reports/application-status-report"
                            [title]="linkDescription.ApplicationStatus">Application Status</button>
                        <button mat-menu-item routerLink="/reports/renewal-report"
                            [title]="linkDescription.Renewals">Renewal</button>
                        <button mat-menu-item routerLink="/reports/settlement-report"
                            [title]="linkDescription.Settlement">Settlement</button>
                        <button *ngIf="canAnyDealerViewLoanDetailReport()" mat-menu-item
                            routerLink="/reports/loan-details-report" [title]="linkDescription.LoanDetails">Loan
                            Details</button>
                    </ng-container>
                    <ng-container *ngIf="canAnyDealerViewInvoicingDocuments()">
                        <button *ngIf="canAnyDealerViewInvoicingDocuments()" mat-menu-item
                            routerLink="/reports/dealerinvoicing-report"
                            [title]="linkDescription.StatementsInvoices">Statements & Invoices</button>
                    </ng-container>
                </mat-menu>
            </li>
            <li *ngIf="hasPermission(ASSIGNED_DEALER_GET_ANY) || doesAnyDealerHavePermission(DEALER_PERMISSION_USER_ACCESS_MANAGEMENT)"
                routerLinkActive="current"><a routerLink="/users">Users</a></li>
            <li class="toolbar-controls-user">
                <button type="button" [matMenuTriggerFor]="userMenu">{{getFullName()}} <i
                        class="fa fa-caret-down"></i></button>
                <mat-menu #userMenu="matMenu" class="user-menu">
                    <button mat-menu-item routerLink="/my-profile">
                        <i class="fa fa-user"></i>
                        My Profile
                    </button>
                    <button mat-menu-item routerLink="/help">
                        <i class="fa fa-question-circle"></i>
                        Help
                    </button>
                    <button mat-menu-item (click)="logout()">
                        <i class="fa fa-sign-out"></i>
                        Logout
                    </button>
                </mat-menu>
            </li>
        </ul>
    </div>
</nav>