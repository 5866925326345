import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { PermissionBase } from '../../shared/models/permission-base.model';
import { AuthService } from '../services/authentication/auth.service';
import { PermissionService } from '../services/authorization/permission.service';
import { MessageCenterService } from '../services/message-center/message-center.service';
import { StateService } from '../services/state.service';
import { LinkDescription } from './link-description';

@Component({
  selector: 'dol-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent extends PermissionBase implements OnInit {
  eulaRouteActive: boolean;
  linkDescription = LinkDescription;

  constructor(
    private authService: AuthService,
    private messageService: MessageCenterService,
    protected permissionService: PermissionService,
    private router: Router,
    private stateService: StateService) {
    super(permissionService);
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.eulaRouteActive = (event.url === '/login/eula');
      }
    });
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  showCustomers(): boolean {
    return this.canViewCustomers(this.stateService.currentUser.assignedDealers);
  }

  getFullName(): string {
    const user = this.stateService.currentUser;
    return user ? user.getFullName() : '';
  }

  logout() {
    this.stateService.clear();
    this.messageService.clearMessages();
    this.authService.logout();
    this.router.navigate(['']);
  }

  hasAssignedDealers() {
    return this.stateService.currentUser.hasAssignedDealers();
  }
}
