import { FormArray } from '@angular/forms';

export function validateSecurityQuestions(securityQuestions: FormArray) {
    const alreadyUsed: Array<string> = [];
    let result = null;

    for (const questionGroup of securityQuestions.controls) {
        const question = questionGroup.get('question').value;
        if (question) {
            if (alreadyUsed.indexOf(question) !== -1) {
                result = { duplicates: true };
                break;
            }
            alreadyUsed.push(question);
        }
    }

    return result;
}
