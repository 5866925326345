import * as moment from 'moment';

import { Address } from '../../../shared/models/address.model';
import { ProgramHeader } from '../../../shared/models/program-header.model';

export class PendingDealerBindingModel {
    email: string;
    legalName: string;
    firstName: string;
    lastName: string;
    address: Address;
    remitAddress: Address;
    phoneNumber: string;
    fax: string;
    programsRequested: string;
    onlineAgreementAcceptanceDate: moment.Moment;
    participatingPrograms: Array<ProgramHeader>;
    recaptchaToken: string;

    constructor(props?: any) {
        if (props) {
            this.email = props.email;
            this.legalName = props.legalName;
            this.firstName = props.firstName;
            this.lastName = props.lastName;
            this.address = new Address(props.address, true);
            this.remitAddress = new Address(props.remitAddress, true);
            this.phoneNumber = props.phone;
            this.fax = props.fax;
            this.programsRequested = props.programsRequested;
            this.onlineAgreementAcceptanceDate = props.onlineAgreementAcceptanceDate
                ? moment(props.onlineAgreementAcceptanceDate) : moment();
            this.participatingPrograms = props.participatingPrograms ? props.participatingPrograms.slice() : null;
            this.recaptchaToken = props.recaptchaToken;
        }
    }
}
