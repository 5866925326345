export class SecurityChallenge {
    question: string;
    securityImageId: number;

    constructor(appData?: any) {
        if (appData) {
            this.question = appData.Question;
            this.securityImageId = appData.SecurityImageId;
        }
    }
}
