import { Component, ElementRef, EventEmitter, Input, OnInit, Output, NgZone, ViewChild } from '@angular/core';

import { CONFIG } from '../../../../environments/environment';
import { ReCaptchaService } from '../../../core/services/recaptcha.service';

@Component({
    selector: 'dol-re-captcha',
    template: '<div class="raised-card" #target></div>',
    styles: [`
        div {
            display: flex;
            justify-content: center;
        }
    `]
})
export class ReCaptchaComponent implements OnInit {

    @Input()
    site_key: string = CONFIG.recaptchaSiteKey;
    @Input()
    theme = 'light';
    @Input()
    type = 'image';
    @Input()
    size = 'normal';
    @Input()
    tabindex = 0;
    /* Available languages: https://developers.google.com/recaptcha/docs/language */
    @Input()
    language: string = null;

    @Output()
    captchaResponse = new EventEmitter<string>();
    @Output()
    captchaExpired = new EventEmitter<boolean>();

    @ViewChild('target') targetRef: ElementRef;
    widgetId: any = null;

    constructor(
        private _zone: NgZone,
        private _captchaService: ReCaptchaService) {
    }

    ngOnInit() {
        this._captchaService.getReady(this.language)
            .subscribe((ready) => {
                if (!ready) {
                    return;
                }
                //noinspection TypeScriptUnresolvedVariable,TypeScriptUnresolvedFunction
                this.widgetId = (<any>window).grecaptcha.render(this.targetRef.nativeElement, {
                    'sitekey': this.site_key,
                    'theme': this.theme,
                    'type': this.type,
                    'size': this.size,
                    'tabindex': this.tabindex,
                    'callback': <any>((response: any) => this._zone.run(this.recaptchaCallback.bind(this, response))),
                    'expired-callback': <any>(() => this._zone.run(this.recaptchaExpiredCallback.bind(this)))
                });
            });
    }

    public reset() {
        if (this.widgetId == null) {
            return;
        }
        //noinspection TypeScriptUnresolvedVariable
        (<any>window).grecaptcha.reset(this.widgetId);
    }

    public getResponse(): String {
        if (this.widgetId == null) {
            return null;
        }
        //noinspection TypeScriptUnresolvedVariable
        return (<any>window).grecaptcha.getResponse(this.targetRef.nativeElement);
    }

    private recaptchaCallback(response: string) {
        this.captchaResponse.emit(response);
    }

    private recaptchaExpiredCallback() {
        this.captchaExpired.emit(true);
    }
}
