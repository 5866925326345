const defaultSettings = {
    guide: false
};

function buildMask(mask: any, settings: any) {
    return Object.assign({}, defaultSettings, { mask }, settings);
}

function zipMaskCallback(input: string) {
    const mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/];

    if (typeof input === 'undefined' || input == null) {
        return mask;
    }

    const rawZipCode = input.replace(/[^0-9]/g, '');
    return rawZipCode.length <= 5 ?
        mask :
        mask.concat(['-', /\d/, /\d/, /\d/, /\d/]);
}

export function buildZipMask(settings: any = {}) {
    const mask = zipMaskCallback;
    return buildMask(mask, settings);
}

export function buildPhoneMask(settings: any = {}) {
    const mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    return buildMask(mask, settings);
}
