import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dol-filter',
    template: `
		<mat-form-field>
			<input matInput type="text" class="form-control" (input)="search($event)" placeholder="{{placeholder}}" [value]="value">
		</mat-form-field>
	`,
    styles: [`
        mat-form-field {
            width: 100%;
        }
    `]
})
export class FilterComponent implements OnInit {
    @Input() items: any;
    @Input() by: any;
    @Input() placeholder = 'Search...';
    @Output() filtered = new EventEmitter<any>();
    value = '';

    ngOnInit() { }

    clear() {
        this.value = null;
    }

    search($event): void {
        const query = $event.target.value;
        if (query) {
            const terms = this.parseTerms(query);

            this.filtered.emit(
                this.items.filter(item => {
                    const targetTerms: Array<string> = this.traverse(item, this.by);
                    const targetString: string = targetTerms.join(' ');

                    let strictPassed = false;
                    let strictMatch = false;
                    for (const strictTerm of terms.strict) {
                        const regex = new RegExp(strictTerm, 'i');

                        for (const targetTerm of targetTerms) {
                            strictMatch = regex.test(targetTerm);
                            if (strictMatch) { break; }
                        }
                        if (!strictMatch) { break; }
                    }
                    strictPassed = terms.strict.length > 0 && strictMatch || terms.strict.length === 0;

                    let containsPassed = false;
                    let containsMatch = false;
                    if (strictPassed) {
                        for (const containsTerm of terms.contains) {
                            const regex = new RegExp(containsTerm, 'i');

                            containsMatch = regex.test(targetString);
                            if (!containsMatch) { break; }
                        }
                        containsPassed = terms.contains.length > 0 && containsMatch || terms.contains.length === 0;
                    }

                    return strictPassed && containsPassed;
                })
            );
        } else {
            this.filtered.emit(this.items);
        }
    }

    parseTerms(query: string): { strict: Array<string>, contains: Array<string> } {
        const terms = {
            strict: [],
            contains: []
        };

        const queryContains = query.replace(/"([^"]*)"/g, match => {
            if (match.length > 2) {
                terms.strict.push('^' + match.substr(1, match.length - 2) + '$');
            }

            return '';
        });

        if (queryContains.length > 0) {
            terms.contains = queryContains.trim().split(/\s+/);
        }

        return terms;
    }

    traverse(target, options, fields = []) {
        for (const key of Object.keys(options)) {
            if (options[key] === 1 && target[key]) {
                fields.push(target[key].toString());
            } else if (typeof (options[key]) === 'object' && typeof (target[key]) === 'object') {
                fields = this.traverse(target[key], options[key], fields);
            }
        }

        return fields;
    }

}
