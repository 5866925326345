import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { STATES } from '../../data/state-list';

@Component({
    selector: 'dol-state-input',
    templateUrl: './state-input.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: StateInputComponent }
    ]
})
export class StateInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() include: Array<string> = ['us_states', 'us_territories', 'canada'];
    _onChange: (_: any) => void;
    stateControl: FormControl = new FormControl();
    stateGroups: Array<{ name: string, members: Array<{ name: string, abbreviation: string }> }> = [];
    private unsubscribe: Subject<void> = new Subject<void>();

    ngOnInit() {
        this.initializeOptions();
        this.stateControl
            .valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((abbreviation: string) => {
                this._onChange(abbreviation);
            });
    }

    writeValue(abbreviation: string) {
        this.stateControl.reset(abbreviation, { emitEvent: false });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched() { }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.stateControl.disable();
        } else {
            this.stateControl.enable();
        }
    }

    private initializeOptions() {
        for (const groupKey of this.include) {
            const stateGroup = STATES[groupKey.toLowerCase()];
            if (stateGroup) {
                this.stateGroups = this.stateGroups.concat(STATES[groupKey]);
            }
        }
    }
}
